import { ClockIcon, MinusIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { getLoanInputFieldOptions, getLoanInputFields } from 'config/loan.input.fields.constants'
import { FieldOrigin, fieldOriginOptions, IVisibleLogic } from 'config/loan.input.visibility.type'
import { useMemo } from 'react'
import { RenderInput } from 'utils/RenderInput'

import { Tooltip } from '../Tooltip/Tooltip'

interface InputVisibleLogicProps {
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Error
   */
  error?: string

  tooltip?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: IVisibleLogic[][]
  /**
   * Custom class name
   */
  className?: string
  /**
   * Optional history handler
   */
  history?: boolean
  additionalElements?: JSX.Element | Function | null
  onChange?: (checked: any) => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const InputVisibleLogic = ({
  disabled = false,
  id = '',
  title = '',
  error = '',
  tooltip = '',
  value = [],
  history = false,
  className = '',
  additionalElements = null,
  onChange = () => {},
  showHistory = () => {},
}: InputVisibleLogicProps) => {
  const _now = Date.now()

  const loanInputFields = getLoanInputFields()
  const loanInputFieldOptions = getLoanInputFieldOptions()

  const fieldOriginOptionsExceptCustom = useMemo(() => {
    const options = cloneDeep(fieldOriginOptions)
    delete (options as any)[FieldOrigin.Other]
    delete (options as any)[FieldOrigin.Custom]
    return options
  }, [])

  const onAddVisibleLogic = (orIndex: number) => {
    const newValue = cloneDeep(value)

    if (orIndex == -1) newValue.push([])
    else
      newValue[orIndex].push({
        fieldOrigin: null,
        fieldKey: '',
        value: '',
      } as any)
    onChange(newValue)
  }

  const onRemoveVisibleLogic = (orIndex: number, andIndex: number = -1) => {
    const newValue = cloneDeep(value)

    if (andIndex == -1) newValue.splice(orIndex, 1)
    else newValue[orIndex].splice(andIndex, 1)

    onChange(newValue)
  }

  const onUpdateVisibleLogic = (orIndex: number, andIndex: number, key: keyof IVisibleLogic, _value: any) => {
    const newValue = cloneDeep(value)

    let newValueItem = newValue![orIndex][andIndex]
    if (key == 'fieldOrigin') {
      newValueItem.fieldKey = ''
      ;(newValueItem as any).value = null
    }
    ;(newValueItem as any)[key] = _value

    newValue![orIndex][andIndex] = newValueItem
    onChange(newValue)
  }

  const renderVisibleLogic = (option: IVisibleLogic, orIndex: number, andIndex: number) => {
    const { fieldOrigin, fieldKey, value } = option
    const inputs: Record<string, InputType> = {
      fieldOrigin: {
        inputType: 'select',
        title: 'Page Origin',
        options: fieldOriginOptionsExceptCustom,
        hasDefaultOption: true,
        value: fieldOrigin,
        required: true,
        sort: false,
      },
      fieldKey: {
        inputType: 'select',
        title: 'Select Field',
        options: loanInputFieldOptions[fieldOrigin] || {},
        hasDefaultOption: true,
        value: fieldKey,
        required: true,
        sort: true,
      },
    }
    if (fieldOrigin && fieldKey) {
      inputs.value = {
        ...loanInputFields[fieldOrigin][fieldKey],
        value: value as any,
      }
    }
    return (
      <div className="pl-4">
        <div className="flex justify-between">
          <p className="text-sm">
            Condition {orIndex + 1} - {andIndex + 1}
          </p>
          <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex, andIndex)}>
            <MinusIcon className="w-4 h-4" />
          </span>
        </div>
        <div className="grid md:grid-cols-2 gap-x-2">
          {Object.keys(inputs).map((key) => {
            const elementKey = `${key}-${fieldOrigin}-${fieldKey}-${key}`
            return (
              <div
                className={`mb-4 ${!['fieldOrigin', 'fieldKey'].includes(key) ? 'col-span-full' : ''}`}
                key={elementKey}
              >
                <RenderInput
                  input={inputs[key]}
                  Key={elementKey}
                  onChange={(_key: string, value: string) => onUpdateVisibleLogic(orIndex, andIndex, key as any, value)}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderVisibleLogicAND = (logics: IVisibleLogic[], orIndex: number) => {
    return (
      <div className={`${orIndex % 2 == 0 ? `bg-slate-100` : ''} p-2`}>
        <div className="flex justify-between items-center">
          <p className="font-bold text-sm">{orIndex + 1}. Visible Conditions (AND)</p>
          <div className="flex">
            <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex)}>
              <MinusIcon className="w-4 h-4" />
            </span>
            <span className="btn-icon" onClick={() => onAddVisibleLogic(orIndex)}>
              <PlusIcon className="w-4 h-4" />
            </span>
          </div>
        </div>

        {logics.map((v, andIndex) => renderVisibleLogic(v, orIndex, andIndex))}
      </div>
    )
  }

  const renderLogics = () => {
    return (
      <div>
        <div className="flex justify-between items-center">
          <p className="font-bold text-sm">Visible Conditions (OR)</p>
          <span className="btn-icon" onClick={() => onAddVisibleLogic(-1)}>
            <PlusIcon className="w-4 h-4" />
          </span>
        </div>

        {value.map((v, logicIdx) => renderVisibleLogicAND(v, logicIdx))}
      </div>
    )
  }

  return (
    <div>
      <div className={`group relative z-0 w-full group ${className}`}>
        <div className="flex flex-1">
          {!!title && (
            <label
              htmlFor={`${id}-${_now}`}
              className={`inline-flex relative items-center cursor-pointer h-5 ${disabled && 'cursor-not-allowed'}`}
            >
              <span className="text-[14px] font-medium text-gray-900 dark:text-gray-300">{title}</span>
            </label>
          )}

          <div className="flex-1" />
          {tooltip ? (
            <Tooltip message={tooltip}>
              <QuestionMarkCircleIcon className="w-4 h-4" />
            </Tooltip>
          ) : null}
          {history && (
            <span className="ml-3 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
          {additionalElements !== null &&
            (typeof additionalElements == 'function' ? additionalElements(onChange) : additionalElements)}
        </div>
        {renderLogics()}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
