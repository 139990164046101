import {
  API_TASK_ADD_FROM_TEMPLATES,
  API_TASK_BY_LOAN_NUMBER,
  API_TASK_BY_USER_ID,
  API_TASK_GET_TASK_BY_PROJECTID,
  API_TASK_GET_TASK_BY_TASKNO,
  API_TASK_GET_TASK_HISTORY,
  API_TASK_NEW_TASK,
  API_TASK_PROJECT,
  API_TASK_UPDATE_TASK,
  API_TASK_UPDATE_TASK_STATUS,
} from 'config'
import Api from 'services/api'

export const setProjects = (data: Record<string, any>[]) => {
  return Api.post(API_TASK_PROJECT, data)
}

export const getProjects = (needActiveCount = 0) => {
  return Api.get(API_TASK_PROJECT, { needActiveCount })
}

export const addNewTask = (data: Record<string, string | string[] | any>) => {
  return Api.post(API_TASK_NEW_TASK, data, {})
}

export const updateTask = (id: number, data: Record<string, string | string[] | any>) => {
  return Api.post(API_TASK_UPDATE_TASK, data, { taskId: id })
}

export const updateTaskStatus = (id: number, data: Record<string, any>) => {
  return Api.put(API_TASK_UPDATE_TASK_STATUS, data, { taskId: id })
}

export const getAllTasks = (
  projectId: number,
  filters: Record<string, any>,
): Promise<{ data: Record<string, any>[]; total: number }> => {
  return Api.get(API_TASK_GET_TASK_BY_PROJECTID, filters, { projectId })
}

export const deleteTask = (taskId: number) => {
  return Api.delete(API_TASK_UPDATE_TASK, {}, { taskId })
}

export const recoverTask = (taskId: number) => {
  return Api.put(API_TASK_UPDATE_TASK, {}, { taskId })
}

export const getTaskCompleteHistory = (taskId: number) => {
  return Api.get(API_TASK_GET_TASK_HISTORY, {}, { taskId })
}

export const getTaskByLoanNumber = (loanNumber: number) => {
  return Api.get(API_TASK_BY_LOAN_NUMBER, {}, { loanNumber })
}

export const getTaskByNo = (taskNo: number) => {
  return Api.get(API_TASK_GET_TASK_BY_TASKNO, {}, { taskNo })
}

export const addNewTaskFromTemplates = (projectId: number, loanNumber: number, templates: Record<string, any>[]) => {
  return Api.post(API_TASK_ADD_FROM_TEMPLATES, templates, { projectId, loanNumber })
}

export const getTaskByUserId = (
  userId: number,
  filters: Record<string, any>,
): Promise<{ data: Record<string, any>[]; total: number }> => {
  return Api.get(API_TASK_BY_USER_ID, filters, { userId })
}
