import { setLoanGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { type InputType, FieldOrigin } from 'config'
import { fieldsByTransactionTypeAndProductTypeConstant, MINVALUE } from 'config/loan.constants'
import moment from 'moment-timezone'
import type { ILoanGlobalSettings, IMinMaxLimitPerTypes } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import { isSameStructureApplicationCreditScore } from 'pages/LoanApplication/CreditScore/logic'
import { IUnderwritingFee } from 'pages/Resources/UnderwritingFee'
import { toast } from 'react-toastify'
import { store } from 'reducers'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import {
  formatDate,
  fromToNumberOptions,
  getPrice2decimal,
  getPrice3decimal,
  InputConvert,
  InputValidate,
  isEmpty,
  removeComma,
} from 'utils'
import { removeInvisibleFields } from 'utils/loan'

import type { ILoan, ILockDay, ILockTermPrice, IPrice, IPrice_Program_Price, IProduct, IProgram } from './interfaces'

const analyzeLoanStructureValue = (loan: ILoan) => {
  let visibleFields = fieldsByTransactionTypeAndProductTypeConstant[loan.productType]?.[loan.transactionType] || []
  let propertyPurchasePrice = removeComma(loan.propertyPurchasePrice)
  let asIsValue = removeComma(loan.asIsValue)
  let constructionReserve = removeComma(loan.constructionReserve)
  let proposedLoanAmount = removeComma(loan.proposedLoanAmount)
  let afterRepairValue = removeComma(loan.afterRepairValue)
  let rehabBudget = removeComma(loan.rehabBudget)
  let lienPayoff = removeComma(loan.lienPayoff)
  let closingCostEstimate = removeComma(loan.closingCostEstimate)
  let interestReserve = removeComma(loan.interestReserve)
  let secondLien = removeComma(loan.secondLien)

  if (visibleFields.indexOf('propertyPurchasePrice') === -1) propertyPurchasePrice = 0
  if (visibleFields.indexOf('asIsValue') === -1) asIsValue = 0
  if (visibleFields.indexOf('constructionReserve') === -1) constructionReserve = 0
  if (visibleFields.indexOf('proposedLoanAmount') === -1) proposedLoanAmount = 0
  if (visibleFields.indexOf('afterRepairValue') === -1) afterRepairValue = 0
  if (visibleFields.indexOf('rehabBudget') === -1) rehabBudget = 0
  if (visibleFields.indexOf('secondLien') === -1) secondLien = 0

  let minPurchasePriceAppraisedValue = asIsValue
  if (loan.transactionType === 'Purchase') {
    if (asIsValue === 0) minPurchasePriceAppraisedValue = propertyPurchasePrice
    else if (propertyPurchasePrice === 0) minPurchasePriceAppraisedValue = asIsValue
    else minPurchasePriceAppraisedValue = Math.min(propertyPurchasePrice, asIsValue)
  }
  return {
    propertyPurchasePrice,
    asIsValue,
    constructionReserve,
    proposedLoanAmount,
    afterRepairValue,
    rehabBudget,
    lienPayoff,
    closingCostEstimate,
    interestReserve,
    secondLien,
    minPurchasePriceAppraisedValue,
  }
}

export const getLockExpired = (data: any) => {
  let rlt = false
  if (data.rateLocked && data.expireDate) {
    rlt = moment().diff(formatDate(data.expireDate), 'days') > 0
  }
  return rlt
}

export const calculateLoanLimits = (
  loan: ILoan | null = null,
  getMaximumLoanAmount: boolean = false,
  maxLimit: any = null,
) => {
  let rlt: any = {
    Limit: {},
  }
  try {
    const state = store.getState()
    let ltvMaxLimit: any = state.ltvMaxLimit
    if (maxLimit !== null) ltvMaxLimit = maxLimit

    if (loan === null) {
      loan = state.loan as ILoan
    }
    const { productType, transactionType } = loan

    let _aiv_ltv = 0
    let _arv_ltv = 0
    let _ltc = 0
    let _ltp = 0

    const visibleFields = fieldsByTransactionTypeAndProductTypeConstant[productType]?.[transactionType] || []

    let {
      propertyPurchasePrice,
      constructionReserve,
      proposedLoanAmount,
      afterRepairValue,
      rehabBudget,
      lienPayoff,
      closingCostEstimate,
      interestReserve,
      minPurchasePriceAppraisedValue,
    } = analyzeLoanStructureValue(loan)

    _aiv_ltv = (100 * (proposedLoanAmount - constructionReserve)) / minPurchasePriceAppraisedValue
    _arv_ltv = (100 * proposedLoanAmount) / afterRepairValue
    _ltc = (100 * proposedLoanAmount) / (minPurchasePriceAppraisedValue + rehabBudget)
    _ltp = (100 * (proposedLoanAmount - constructionReserve)) / propertyPurchasePrice

    _aiv_ltv = Number(_aiv_ltv.toFixed(3))
    _arv_ltv = Number(_arv_ltv.toFixed(3))
    _ltc = Number(_ltc.toFixed(3))
    _ltp = Number(_ltp.toFixed(3))

    rlt.Limit.aiv_ltv = _aiv_ltv
    rlt.Limit.arv_ltv = _arv_ltv
    rlt.Limit.ltc = _ltc
    rlt.Limit.ltp = _ltp

    try {
      rlt.Limit.max_aiv_ltv = ltvMaxLimit.max_aiv_ltv
      rlt.Limit.max_arv_ltv = ltvMaxLimit.max_arv_ltv
      rlt.Limit.max_ltc = ltvMaxLimit.max_ltc
      rlt.Limit.max_ltp = ltvMaxLimit.max_ltp
    } catch {}

    if (getMaximumLoanAmount) {
      let minValue = MINVALUE
      if (rlt.Limit.max_aiv_ltv) {
        minValue = Math.min(
          minValue,
          removeComma((rlt.Limit.max_aiv_ltv * minPurchasePriceAppraisedValue) / 100 + constructionReserve),
        )
      }
      if (rlt.Limit.max_arv_ltv) {
        minValue = Math.min(minValue, removeComma((rlt.Limit.max_arv_ltv * afterRepairValue) / 100))
      }
      if (rlt.Limit.max_ltc) {
        minValue = Math.min(
          minValue,
          removeComma((rlt.Limit.max_ltc * (minPurchasePriceAppraisedValue + rehabBudget)) / 100),
        )
      }
      if (rlt.Limit.max_ltp) {
        minValue = Math.min(
          minValue,
          removeComma((rlt.Limit.max_ltp * propertyPurchasePrice) / 100 + constructionReserve),
        )
      }
      if (transactionType === 'Refinance') {
        if (visibleFields.indexOf('rehabBudget') !== -1) {
          minValue = Math.min(
            minValue,
            rehabBudget + rehabBudget * 0.2 + lienPayoff + closingCostEstimate + interestReserve,
          )
        }
      }
      rlt.Limit = Math.trunc(minValue)
    }
  } catch {}
  return rlt.Limit
}

export const calculateLTV = (loan: ILoan | null = null, cltv: boolean = false) => {
  let rlt: any = ''
  try {
    if (loan === null) {
      const state = store.getState()
      loan = state.loan as ILoan
    }

    let { constructionReserve, proposedLoanAmount, secondLien, minPurchasePriceAppraisedValue } =
      analyzeLoanStructureValue(loan)

    rlt = (100 * (proposedLoanAmount - constructionReserve)) / minPurchasePriceAppraisedValue
    if (cltv) rlt = (100 * (proposedLoanAmount + secondLien - constructionReserve)) / minPurchasePriceAppraisedValue

    return rlt.toFixed(3)
  } catch {}
  return rlt
}

export const visibleLoansLogic = (): string[] => {
  const { loan } = store.getState()
  let visibleFields = ['productType', 'transactionType']

  if (loan['productType'] && loan['transactionType']) {
    const definedVisibleFields =
      fieldsByTransactionTypeAndProductTypeConstant[loan['productType']][loan['transactionType']]
    visibleFields = [...visibleFields, ...definedVisibleFields]
    if (loan['propertyType'] === 'Condo') {
      visibleFields.push('condoType')
    }
    if (loan['propertyState'] === 'NY' && loan['transactionType'] === 'Refinance')
      visibleFields.push('isCEMATransaction')
    if (removeComma(loan['secondLien']) > 0) visibleFields.push('secondLienPayment')
    if (visibleFields.includes('experience') && loan.experience === '5+ Transactions')
      visibleFields.push('howManyExperiences')
  }

  visibleFields = removeInvisibleFields(FieldOrigin.LoanStructure, visibleFields)

  return visibleFields
}

export const propertyTypeNumberUnitInputLogic = (propertyType: string, input: any) => {
  switch (propertyType) {
    case 'SFR-Detached':
    case 'SFR-Attached':
    case 'Industrial':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 1)
      input.hasDefaultOption = true
      break
    case 'Condo':
    case 'PUD':
    case 'Automotive':
    case 'Modular':
    case 'Manufactured':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 4)
      input.hasDefaultOption = true
      break
    case 'Office':
    case 'Warehouse':
      input.inputType = 'select'
      input.options = fromToNumberOptions(1, 100)
      input.hasDefaultOption = true
      break
    case '2-4 Units':
      input.inputType = 'select'
      input.options = fromToNumberOptions(2, 4)
      input.hasDefaultOption = true
      break

    default: {
      input.inputType = 'text'
      break
    }
  }
  return input
}

export const loanFieldsValidateAll = (loanField: any, loanGlobalSettings: ILoanGlobalSettings) => {
  const { loan, loanDetail } = store.getState()

  // Property Type & Number Of Unit Logic
  loanField.numberOfUnits = propertyTypeNumberUnitInputLogic(loan.propertyType, loanField.numberOfUnits)
  // End

  Object.keys(loanField).map((key) => {
    loanField[key].error = InputValidate(
      { ...loanField[key], value: loan[key] },
      loanDetail.loanNumber,
      loanDetail,
      loan,
    )
  })

  //  The After Repair Value (ARV) must be no less than setting% of the total costs (lesser of the as is value / purchase price + rehab budget)
  let { afterRepairValue, rehabBudget, minPurchasePriceAppraisedValue } = analyzeLoanStructureValue(loan)
  const minAfterRepairValue = Math.ceil(
    ((100 + Number(loanGlobalSettings.minAfterRepairValue)) * (minPurchasePriceAppraisedValue + rehabBudget)) / 100,
  )
  if (0 < afterRepairValue && afterRepairValue < minAfterRepairValue) {
    loanField['afterRepairValue'].error = `The After Repair Value does not confirm at least ${
      loanGlobalSettings.minAfterRepairValue
    }% profitability due to it being less than $${getPrice2decimal(minAfterRepairValue)}`
  }

  // When First Time Homebuyer or First Time Investor then 'Borrower Type' = Individual is ineligible
  if (loan['firstTimeHomeBuyer'] || loan['firstTimeHomeInvestor']) {
    if (loan['borrowerType'] === 'Individual' && isNonDSCRProduct(loan.productType)) {
      loanField[
        'borrowerType'
      ].error = `When first time home buyer  or first time investor, then individual is ineligible.`
    }
  }

  const sameCredit = isSameStructureApplicationCreditScore()
  if (!sameCredit.same && sameCredit.value !== 0) {
    loanField['estimatedCreditScore'].error = sameCredit.error
  }
  return loanField
}

export const showLimitSection = () => {
  let flag = true
  const { loan } = store.getState()
  if (!isNonDSCRProduct(loan['productType'])) flag = false
  return flag
}

export const loanDataConvert = (fields: Record<string, InputType>) => {
  const { loan } = store.getState()
  let data: any = {}
  Object.keys(fields).map((key) => {
    data[key] = InputConvert(fields[key], loan[key])
  })
  store.dispatch(setLoanGroupData(data))
  const key2 = 'propertyCounty'
  data[key2] = loan[key2]
  return data
}

export const getMaximumTotalLoanAmountTip = (loan: ILoan | null, limit: any) => {
  if (loan === null) {
    const state = store.getState()
    loan = state.loan as ILoan
  }
  const lists = []
  let visibleFields = fieldsByTransactionTypeAndProductTypeConstant[loan.productType]?.[loan.transactionType]
  if (visibleFields === undefined) visibleFields = []

  let {
    propertyPurchasePrice,
    constructionReserve,
    proposedLoanAmount,
    afterRepairValue,
    rehabBudget,
    lienPayoff,
    closingCostEstimate,
    interestReserve,
    minPurchasePriceAppraisedValue,
  } = analyzeLoanStructureValue(loan)

  let list: any = {}

  if (loan.transactionType === 'Refinance') {
    if (visibleFields.indexOf('rehabBudget') !== -1) {
      if (limit?.max_aiv_ltv || limit?.max_arv_ltv || limit?.max_ltc || limit?.max_ltp) {
        lists.push({
          label:
            'Construction Budget + 20% of Construction Budget + Lien Payoff + Closing Cost Estimate + Interest Reserve',
          value: rehabBudget + rehabBudget * 0.2 + lienPayoff + closingCostEstimate + interestReserve,
        })
      }
    }
  }

  let AIVLTVMIN = -1
  if (limit?.max_aiv_ltv) {
    const percent = limit.max_aiv_ltv / 100
    list = {
      label: `AIV-LTV MAX: Min(As Is Appraised Value, Property Purchase Price) x ${percent} + Construction Amount Financed`,
      value: minPurchasePriceAppraisedValue * percent + constructionReserve,
    }
    lists.push(list)
    AIVLTVMIN = minPurchasePriceAppraisedValue * percent
  }
  if (limit?.max_arv_ltv) {
    const percent = limit.max_arv_ltv / 100
    list = {
      label: `ARV-LTV MAX: After Repair Value x ${percent}`,
      value: afterRepairValue * percent,
    }
    lists.push(list)
  }
  if (limit?.max_ltc) {
    const percent = limit.max_ltc / 100
    list = {
      label: `LTC MAX: (Min(As Is Appraised Value, Property Purchase Price) + Construction Budget) x ${percent}`,
      value: (minPurchasePriceAppraisedValue + rehabBudget) * percent,
    }
    lists.push(list)
  }
  if (limit?.max_ltp) {
    const percent = limit.max_ltp / 100
    list = {
      label: `LTP MAX: Property Purchase Price x ${percent} + Construction Amount Financed`,
      value: propertyPurchasePrice * percent + constructionReserve,
    }
    lists.push(list)
  }

  let minValue = MINVALUE
  let element = ``
  lists.map((item) => {
    if (minValue > item.value) minValue = item.value
    element += `<div class="mb-1">${item.label} = <span class="text-[14px]">${getPrice3decimal(
      item.value,
    )}</span></div>`
  })

  const tooltip = `
    <div class="">
      <div class="font-bold mb-2">The minimum value of the following:</div>
      ${element}
    </div>
    `

  let constructionReserveMinwithoutChangeLoanAmountValidation = -1
  if (AIVLTVMIN !== -1) {
    if (minValue >= proposedLoanAmount) {
      constructionReserveMinwithoutChangeLoanAmountValidation = Math.max(0, minValue - AIVLTVMIN)
    }
    if (minValue - AIVLTVMIN >= 0 && minValue - AIVLTVMIN >= proposedLoanAmount - AIVLTVMIN) {
      constructionReserveMinwithoutChangeLoanAmountValidation = Math.max(0, proposedLoanAmount - AIVLTVMIN)
    }
  }

  return {
    tooltip: tooltip,
    minValue: Math.trunc(minValue),
    lists: lists.length,
    constructionReserveMinwithoutChangeLoanAmountValidation,
  }
}

export const isNonDSCRProduct = (productType: string) => {
  return 'DSCR (Long Term Rental)' !== productType
}

export const estimatedClosingAmountBorrower = (loan: ILoan, loanGlobalSettings: ILoanGlobalSettings) => {
  let value = 0,
    valueWithoutConstructionCalc = 0,
    closingTooltip = '',
    closingMaxRehabBudget = 0,
    closingError = '',
    rehabFinancedPercent = '',
    totalRehabFinancedPercent = '',
    totalRehabFinancedTooltip = ''

  let visibleFields = fieldsByTransactionTypeAndProductTypeConstant[loan.productType]?.[loan.transactionType]
  if (visibleFields === undefined) visibleFields = []

  if (loan.transactionType === 'Refinance') {
    valueWithoutConstructionCalc =
      removeComma(loan.proposedLoanAmount) -
      removeComma(loan.lienPayoff) -
      removeComma(loan.closingCostEstimate) -
      removeComma(loan.interestReserve)
    value = valueWithoutConstructionCalc - removeComma(loan.constructionReserve)

    closingTooltip = `Proposed Loan Amount<br/>- Lien Payoff<br/>- Closing Cost Estimate<br/>- Interest Reserve<br/>- Construction Amount Financed`

    if (!visibleFields.includes('lienPayoff')) {
      value += removeComma(loan.lienPayoff)
      closingTooltip = closingTooltip.replace('<br/>- Lien Payoff', '')
    }
    if (!visibleFields.includes('constructionReserve')) {
      value += removeComma(loan.constructionReserve)
      closingTooltip = closingTooltip.replace('<br/>- Construction Amount Financed', '')
    }
    if (!visibleFields.includes('interestReserve')) {
      value += removeComma(loan.interestReserve)
      closingTooltip = closingTooltip.replace('<br/>- Interest Reserve', '')
    }

    if (visibleFields.includes('rehabBudget')) {
      closingMaxRehabBudget =
        (removeComma(loan.rehabBudget) * removeComma(loanGlobalSettings.maxPercentOfEstimatedClosingAmountToBorrower)) /
        100
      if (value > closingMaxRehabBudget) {
        closingError = `May not exceed ${
          loanGlobalSettings.maxPercentOfEstimatedClosingAmountToBorrower
        }% 'Construction Budget' which are $${getPrice2decimal(closingMaxRehabBudget)}`
      }
    }
  } else {
    value =
      removeComma(loan.propertyPurchasePrice) -
      removeComma(loan.proposedLoanAmount) +
      removeComma(loan.closingCostEstimate) +
      removeComma(loan.interestReserve) +
      removeComma(loan.constructionReserve)
    closingTooltip =
      'Property Purchase Price<br/>- Proposed Loan Amount<br/>+ Closing Cost Estimate<br/>+ Interest Reserve<br/>+ Construction Amount Financed'

    if (!visibleFields.includes('constructionReserve')) {
      value -= removeComma(loan.constructionReserve)
      closingTooltip = closingTooltip.replace('<br/>+ Construction Amount Financed', '')
    }
    if (!visibleFields.includes('interestReserve')) {
      value -= removeComma(loan.interestReserve)
      closingTooltip = closingTooltip.replace('<br/>+ Interest Reserve', '')
    }
  }
  if (visibleFields.includes('rehabBudget') && removeComma(loan.rehabBudget) > 0) {
    rehabFinancedPercent = ((removeComma(loan.constructionReserve) * 100) / removeComma(loan.rehabBudget)).toFixed(2)
    const closingCost = loan.transactionType === 'Refinance' ? (value < 0 ? 0 : value) : 0
    totalRehabFinancedTooltip = `'Construction Amount Financed'${
      closingCost > 0 ? " + 'Estimated Closing Amount to Borrower'" : ''
    }/<br/>'Construction Budget'`
    totalRehabFinancedPercent = (
      ((removeComma(closingCost) + removeComma(loan.constructionReserve)) * 100) /
      removeComma(loan.rehabBudget)
    ).toFixed(2)
  }
  return {
    rehabFinancedPercent,
    totalRehabFinancedPercent,
    totalRehabFinancedTooltip,
    value,
    valueWithoutConstructionCalc,
    closingTooltip,
    closingMaxRehabBudget,
    closingError,
  }
}

export const validateLoanStructureData = (loan: ILoan, rateData: any, errorMessage: boolean = false) => {
  let errors = []
  const { auth } = store.getState()
  if (removeComma(rateData.rate) > 0) {
    // if (!isNonDSCRProduct(loan['productType'])) {
    //   const pitis = combinedMonthlyExpense(loan, rateData, true)
    //   if (
    //     removeComma(pitis['dscr']) < DscrRangeMapping[loan.estimatedDscr].from ||
    //     removeComma(pitis['dscr']) > DscrRangeMapping[loan.estimatedDscr].to
    //   ) {
    //     errors.push(`The 'DSCR Range' does not match the 'Calculated DSCR'!`)
    //   }
    // }
  } else if (!auth.profile.isBorrower) {
    errors.push(`Interest Rate & Base Price is Required!`)
  }
  const sameCredit = isSameStructureApplicationCreditScore()
  if (!sameCredit.same && sameCredit.value !== 0) {
    errors.push(sameCredit.error)
  }
  if (errorMessage && errors.length) {
    toast(errors.join('\n'), { type: 'error' })
  }
  return errors
}

export const isLtvAdjustments = (fields: string[]) => {
  return fields.includes('ltvAdjustment') || fields.includes('aivLtvAdjustment')
}

export const isEmptyPrices = (prices: IPrice[] = []) => {
  let rlt = true

  prices.forEach((item) => {
    if (!!item.Programs.length) rlt = false
  })

  return rlt
}

export const getRateSpreadExitFeeTooltip = (loan: ILoan, data: IPrice_Program_Price) => {
  const rateSpread = removeComma(data?.RateSpread as number)
  const exitFee = removeComma(data?.ExitFee as number)
  const loanAmount = removeComma(loan.proposedLoanAmount)

  return {
    rateSpread: `$${getPrice2decimal((loanAmount * rateSpread) / 100 / 12, false, true)}/month`,
    exitFee: `$${getPrice2decimal((loanAmount * exitFee) / 100, false, true)}`,
  }
}

export const calcInitialLoanAmount = (loan: ILoan) => {
  let definedVisibleFields: any = []
  try {
    definedVisibleFields = fieldsByTransactionTypeAndProductTypeConstant[loan['productType']]?.[loan['transactionType']]
    if (definedVisibleFields === undefined) definedVisibleFields = []
  } catch {}
  let rlt = removeComma(loan.proposedLoanAmount)
  if (definedVisibleFields.includes('constructionReserve')) rlt -= removeComma(loan.constructionReserve)
  if (rlt <= 0) return '0.00'
  return getPrice3decimal(rlt)
}

export const updateProduct = (products: IProduct[], prices: IPrice[], newData: IProduct, isUpdate: boolean) => {
  const newProducts = cloneDeep(products)
  const newPrices = cloneDeep(prices)

  if (isUpdate) {
    newProducts.forEach((item) => {
      if (item.Id === newData.Id) {
        item.Description = newData.Description
      }
    })
    newPrices.forEach((item) => {
      if (item.ProductID === newData.Id) {
        item.ProductName = newData.Description
        const state = store.getState()
        const loan = state.loan as ILoan

        if (!isNonDSCRProduct(loan.productType))
          item.Programs.forEach((prog) => {
            prog.Prices.forEach((price) => {
              const pitis = combinedMonthlyExpense(loan, { rate: price.Rate, type: item.ProductName }, true)
              price.Dscr = pitis.dscr
            })
          })
      }
    })
  } else {
    const isExist = newProducts.find((item) => item.Description === newData.Description)
    if (isExist) {
      toast(`The Product already exists`, { type: 'error' })
      return { newProducts, newPrices }
    }
    if (!!newProducts.length) {
      const maxIdProduct = products.reduce((maxProduct, currentProduct) => {
        return currentProduct.Id > maxProduct.Id ? currentProduct : maxProduct
      })
      newData.Id = maxIdProduct.Id + 1
    } else newData.Id = 0
    newProducts.push(newData)

    newPrices.push({
      ProductID: newData.Id,
      ProductName: newData.Description,
      Programs: [],
    })
  }

  return { newProducts, newPrices }
}

export const deleteProduct = (products: IProduct[], prices: IPrice[], product: IProduct) => {
  const newProducts = cloneDeep(products)
  const newPrices = cloneDeep(prices)

  let targetIndex = newProducts.findIndex((item) => item.Id === product.Id)
  newProducts.splice(targetIndex, 1)

  targetIndex = newPrices.findIndex((item) => item.ProductID === product.Id)
  newPrices.splice(targetIndex, 1)

  return { newProducts, newPrices }
}

export const updateProgram = (
  programs: IProgram[],
  prices: IPrice[],
  lockDays: ILockDay[],
  product: IProduct,
  program: IProgram,
  isUpdate: boolean,
) => {
  const newPrograms = cloneDeep(programs)
  const newPrices = cloneDeep(prices)

  if (isUpdate) {
    newPrograms.forEach((item) => {
      if (item.ID === program.ID) {
        item.Name = program.Name
      }
    })

    newPrices.forEach((item) => {
      item.Programs.forEach((data) => {
        if (data.ProgramID === program.ID) {
          data.ProgramName = program.Name
        }
      })
    })
  } else {
    const isExist = newPrograms.find((item) => item.Name === program.Name)
    if (isExist) program.ID = isExist.ID
    else {
      if (!!newPrograms.length) {
        const maxIdProgram = programs.reduce((maxProgram, currentProgram) => {
          return currentProgram.ID > maxProgram.ID ? currentProgram : maxProgram
        })
        program.ID = maxIdProgram.ID + 1
      } else program.ID = 0
      newPrograms.push(program)
    }

    newPrices.forEach((item) => {
      if (item.ProductID === product.Id) {
        const alreadyExist = item.Programs.find((data) => data.ProgramName === program.Name)
        if (alreadyExist) {
          toast(`The Program already exists`, { type: 'error' })
          return { newPrograms, newPrices }
        }

        const lockTermPrices: ILockTermPrice[] = []
        lockDays.forEach((lock) => {
          lockTermPrices.push({
            LockDay: lock.description,
            BasePrice: 0,
            LockTermLLPA: 0,
            Price: '0',
          })
        })
        item.Programs.push({
          ProgramID: program.ID,
          ProgramName: program.Name,
          Margin: NaN,
          Prices: [
            {
              Rate: 0,
              BaseRate: 0,
              Dscr: undefined,
              RateSpread: 0,
              ExitFee: 0,
              LockTermPrices: lockTermPrices,
              CalcHistory: [],
              LLPA: {},
              Limit: {},
            },
          ],
          LLPA: {},
          CalcHistory: [],
          LTV: { from: NaN, to: NaN },
        })
      }
    })
  }

  return { newPrograms, newPrices }
}

export const deleteProgram = (prices: IPrice[], productID: number, programID: number) => {
  const newPrices = cloneDeep(prices)

  newPrices.find((item) => {
    if (item.ProductID === productID) {
      const targetIndex = item.Programs.findIndex((prog) => prog.ProgramID === programID)
      item.Programs.splice(targetIndex, 1)
    }
  })

  return { newPrices }
}

export const updateLockDay = (
  prices: IPrice[],
  lockDays: ILockDay[],
  lockDayIndex: number,
  value: number,
  isUpdate: boolean,
) => {
  const newPrices = cloneDeep(prices)
  const newLockDays = cloneDeep(lockDays)

  if (isUpdate) {
    newLockDays[lockDayIndex].description = String(value)

    newPrices.forEach((item) => {
      item.Programs.forEach((data) => {
        data.Prices.forEach((rate) => {
          if (rate.LockTermPrices[lockDayIndex]) rate.LockTermPrices[lockDayIndex].LockDay = String(value)
        })
      })
    })
  } else {
    const isExist = newLockDays.find((item) => Number(item.description) === Number(value))
    if (isExist) {
      toast(`The Lock Day already exists`, { type: 'error' })
      return { newPrices, newLockDays }
    } else {
      newLockDays.push({
        description: String(value),
      })

      newPrices.forEach((item) => {
        item.Programs.forEach((prog) => {
          prog.Prices.forEach((price) => {
            price.LockTermPrices.push({
              LockDay: String(value),
              BasePrice: 0,
              LockTermLLPA: 0,
              Price: '0',
            })
          })
        })
      })
    }
  }

  return { newPrices, newLockDays }
}

export const deleteLockDay = (prices: IPrice[], lockDays: ILockDay[], index: number) => {
  const newPrices = cloneDeep(prices)
  const newLockDays = cloneDeep(lockDays)

  newLockDays.splice(index, 1)
  newPrices.forEach((item) => {
    item.Programs.forEach((prog) => {
      prog.Prices.forEach((price) => {
        price.LockTermPrices.splice(index, 1)
      })
    })
  })

  return { newLockDays, newPrices }
}

export const addRate = (
  prices: IPrice[],
  lockDays: ILockDay[],
  selectedProductId: number,
  selectedProgramId: number,
  index: number,
  value: number,
) => {
  const newPrices = cloneDeep(prices)

  newPrices.forEach((item) => {
    if (item.ProductID === selectedProductId) {
      item.Programs.forEach((prog) => {
        if (prog.ProgramID === selectedProgramId) {
          if (index === -1) {
            const lockTermPrices: ILockTermPrice[] = []
            lockDays.forEach((lock) => {
              lockTermPrices.push({
                LockDay: lock.description,
                BasePrice: 0,
                LockTermLLPA: 0,
                Price: '0',
              })
            })
            prog.Prices.push({
              Rate: 0,
              BaseRate: 0,
              Dscr: undefined,
              RateSpread: 0,
              ExitFee: 0,
              LockTermPrices: lockTermPrices,
              CalcHistory: [],
              LLPA: {},
              Limit: {},
            })
          } else {
            prog.Prices[index].Rate = value

            const state = store.getState()
            const loan = state.loan as ILoan

            if (!isNonDSCRProduct(loan.productType)) {
              const pitis = combinedMonthlyExpense(loan, { rate: value, type: item.ProductName }, true)
              prog.Prices[index].Dscr = pitis.dscr
            }
          }
        }
      })
    }
  })

  return { newPrices }
}

export const deleteRate = (prices: IPrice[], selectedProductId: number, selectedProgramId: number, index: number) => {
  const newPrices = cloneDeep(prices)

  newPrices.forEach((item) => {
    if (item.ProductID === selectedProductId) {
      item.Programs.forEach((prog) => {
        if (prog.ProgramID === selectedProgramId) {
          prog.Prices.splice(index, 1)
        }
      })
    }
  })

  return { newPrices }
}

export const updatePrice = (
  prices: IPrice[],
  selectedProductId: number,
  selectedProgramId: number,
  rateIndex: number,
  lockDayIndex: number,
  price: number,
) => {
  const newPrices = cloneDeep(prices)

  newPrices.forEach((item) => {
    if (item.ProductID === selectedProductId) {
      item.Programs.forEach((prog) => {
        if (prog.ProgramID === selectedProgramId) {
          prog.Prices[rateIndex].LockTermPrices[lockDayIndex].BasePrice = price
          prog.Prices[rateIndex].LockTermPrices[lockDayIndex].Price = price.toString()
        }
      })
    }
  })

  return { newPrices }
}

export const updateRateSpreadExitFee = (
  prices: IPrice[],
  selectedProductId: number,
  selectedProgramId: number,
  index: number,
  key: 'RateSpread' | 'ExitFee',
  value: number,
) => {
  const newPrices = cloneDeep(prices)

  newPrices.forEach((item) => {
    if (item.ProductID === selectedProductId) {
      item.Programs.forEach((prog) => {
        if (prog.ProgramID === selectedProgramId) {
          prog.Prices[index][key] = value
        }
      })
    }
  })

  return { newPrices }
}

export const getUWFee = (loan: ILoan, underwritingFees: IUnderwritingFee[], globalSetting: ILoanGlobalSettings) => {
  const proposedLoanAmount = removeComma(loan.proposedLoanAmount)
  const { productType, transactionType, propertyType } = loan

  const targetUWfee = underwritingFees.find(
    (item) =>
      item.productType === productType &&
      item.transactionType === transactionType &&
      item.propertyType === propertyType &&
      item.loanAmountFrom <= proposedLoanAmount &&
      item.loanAmountTo >= proposedLoanAmount,
  )

  if (targetUWfee) return targetUWfee.underwritingFee

  return globalSetting.defaultUnderwritingFee
}

export const validateMinMaxLimitPerTypes = (
  amount: number,
  limit: IMinMaxLimitPerTypes,
  loanAmount: number,
  title: string,
) => {
  const { minAmount, maxAmount, minPercent, maxPercent } = limit

  if (!isEmpty(minAmount) && !isEmpty(maxAmount)) {
    if (amount < minAmount || amount > maxAmount)
      return `${title} should be between $${getPrice3decimal(minAmount)} and $${getPrice3decimal(maxAmount)}`
  } else if (!isEmpty(minAmount) && isEmpty(maxAmount)) {
    if (amount < minAmount) return `${title} should be greater than $${getPrice3decimal(minAmount)}`
  } else if (isEmpty(minAmount) && !isEmpty(maxAmount)) {
    if (amount > maxAmount) return `${title} should be less than $${getPrice3decimal(maxAmount)}`
  }

  if (!isEmpty(minPercent) && !isEmpty(maxPercent)) {
    const minLimit = (loanAmount * minPercent) / 100
    const maxLimit = (loanAmount * maxPercent) / 100
    if (amount < minLimit || amount > maxLimit)
      return `${title} should be between ${getPrice3decimal(minPercent)}% and ${getPrice3decimal(
        maxPercent,
      )}% of Loan Amount`
  } else if (!isEmpty(minPercent) && isEmpty(maxPercent)) {
    const minLimit = (loanAmount * minPercent) / 100
    if (amount < minLimit) return `${title} should be greater than ${getPrice3decimal(minPercent)}% of Loan Amount`
  } else if (isEmpty(minPercent) && !isEmpty(maxPercent)) {
    const maxLimit = (loanAmount * maxPercent) / 100
    if (amount > maxLimit) return `${title} should be less than ${getPrice3decimal(maxPercent)}% of Loan Amount`
  }

  return ''
}
