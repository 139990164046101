import { PencilSquareIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { deleteEmailDnsConfig, getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { confirm } from 'utils'

import { EmailDNSSetupDetails } from './EmailDNSSetupDetails'
import { EmailDNSConfig, EmailProviderTypes } from './type'

export const EmailDNSSetup = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState<EmailDNSConfig | null>()
  const [data, setData] = useState<EmailDNSConfig[]>([])

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    setIsLoading(true)
    getAdminConfig('emailDNSSetup')
      .then((data) => {
        setData(data)
      })
      .finally(() => setIsLoading(false))
  }

  const onSetPrimary = (item: EmailDNSConfig) => {
    let newData = cloneDeep(data)
    newData = newData.map((v) => ({
      ...v,
      isPrimary: v.dns == item.dns,
    }))
    setData(newData)

    setIsLoading(true)
    setAdminConfig('emailDNSSetup', newData).then(refresh)
  }

  const onEdit = (item: EmailDNSConfig) => {
    setSelectedItem(item)
  }

  const onTrash = async (item: EmailDNSConfig) => {
    const result = await confirm(`Are you sure you want to delete this Domain (${item.dns})?`)
    if (!result) return

    setIsLoading(true)
    deleteEmailDnsConfig(item.dns)
      .then(() => refresh())
      .finally(() => setIsLoading(false))
  }

  if (selectedItem !== undefined)
    return (
      <EmailDNSSetupDetails
        defaults={selectedItem}
        onBack={(needRefresh: boolean) => {
          setSelectedItem(undefined)
          needRefresh && refresh()
        }}
      />
    )

  return (
    <div className="List-container relative">
      <LayoutLoading show={isLoading} />
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold flex items-center pb-5">Email DNS Setup</h1>
        <Button size="sm" onClick={() => setSelectedItem(null)}>
          Add
        </Button>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3">
                DNS
              </th>
              <th scope="col" className="px-3">
                Provider
              </th>
              <th scope="col" className="px-3">
                Created by
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-3">
                    <div className="flex gap-2 items-center">
                      <p>{item.dns}</p>
                      {item.verified && (
                        <p className="text-green-500 border rounded-full border-green-500 px-1 py-0.5 text-sm">
                          Verified
                        </p>
                      )}
                      {item.isPrimary && (
                        <p className="text-blue-500 border rounded-full border-blue-500 px-1 py-0.5 text-sm">Primary</p>
                      )}
                    </div>
                  </td>
                  <td className="px-3">{EmailProviderTypes[item.provider]}</td>
                  <td className="px-3">{item.createdBy}</td>
                  <td className="px-3">
                    <span className="flex">
                      {!item.verified && item.editable && (
                        <span className="btn-icon" onClick={() => onEdit(item)}>
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>
                      )}
                      {!item.isPrimary && item.verified && (
                        <span className="btn-icon !text-blue-500" onClick={() => onSetPrimary(item)}>
                          <StarIcon className="w-4 h-4" />
                        </span>
                      )}
                      {!item.isPrimary && item.editable && (
                        <span className="btn-icon !text-red-500" onClick={() => onTrash(item)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      )}
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
