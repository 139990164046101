import { ClockIcon, PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { UserSearch } from 'components/UserSearch'
import { useEffect, useRef, useState } from 'react'
import type { Color } from 'stories/types'

import { GoogleAutoComplete } from '../GoogleAutoComplete/GoogleAutoComplete'
import { Tooltip } from '../Tooltip/Tooltip'
import AutoCompleteItem from './AutoCompleteItem'

function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

interface AutoCompleteProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is autofocus
   */
  autoFocus?: boolean
  /**
   * Type of Input
   */
  type?: 'email' | 'text' | 'number'
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: any

  toLowerCase?: boolean
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * autoCompleteType
   */
  autoCompleteType?: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean

  splitByComma?: boolean

  userSearch?: boolean

  additionalComponent?: (v: any) => JSX.Element

  onChange: (e: any) => void // Array<string> => void

  onBlur?: () => void

  history?: boolean

  showHistory?: () => void

  additionalElements?: JSX.Element | Function | null
}

export const AutoComplete = ({
  color = 'sky',
  type = 'text',
  title = '',
  placeholder = ' ',
  disabled = false,
  value = [],
  toLowerCase = false,
  error = '',
  tooltip = '',
  className = '',
  required = false,
  splitByComma = false,
  userSearch = false,
  autoCompleteType = '',
  additionalComponent = undefined,
  onChange = () => {},
  onBlur = () => {},
  history = false,
  showHistory = () => {},
  additionalElements,
  ...props
}: AutoCompleteProps) => {
  const [values, setValues] = useState<Array<string | number>>(cloneDeep(value))
  const [isEditing, setEditing] = useState(false)
  const [text, setText] = useState('')
  const [textError, setTextError] = useState('')

  useEffect(() => {
    if (!Array.isArray(value)) {
      setValues([])
      return
    }
    if (JSON.stringify(value) !== JSON.stringify(values)) {
      setValues(value)
    }
  }, [value])

  const onRemove = (v: string | number) => {
    if (disabled) return
    let newValues = cloneDeep(values)
    const index = newValues.indexOf(v)
    newValues.splice(index, 1)
    onChange(newValues)
    setValues(cloneDeep(newValues))
    setTimeout(() => {
      divRef?.current?.click()
    }, 250)
  }

  const onAdd = () => {
    if (disabled) return
    setEditing(true)
  }

  const onTextUpdate = (t: string) => {
    setText(t)
    setTextError('')
  }

  const onConfirm = async (feed?: string) => {
    const newValues = cloneDeep(values)
    const _text = feed || text
    let texts = [_text]
    if (splitByComma) texts = _text.split(',')
    let hasError = false

    texts.map((text) => {
      let v: string | number = text.trim()
      if (toLowerCase) v = v.toLowerCase()
      if (!v) return
      if (type == 'number') v = parseInt(v)
      if (newValues.includes(v)) {
        setTextError('Already exists.')
        hasError = true
        return
      }
      if (type == 'email' && !validateEmail(v as string)) {
        setTextError('Email address is not in a valid format.')
        hasError = true
        return
      }
      if (type == 'number' && isNaN(v as number)) {
        setTextError('Value is not in a valid number format.')
        hasError = true
        return
      }
      newValues.push(v)
    })
    if (hasError) return

    try {
      await onChange(newValues)
      setValues(newValues)
      if (!feed) {
        setText('')
        setEditing(false)
        setTimeout(() => {
          divRef?.current?.click()
        }, 250)
      }
    } catch (e) {}
  }

  const onCancel = () => {
    setText('')
    setTextError('')
    setEditing(false)
  }

  const classNames = [
    'block',
    'rounded',
    'py-1.5',
    'px-2',
    'w-full',
    'text-sm',
    'text-gray-900',
    'bg-transparent',
    'border',
    'border-gray-300',
    'appearance-none',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    error && 'border-rose-700',
    className,
    disabled && 'bg-gray-100',
  ].join(' ')

  const divRef = useRef<HTMLDivElement>(null)

  return (
    <div className="input-container mb-3" {...props}>
      <div className={`group relative w-full text-left ${classNames}`}>
        <div className="group relative flex items-center">
          <label className="text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 gap-2 items-center">
            {title}
            {required && '*'}
          </label>
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {additionalElements !== null &&
            (typeof additionalElements == 'function' ? additionalElements(onChange) : additionalElements)}
          {history && (
            <span className="ml-3 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </div>
        <div className="flex flex-wrap my-2 gap-2" ref={divRef} onClick={onBlur}>
          {Array.isArray(values) &&
            values.map((v) => (
              <AutoCompleteItem
                key={v}
                value={v}
                onRemove={onRemove}
                additionalComponent={additionalComponent}
                autoCompleteType={autoCompleteType}
              />
            ))}
          <div
            className="flex border border-gray-300 p-2 mr-3 hover:bg-shade-blue hover:border-white cursor-pointer rounded text-gray-500 hover:text-white"
            onClick={onAdd}
          >
            <PlusIcon className="w-5 h-5" />
          </div>
        </div>

        {isEditing && (
          <div className="relative">
            <div className="flex w-full">
              {autoCompleteType === 'map' ? (
                <GoogleAutoComplete
                  title={'Address'}
                  key={`${title}-autoComplete-GoogleAddress`}
                  inputKey={`${title}-autoComplete-GoogleAddress`}
                  className="w-full"
                  error={error}
                  value={text || ''}
                  onChange={(value) => onTextUpdate(value)}
                />
              ) : (
                <input
                  type={type}
                  className="block p-2.5 w-full z-20 text-sm text-gray-900 rounded-l border border-gray-300 focus:border-shade-blue focus:outline-none focus:ring-0 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder={placeholder}
                  required
                  autoFocus
                  value={text}
                  onChange={(e) => onTextUpdate(e.target.value)}
                />
              )}
              <button
                type="button"
                className="p-2.5 text-sm font-medium text-white bg-shade-blue hover:bg-sky-600 cursor-pointer focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 focus:z-5"
                onClick={() => onConfirm()}
                disabled={!text.trim()}
              >
                Confirm
              </button>
              <button
                type="button"
                className="p-2.5 text-sm font-medium text-white bg-gray-400 rounded-r-lg cursor-pointer hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800 focus:z-5"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
            {userSearch && (
              <div className="">
                <UserSearch query={text} onSelect={(value: string) => onConfirm(value)} />
              </div>
            )}
          </div>
        )}
        {textError && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px]">{textError}</p>}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
