import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getFciLoanPortfolio } from 'services'
import { Alert } from 'stories/components'
import { thousandSeperatorWithPrefix } from 'utils'

const fieldMap: Record<string, string> = {
  loanAccount: 'FCI Servicing Number',
  maturityDate: 'Maturity Date',
  primaryPurpose: 'Primary Purpose',
  originalBalance: 'Original Balance',
  currentBalance: 'Current Balance',
  daysLate: 'Days late',
  nextDueDate: 'Next Due Date',
  noteRate: 'Note Rate',
  investorRate: 'Default Investor Rate',
  totalPayment: 'Total Payment Amount',
  loanStatus: 'Loan Status',
  boardingDate: 'Boarding Date',
}

const fundTypes = ['originalBalance', 'currentBalance', 'totalPayment']

export function Fci() {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<Record<string, any> | null>()

  useEffect(() => {
    getFciLoanPortfolio()
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  const renderData = () => {
    if (!data) return null

    return (
      <div className="max-w-md">
        {Object.keys(fieldMap).map((key) => (
          <div className="flex justify-between mb-2">
            <p className="text-gray-500">{fieldMap[key]}</p>
            <p>{fundTypes.includes(key) ? thousandSeperatorWithPrefix(data[key], '$') : data[key]}</p>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="servicing-document-container">
      <div className="relative max-w-screen-2xl min-h-[30rem]">
        <LayoutLoading show={isLoading} />

        {data === null && (
          <div className="max-w-2xl">
            <Alert color="yellow">Please confirm the Servicing Number from Servicing Notes section.</Alert>
          </div>
        )}
        {!!data && renderData()}
      </div>
    </div>
  )
}
