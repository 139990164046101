import { LogoBlue } from 'components/Logo'
import { COMPANY_NAME } from 'config'

export function HeaderSimple() {
  return (
    <div className="max-w-screen-2xl m-auto relative py-4 px-2 bg-white">
      <nav className="relative flex items-center justify-between" aria-label="Global">
        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <span className="sr-only">{COMPANY_NAME}</span>
            <LogoBlue />
            <div className="mr-2 flex items-center md:hidden"></div>
          </div>
        </div>
      </nav>
    </div>
  )
}
