import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const PaymentTermsConditionsConfig = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Array<string>>([])

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('paymentTerms')
    setData(res)
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onChange = (index: number, value: string) => {
    const temp = cloneDeep(data)
    temp[index] = value
    setData(temp)
  }

  const onAdd = async () => {
    const temp = cloneDeep(data)
    temp.push('')
    setData(temp)
    await onSubmit(temp)
  }

  const onRemove = async (index: number) => {
    const res = await confirm('Are you sure you want to remove this term?')
    if (!res) return

    const temp = cloneDeep(data)
    temp.splice(index, 1)
    setData(temp)
    await onSubmit(temp)
  }

  const onSubmit = async (payload?: any) => {
    setIsLoading(true)
    await setAdminConfig('paymentTerms', payload || data)
    setIsLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      {data.map((v: string, index: number) => {
        const input: InputType = {
          inputType: 'textarea',
          title: 'Text',
          value: v,
          rows: 3,
        }
        input.additionalElements = (
          <span className="text-red-800 p-1 cursor-pointer" onClick={() => onRemove(index)}>
            <TrashIcon className="w-4 h-4"></TrashIcon>
          </span>
        )
        return (
          <div className="mb-4">
            <RenderInput
              input={input}
              key={`term-${index}`}
              Key={`term-${index}`}
              onChange={(key: string, value: any) => onChange(index, value)}
              onBlur={() => onSubmit()}
            />
          </div>
        )
      })}
      <Button link onClick={onAdd}>
        + Add New
      </Button>
    </div>
  )
}
