import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { AddConditionsFromTemplates } from 'components/Modals/Condition'
import { SaveChanges } from 'components/SaveChanges'
import { InputType } from 'config'
import { getLoanInputFieldOptions, getLoanInputFields } from 'config/loan.input.fields.constants'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getAdminConfig, getConditions, setAdminConfig } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, PlainTable } from 'stories/components'
import { removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import {
  ILoanConditionVisibilityOption,
  ILoanStructureCondition,
  ILoanStructureConditionFieldOriginOptions,
} from './types'

export const LoanStructureConditions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [configs, setConfigs] = useState<ILoanStructureCondition[]>([])
  const [conditions, setConditions] = useState([])
  const [error, setError] = useState(false)
  const [isAddConditionModalOpen, setIsAddConditionModalOpen] = useState(false)
  const [selectedIdx, setSelectedIdx] = useState(-1)

  const initData = async () => {
    setIsLoading(true)
    const [res1, res2] = await Promise.all([getConditions(), getAdminConfig('loanStructureConditions')])
    setConditions(res1.data)
    setConfigs(res2)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const conditionOptions = useMemo(() => {
    let options: Record<string, any> = {}
    conditions.map((item: any) => {
      options[item.no] = `${item.no} - ${item.name}`
    })
    return options
  }, [conditions])

  const onAddCondition = () => {
    const temp = cloneDeep(configs)
    const newItem: ILoanStructureCondition = {
      visibleLogic: [],
      value: [],
      detectChange: false,
    }
    temp.push(newItem)
    setConfigs(temp)
  }

  const onRemoveCondition = (index: number) => {
    let temp = cloneDeep(configs)
    temp.splice(index, 1)
    setConfigs(temp)
    setChanged(true)
  }

  const renderVisibleLogicOR = (control: ILoanStructureCondition, index: number) => {
    const onAddVisibleLogic = (orIndex: number) => {
      const newConfigs = cloneDeep(configs)
      const newConfig = newConfigs[index]

      if (!newConfig.visibleLogic) newConfig.visibleLogic = []
      if (orIndex == -1) newConfig.visibleLogic.push([])
      else
        newConfig.visibleLogic[orIndex].push({
          fieldOrigin: '',
          fieldKey: '',
          value: '',
        } as any)
      setConfigs(newConfigs)
      setChanged(true)
    }

    const onRemoveVisibleLogic = (orIndex: number, andIndex: number = -1) => {
      const newConfigs = cloneDeep(configs)
      const newConfig = newConfigs[index]

      if (!newConfig.visibleLogic) return

      if (andIndex == -1) newConfig.visibleLogic.splice(orIndex, 1)
      else newConfig.visibleLogic[orIndex].splice(andIndex, 1)

      setConfigs(newConfigs)
      setChanged(true)
    }

    const onChangeDetect = (key: string, value: any) => {
      const newConfigs = cloneDeep(configs)
      const newConfig = newConfigs[index]

      newConfig.detectChange = value
      setConfigs(newConfigs)
      setChanged(true)
    }

    const renderVisibleLogic = (option: ILoanConditionVisibilityOption, orIndex: number, andIndex: number) => {
      const onUpdateVisibleLogic = (key: string, value: any, input: Record<string, any>) => {
        const newConfigs = cloneDeep(configs)
        const newConfig = newConfigs[index]

        if (['number', 'thousandSep', 'thousandSepNoDecimal'].includes(input.type)) value = removeComma(value)
        ;(newConfig.visibleLogic![orIndex][andIndex] as any)[key] = value
        if (['fieldOrigin', 'fieldKey'].includes(key)) {
          ;(newConfig.visibleLogic![orIndex][andIndex] as any).value = ''
          if (key === 'fieldOrigin') (newConfig.visibleLogic![orIndex][andIndex] as any).fieldKey = ''
        }
        setConfigs(newConfigs)
        setChanged(true)
      }

      const { fieldOrigin, fieldKey, value } = option
      const inputs: Record<string, InputType> = {
        fieldOrigin: {
          inputType: 'select',
          title: 'Page Origin',
          options: ILoanStructureConditionFieldOriginOptions,
          hasDefaultOption: true,
          value: fieldOrigin,
          required: true,
          sort: false,
          span: 1,
        },
        fieldKey: {
          inputType: 'select',
          title: 'Select Field',
          options: getLoanInputFieldOptions()[fieldOrigin] || {},
          hasDefaultOption: true,
          value: fieldKey,
          required: true,
          sort: true,
          span: 1,
        },
      }
      if (fieldKey) {
        inputs.value = {
          ...getLoanInputFields()[fieldOrigin][fieldKey],
          value: value as any,
          span: 'full',
        }
      }
      return (
        <div className="pl-4">
          <div className="flex justify-between">
            <p className="text-sm">
              Condition {orIndex + 1} - {andIndex + 1}
            </p>
            <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex, andIndex)}>
              <MinusIcon className="w-4 h-4" />
            </span>
          </div>
          <div className="grid gap-x-2 grid-cols-2">
            {Object.keys(inputs).map((key) => {
              const elementKey = `${index}-${fieldKey}-${key}`
              return (
                <div className={`mb-4 col-span-${inputs[key].span}`} key={elementKey}>
                  <RenderInput
                    input={{
                      ...inputs[key],
                      error:
                        error && inputs[key].value === '' && !(configs[index].detectChange && key === 'value')
                          ? 'This field is required'
                          : '',
                    }}
                    Key={elementKey}
                    onChange={(_key: string, value: string) => onUpdateVisibleLogic(key, value, inputs[key])}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    const renderVisibleLogicAND = (logics: ILoanConditionVisibilityOption[], orIndex: number) => {
      return (
        <div className={`${orIndex % 2 == 0 ? `bg-slate-100` : ''} p-2`}>
          <div className="flex justify-between items-center">
            <p className="font-bold text-sm">{orIndex + 1}. Trigger Conditions (AND)</p>
            <div className="flex">
              <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex)}>
                <MinusIcon className="w-4 h-4" />
              </span>
              <span className="btn-icon" onClick={() => onAddVisibleLogic(orIndex)}>
                <PlusIcon className="w-4 h-4" />
              </span>
            </div>
          </div>

          {logics.map((v, andIndex) => (
            <>
              {renderVisibleLogic(v, orIndex, andIndex)}
              {andIndex < logics.length - 1 && (
                <div className="flex justify-center">
                  <PlusIcon className="w-6 h-6" />
                </div>
              )}
            </>
          ))}
        </div>
      )
    }

    return (
      <div>
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center gap-2">
            <p className="font-bold text-sm">Trigger Conditions (OR)</p>
            <div>
              <RenderInput
                input={{ inputType: 'checkbox', title: 'Detect Change', value: configs[index].detectChange }}
                onChange={onChangeDetect}
                Key={`detectchange-${index}`}
              />
            </div>
          </div>
          <span className="btn-icon" onClick={() => onAddVisibleLogic(-1)}>
            <PlusIcon className="w-4 h-4" />
          </span>
        </div>

        {!!control.visibleLogic && control.visibleLogic.map((v, logicIdx) => renderVisibleLogicAND(v, logicIdx))}
      </div>
    )
  }

  const onSave = async () => {
    let hasError = false,
      detectChangeCountError = false,
      detectChangeTypeError = false
    configs.map((config: ILoanStructureCondition) => {
      if (!config.value.length) hasError = true
      if (config.detectChange) {
        if (!(config.visibleLogic.length == 1 && config.visibleLogic[0].length == 1)) detectChangeCountError = true
        else {
          const { fieldOrigin, fieldKey } = config.visibleLogic[0][0]
          if (
            !fieldOrigin ||
            !fieldKey ||
            !['text', 'Text'].includes(getLoanInputFields()[fieldOrigin][fieldKey].inputType)
          )
            detectChangeTypeError = true
        }
        return
      }
      config.visibleLogic.map((andLogic: ILoanConditionVisibilityOption[]) => {
        andLogic.map((logic: ILoanConditionVisibilityOption) => {
          if ((logic.fieldOrigin as any) == '' || logic.fieldKey === '' || logic.value === '') hasError = true
        })
      })
    })
    setError(hasError)
    if (hasError) {
      toast('Please fill required informations', { type: 'error' })
      return
    }
    if (detectChangeCountError) {
      toast('Detect change conditions should have only 1 condition.', { type: 'error' })
      return
    }
    if (detectChangeTypeError) {
      toast('Detect change conditions should have "Text" Field.', { type: 'error' })
      return
    }
    setIsLoading(true)
    await setAdminConfig('loanStructureConditions', configs)
    setIsLoading(false)
    setChanged(false)
    setError(false)
    toast('Successfully updated Loan Structure Conditions', { type: 'success' })
  }

  const onSubmitConditionsFromTemplates = (conditions: Array<any>) => {
    const conditionNos = conditions.map((item) => item.no).sort()
    const temp = cloneDeep(configs)
    temp[selectedIdx].value = conditionNos
    setConfigs(temp)
    setChanged(true)
    setSelectedIdx(-1)
    setIsAddConditionModalOpen(false)
  }

  return (
    <div className="Templates-container">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Loan Structure & Application Conditions
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      {configs.map((item, index: number) => {
        return (
          <div className="mt-4">
            <div className={`border-b-2 border-shade-blue w-full italic flex justify-between items-center`}>
              <p>- Condition {index + 1}</p>

              <div className="flex items-center gap-2">
                <p className="text-sm cursor-pointer" onClick={() => onRemoveCondition(index)}>
                  - Remove
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-2">
              {renderVisibleLogicOR(item, index)}
              <div className="col-span-1">
                <Button
                  link
                  onClick={() => {
                    setIsAddConditionModalOpen(true)
                    setSelectedIdx(index)
                  }}
                  className={`${error && !item.value.length ? 'border-red-500 border-[1px]' : ''} p-2`}
                >
                  + Add Condition
                </Button>
                {item.value.length ? (
                  <div className="mt-4 max-h-[350px] overflow-y-auto">
                    <PlainTable
                      header={['Conditions']}
                      data={item.value.map((v) => [conditionOptions[v]])}
                      tdClass="px-6 py-2"
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )
      })}
      <div className="flex flex-row justify-center gap-4 mt-4">
        <Button link onClick={onAddCondition}>
          + Add New Condition
        </Button>
        <Button onClick={onSave}>Save</Button>
      </div>
      {isAddConditionModalOpen && (
        <AddConditionsFromTemplates
          onAfterSubmit={onSubmitConditionsFromTemplates}
          type="noButton"
          onClose={() => setIsAddConditionModalOpen(false)}
          exists={configs[selectedIdx].value}
        />
      )}
      <SaveChanges show={changed} label="Save Changes" onSave={onSave} />
    </div>
  )
}
