import { getInitialLoanFields } from 'config'
import { InputTypeOfLoans } from 'config/loan.input.fields.constants'
import { FieldOrigin } from 'config/loan.input.visibility.type'
import { defaultInputs as borrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { defaultInputs as propertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'

export const getInputs = (): InputTypeOfLoans => {
  return {
    [FieldOrigin.LoanStructure]: getInitialLoanFields(),
    [FieldOrigin.BorrowerInformation]: borrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: propertyInformationInputs(),
  } as any
}
