import { companyName } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTitle } from 'utils/pageTitle'

import { AccountExecutives } from './AccountExecutives'
import { BankerSearch } from './BankerSearch'
import { BorrowerPipelineConfiguration } from './BorrowerPipelineConfiguration'
import { AccountVerificationType, BrokerVerificationCategory } from './BrokerVerificationCategory'
import { CompanyBroadcast } from './CompanyBroadcast'
import { Configuration } from './Configuration/index'
import { Credentials } from './Credentials'
import { EmailDNSSetup } from './EmailDNSSetup'
import { ExpirationDateType } from './ExpirationDateType'
import { HomePageCreditBox } from './HomePageCreditBox'
import { LandingLearnMore } from './LandingLearnMore'
import { LoginPageNotifications } from './LoginPageNotifications'
import { NewPermissions } from './NewPermissions'
import { Projects } from './Projects'
import { ResourceBroadcast } from './ResourceBroadcast'
import { StripeManagements } from './StripeManage'
import { Survey } from './Survey'

type menuType = {
  [key: string]: string //fix this
}

export default function AdminTools() {
  useTitle(`Admin Tools - ${companyName}`)

  const { menu = '' } = useParams() as any
  const navigate = useHistory()
  const [selectedMenu, setSelectedMenu] = useState(menu)
  const [menus, setMenus] = useState<menuType>({})

  const { hasPermission } = usePermissions()

  useEffect(() => {
    let temp: menuType = {}
    if (hasPermission('MANAGE_ADMIN_TOOLS')) {
      temp = {
        permissions: 'Permissions',
        homePage: 'Home Page Credit Box',
        loginModal: 'Login Page Notifications',
        companyBroadcast: 'Company Broadcast',
        brokerVerification: 'Broker Approval Categories',
        correspondentVerification: 'Correspondent Approval Categories',
        tablefunderVerification: 'Table Funder Approval Categories',
        contractorApprovalVerification: 'Contractor Approval Categories',
        resourcesBroadcast: 'Resources Broadcast',
        stripeManage: 'Stripe Management',
        expirationDatesTypes: 'Expiration Date',
        configuration: 'Configuration',
        emailDNSSetup: 'Email DNS Setup',
        projects: 'Projects',
        credentials: 'Credentials',
        borrowerPipeline: 'Customize Borrower Application',
      }
    }
    if (hasPermission('MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR')) {
      temp = {
        ...temp,
        bankerSearch: 'Appraiser / Settlement Agent / Contractor',
      }
    }
    if (hasPermission('MANAGE_REVIEWS')) {
      temp = {
        ...temp,
        reviews: 'Reviews',
        accountExecutives: 'Account Executives',
        landingLearnMore: 'Landing Learn More',
      }
    }

    setMenus(temp)
  }, [])

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate.push(`/admin_tool/${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'bankerSearch':
        return <BankerSearch />

      case 'permissions':
        return <NewPermissions />

      case 'homePage':
        return <HomePageCreditBox />

      case 'loginModal':
        return <LoginPageNotifications />

      case 'companyBroadcast':
        return <CompanyBroadcast />

      case 'brokerVerification':
      case 'correspondentVerification':
      case 'tablefunderVerification':
      case 'contractorApprovalVerification':
        return (
          <div key={selectedMenu}>
            <BrokerVerificationCategory type={selectedMenu.replace('Verification', '') as AccountVerificationType} />
          </div>
        )

      case 'resourcesBroadcast':
        return <ResourceBroadcast />

      case 'stripeManage':
        return <StripeManagements />

      case 'expirationDatesTypes':
        return <ExpirationDateType />

      case 'reviews':
        return <Survey />

      case 'accountExecutives':
        return <AccountExecutives />

      case 'landingLearnMore':
        return <LandingLearnMore />

      case 'configuration':
        return <Configuration />

      case 'emailDNSSetup':
        return <EmailDNSSetup />

      case 'projects':
        return <Projects />

      case 'credentials':
        return <Credentials />

      case 'borrowerPipeline':
        return <BorrowerPipelineConfiguration />

      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  useEffect(() => {
    let newMenu = menu
    if (!menu || !Object.keys(menus).includes(newMenu)) newMenu = Object.keys(menus)[0]
    if (!newMenu) return
    setMenu(newMenu)
  }, [menus, menu])

  return (
    <div className="admin-tools-container py-6">
      <div className="admin-tools-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(menus).map((item: string, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenu(item)
                  }}
                  className="border-b py-2"
                >
                  <p
                    className={`hover:underline cursor-pointer ${
                      selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                    }`}
                  >
                    {index + 1}. {menus[item as keyof typeof menus]}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
