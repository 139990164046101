export enum EmailProvider {
  Default = 1,
  SendGrid = 2,
}

export interface IEmailDNSBase {
  dns: string
  isPrimary: boolean
  verified: boolean
  editable: boolean
  createdBy: string
}

interface IDefaultProps extends IEmailDNSBase {
  provider: EmailProvider.Default
}

interface ISendGridProps extends IEmailDNSBase {
  provider: EmailProvider.SendGrid
  apiKey: string
}

export type EmailDNSConfig = IDefaultProps | ISendGridProps

export const EmailProviderTypes = {
  [EmailProvider.Default]: 'Default',
  [EmailProvider.SendGrid]: 'SendGrid',
}

export interface ISendGridDomain {
  id: number
  user_id: number
  subdomain: string
  domain: string
  username: string
  ips: []
  custom_spf: boolean
  default: boolean
  legacy: boolean
  automatic_security: boolean
  valid: boolean
  dns: Record<
    'mail_cname' | 'dkim1' | 'dkim2',
    {
      valid: boolean
      type: string
      host: string
      data: string
      data_with_valimail?: string
    }
  >
  last_validation_attempt_at?: number
}

export interface ISendGridValidateResult {
  id: number
  valid: boolean
  validation_results: Record<
    'mail_cname' | 'dkim1' | 'dkim2' | 'dmarc',
    {
      valid: boolean
      reason: string
    }
  >
}

export type IDomainData = ISendGridDomain
