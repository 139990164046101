import type { InputType } from 'config'
import type { FormTableHeader } from 'stories/components'

export const header: FormTableHeader[] = [
  { key: 'ID', title: 'Order ID' },
  {
    key: 'referenceWithFrom',
    title: `<span><div class="border-b w-fit mb-1 border-gray-300">Reference</div>From Whom Received/Paid</span>`,
  },
  {
    key: 'eligibleWithNetAmount',
    title: `<span><div class="border-b w-fit mb-1 border-gray-300">Eligible Amount</div>Net Eligible Amount</span>`,
  },
  { key: 'recommendedDisbursement', title: 'Max Eligible Disbursement' },
  { key: 'previouslyDisbursed', title: 'Previously Disbursed' },
  {
    key: 'paymentWithDeposit',
    title: `<span><div class="border-b w-fit mb-1 border-gray-300">Payment</div>Deposit</span>`,
  },
  {
    key: 'feeWithAddHoldback',
    title: `<span><div class="border-b w-fit mb-1 border-gray-300">Fee</div>Additional Holdback</span>`,
  },
  {
    key: 'drawWithBalance',
    title: `<span><div class="border-b w-fit mb-1 border-gray-300">Draw Disbursed</div>Balance</span>`,
  },
  { key: 'dateWithFiles', title: `<span><div class="border-b w-[90px] mb-1 border-gray-300">Date</div>Files</span>` },
]

export const interestHeader: FormTableHeader[] = [
  { key: 'reference', title: 'Reference' },
  { key: 'fromWhom', title: 'From Whom Received/Paid' },
  { key: 'payment', title: 'Payment' },
  { key: 'deposit', title: 'Deposit' },
  { key: 'balance', title: 'Balance' },
  { key: 'date', title: 'Date' },
]

export const additionalInputs = (): Record<string, InputType> => {
  return {
    isWiringInstructions: {
      inputType: 'toggle',
      value: false,
      title: 'Wiring Instructions',
      span: 2,
    },
    pledgedFacility: {
      inputType: 'text',
      type: 'text',
      title: 'Pledged Facility',
      span: 1,
      history: true,
    },
    interestType: {
      inputType: 'select',
      options: ['Dutch', 'Non Dutch'],
      hasDefaultOption: true,
      title: 'Interest Type',
      history: true,
    },
    accountName: {
      inputType: 'text',
      type: 'text',
      title: 'Name on Account',
      history: true,
      visible: false,
    },
    accountAddress: {
      inputType: 'map',
      title: 'Address on Account',
      history: true,
      visible: false,
    },
    routingNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Routing Number',
      history: true,
      maxLength: 9,
      visible: false,
    },
    accountNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Account Number',
      history: true,
      minLength: 8,
      maxLength: 17,
      visible: false,
    },
    bankName: {
      inputType: 'text',
      type: 'text',
      title: 'Bank Name',
      history: true,
      visible: false,
    },
    comment: {
      inputType: 'textarea',
      title: 'Comment',
      visible: false,
      history: true,
    },
    interestComment: {
      inputType: 'textarea',
      title: 'Interest Reserve Comment',
      visible: false,
      history: true,
    },
  }
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
      required: true,
    },
    reference: {
      inputType: 'text',
      type: 'text',
      title: 'Reference',
      value: '',
      error: '',
      required: true,
    },
    fromWhom: {
      inputType: 'text',
      type: 'text',
      title: 'From Whom Received/Paid',
      value: '',
      error: '',
      required: true,
    },
    eligibleAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Gross Eligible Amount',
      prefix: '$',
      value: '',
      error: '',
    },
    netEligibleAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Net Eligible Amount of Gross',
      prefix: '$',
      value: '',
      error: '',
      disabled: true,
    },
    previouslyDisbursed: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Previously Disbursed',
      prefix: '$',
      value: '',
      error: '',
      readOnly: true,
    },
    grossEligibleDrawAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'New Eligible Amount',
      prefix: '$',
      value: '',
      error: '',
      readOnly: true,
    },
    inspectionFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Inspection Fee',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    wireFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Wire Fee',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    titleUpdateFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Title Update Fee',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    additionHoldback: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Additional Holdback',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    recommendedDisbursement: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Max Eligible Disbursement',
      prefix: '$',
      value: '',
      error: '',
      readOnly: true,
    },
    payment: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Disbursement',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    deposit: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Deposit',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    totalDraw: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Total Draw',
      prefix: '$',
      value: '',
      error: '',
      readOnly: true,
    },
    notes: {
      inputType: 'textarea',
      title: 'Note',
      value: '',
      error: '',
      span: 2,
    },
    files: {
      inputType: 'FileTable',
      title: 'Files',
      value: [],
      error: '',
      span: 3,
      filePath: '',
      required: false,
    },
  }
}

export const interestDefaultInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
      required: true,
    },
    reference: {
      inputType: 'text',
      type: 'text',
      title: 'Reference',
      value: '',
      error: '',
      required: true,
    },
    fromWhom: {
      inputType: 'text',
      type: 'text',
      title: 'From Whom Received/Paid',
      value: '',
      error: '',
      required: true,
    },
    payment: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Payment',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    deposit: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Deposit',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
  }
}
