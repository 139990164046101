import { Link } from 'react-router-dom'

export const renderNav = (item: { href: string; name: string }, key: string, className: string) => {
  if (item.href && item.href.startsWith('http'))
    return (
      <a key={key} href={item.href} target="_blank" className={className}>
        <span dangerouslySetInnerHTML={{ __html: item.name }} />
      </a>
    )

  return (
    <Link key={key} to={item.href} className={className}>
      <span dangerouslySetInnerHTML={{ __html: item.name }} />
    </Link>
  )
}
