import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { condConstant } from 'pages/Admin'
import { useState } from 'react'
import { getConditionsByNos, getConditionsSimply } from 'services'
import { Button, ButtonGroup, Checkbox, Input2, Modal } from 'stories/components'

export const AddConditionsFromTemplates = ({ onAfterSubmit = () => {}, ...props }: any) => {
  const [conditions, setConditions] = useState<Array<any>>([])
  const [defaultConditions, setDefaultConditions] = useState<Array<number>>([])
  const [type, setType] = useState('ALL')
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())

  const [selectedConditions, setSelectedConditions] = useState<Record<number, boolean>>({})
  const [isLoading, setLoading] = useState(false)

  const hasButton = props.type !== 'noButton'

  const onOpen = () => {
    setLoading(true)
    getConditionsSimply()
      .then(({ conditions, exists }) => {
        const data = conditions.sort((a: any, b: any) => {
          if (a.type > b.type) return 1
          else if (a.type == b.type) return 0
          return -1
        })
        setConditions(data)
        if (hasButton) setDefaultConditions(exists)
        else {
          const temp: Record<number, boolean> = {}
          props.exists?.map((v: any) => {
            temp[Number(v)] = true
          })
          setSelectedConditions(temp)
        }
      })
      .finally(() => setLoading(false))
  }

  const onChangeType = async (key: string) => {
    setType(key)
  }

  const onCloseUserModal = () => {
    setSelectedConditions({})
  }

  const onChangeCheck = (no: number, value: boolean) => {
    const newData = cloneDeep(selectedConditions)
    if (value) newData[no] = value
    else delete newData[no]
    setSelectedConditions(newData)
  }

  const onSubmit = async () => {
    const conditionNos = Object.keys(selectedConditions).map((v) => parseInt(v))
    setLoading(true)
    const selectedConds = await getConditionsByNos(conditionNos)
    await onAfterSubmit(selectedConds)
    setLoading(false)
    setIsOpen(false)
    setLastUpdatedAt(Date.now())
  }

  return (
    <Modal
      button={
        !hasButton ? (
          <></>
        ) : props.type === 'exception' ? (
          <span className="hover:text-shade-blue cursor-pointer text-[15px] underline">Add Condition</span>
        ) : (
          <Button outline>Add Condition</Button>
        )
      }
      title={'Add Conditions from Templates'}
      titleOkay="Add"
      isOpen={hasButton ? isOpen : true}
      lastUpdatedAt={lastUpdatedAt}
      onClose={hasButton ? onCloseUserModal : props.onClose}
      onOk={onSubmit}
      loading={isLoading}
      disabled={!Object.keys(selectedConditions).length}
      onOpen={onOpen}
    >
      <div className="max-w-2xl">
        <div className="mb-3">
          <ButtonGroup title={['ALL', ...condConstant.types]} onChange={onChangeType} value={type} />
        </div>

        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
          className="mb-3"
          value={query}
          onChange={(value) => setQuery(value)}
        />
        <div className="overflow-auto">
          <table className="text-left text-sm w-full">
            <thead>
              <tr className="bg-gray-200 rounded">
                <th className="p-2">No</th>
                <th className="p-2">Description</th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              {conditions.map((condition, index: number) => {
                let className = index % 2 ? 'bg-gray-50' : ''
                if (type != 'ALL' && type != condition.type) className = 'hidden'
                else if (
                  query &&
                  !`${condition.no} ${condition.type} ${condition.description}`
                    .toLowerCase()
                    .includes(query.toLowerCase())
                )
                  className = 'hidden'

                return (
                  <tr key={`tr-${condition.no}`} className={`border-b ${className}`}>
                    <td className="py-2 px-2 border-b h-fit">
                      <Checkbox
                        title={`${condition.type}-${condition.no}`}
                        id={condition.no}
                        disabled={hasButton ? defaultConditions.includes(condition.no) : false}
                        value={defaultConditions.includes(condition.no) || !!selectedConditions[condition.no]}
                        onChange={(value) => onChangeCheck(condition.no, value)}
                      />
                    </td>
                    <td className="px-2 border-b">{condition.description}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
