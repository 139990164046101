import { MagnifyingGlassIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { ClosingCostsMap, ClosingCostStatus, GFEBlockMap, PaidToMap, ResponsiblePartyMap, TridBlockMap } from 'config'
import { useEffect, useState } from 'react'
import { getClosingCostSetups } from 'services/apis'
import { svgLoading } from 'stories/assets'
import { Input2 } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { ClosingCostSetupDetails } from './details'
import { IClosingCostSetup } from './type'

export const ClosingCostSetup = () => {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isEditing, setIsEditing] = useState(false)
  const [closingCostSetups, setClosingCostSetups] = useState<IClosingCostSetup[]>([])
  const [statusFilter, setStatusFilter] = useState('')

  useEffect(() => {
    refresh()
    setClosingCostSetups([])
  }, [])

  const refresh = () => {
    setIsLoading(true)
    getClosingCostSetups()
      .then(({ data }) => {
        setTotal(data.length)
        setClosingCostSetups(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  // const onAdd = () => {
  //   setSelectedIndex(-1)
  //   setIsEditing(true)
  // }

  const onEdit = (index: number) => {
    setSelectedIndex(index)
    setIsEditing(true)
  }

  // const onTrash = (index: number) => {
  //   setSelectedIndex(index)
  // }

  if (isEditing)
    return (
      <ClosingCostSetupDetails
        defaults={selectedIndex == -1 ? null : closingCostSetups[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  return (
    <div>
      {' '}
      <div className="Conditions-container">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          Closing Cost Setup
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <div className="flex flex-wrap justify-between mb-3">
          <div className="flex items-center flex-wrap gap-2">
            <div className="md:w-96 w-72">
              <Input2
                type="search"
                title="Search"
                hasIcon
                icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                value={query}
                onChange={(value) => setQuery(value)}
              />
            </div>
            <div className="md:w-72 w-48">
              <RenderInput
                input={{
                  inputType: 'select',
                  options: ClosingCostStatus,
                  title: 'Status',
                  value: statusFilter,
                  hasDefaultOption: true,
                }}
                Key="statusFilter"
                onChange={(key: string, value: any) => setStatusFilter(value)}
              />
            </div>
            <p className="ml-5">- {total} closing costs</p>
          </div>
          {/* <div className="w-32">
            <Button full onClick={onAdd}>
              Add
            </Button>
          </div> */}
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  Line ID
                </th>
                <th scope="col" className="py-3">
                  HUD No
                </th>
                <th scope="col" className="px-3">
                  Line Name
                </th>
                <th scope="col" className="px-3">
                  TRID Block
                </th>
                <th scope="col" className="px-3">
                  GFE Block
                </th>
                <th scope="col" className="px-3">
                  Type
                </th>
                <th scope="col" className="px-3">
                  Paid To
                </th>
                <th scope="col" className="px-3">
                  Responsible Party
                </th>
                <th scope="col" className="px-3">
                  Status
                </th>
                <th scope="col" className="px-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="">
              {closingCostSetups.map((closingCost, index) => {
                const { lineId, hudNo, lineName, tridBlock, gfeBlock, type, paidToType, responsiblePartyType, status } =
                  closingCost
                const conditionTexts = [
                  lineId,
                  hudNo,
                  lineName,
                  TridBlockMap[tridBlock],
                  GFEBlockMap[gfeBlock],
                  ClosingCostsMap[type],
                ]
                  .join(' ')
                  .toLowerCase()
                if (query && !conditionTexts.includes(query.trim().toLowerCase())) return null
                if (statusFilter && status != Number(statusFilter)) return null
                return (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                    <td className="px-3 py-3">{lineId}</td>
                    <td className="px-3">{hudNo}</td>
                    <td className="px-3">{lineName}</td>
                    <td className="px-3">{TridBlockMap[tridBlock]}</td>
                    <td className="px-3">{GFEBlockMap[gfeBlock]}</td>
                    <td className="px-3">{ClosingCostsMap[type]}</td>
                    <td className="px-3">{PaidToMap[paidToType]}</td>
                    <td className="px-3">{ResponsiblePartyMap[responsiblePartyType]}</td>
                    <td className="px-3">{ClosingCostStatus[status]}</td>
                    <td className="">
                      <span className="flex px-3">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onEdit(index)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                        {/* <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(index)}>
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span> */}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
