import { lazy } from 'react'
import { Route } from 'react-router-dom'

const BorrowerAmortizationSchedule = lazy(
  () => import('pages/Borrower/AmortizationSchedule/BorrowerAmortizationSchedule'),
)
const BorrowerBudgetScopeOfWork = lazy(() => import('pages/Borrower/BudgerScopeOfWork/BorrowerBudgetScopeOfWork'))
const BorrowerConstructionLedger = lazy(() => import('pages/Borrower/ConstructionLedger/BorrowerConstructionLedger'))
const BorrowerDrawRequest = lazy(() => import('pages/Borrower/DrawRequest/BorrowerDrawRequest'))
const BorrowerEditProfile = lazy(() => import('pages/Borrower/EditProfile/EditProfile'))
const BorrowerLoanApplication = lazy(() => import('pages/Borrower/Application/BorrowerLoanApplication'))
const BorrowerLoanOverview = lazy(() => import('pages/Borrower/LoanOverview/BorrowerLoanOverview'))
const BorrowerLoanSubmission = lazy(() => import('pages/Borrower/Submission/BorrowerLoanSubmission'))
const BorrowerPipeline = lazy(() => import('pages/Borrower/Pipeline/BorrowerPipeline'))
const InvestorEditProfile = lazy(() => import('pages/Investor/EditProfile/EditProfile'))
const InvestorLoanOverview = lazy(() => import('pages/Investor/LoanOverview/InvestorLoanOverview'))
const InvestorPipeline = lazy(() => import('pages/Investor/Pipleline/InvestorPipeline'))
const BorrowerEditBudget = lazy(() => import('pages/Loan/Budget/BorrowerEditBudget'))
const CustomSignDocumentGenie = lazy(() => import('pages/Loan/DocumentGenie/CustomSignDocumentGenie'))
const LoanServicingExtensionSign = lazy(() => import('pages/Loan/Servicing/LoanExtension/LoanServicingExtensionSign'))
const OrderValuationFeasibilitySign = lazy(() => import('pages/Loan/OrderAppraisals/FeasibilitySign'))
const PurchaseAdviceSellerSign = lazy(
  () => import('pages/Loan/PostClosing/PurchaseAdviceSeller/PurchaseAdviceSellerSign'),
)
const SignClosingCalendar = lazy(() => import('pages/Loan/ClosingCalendar/SignClosingCalendar'))
const CustomMemberSignApplication = lazy(
  () => import('pages/LoanApplication/AdditionalMember/CustomMemberSignApplication'),
)
const AssetInformationVerificationPage = lazy(
  () => import('pages/LoanApplication/AssetInformation/AssetInformationVerificationPage'),
)
const CustomSignApplication = lazy(() => import('pages/LoanApplication/CustomSignApplication/index'))
const SignLoanTermSheet = lazy(() => import('pages/LoanStructure/SignLoanTermSheet'))
const BrokerVerificationForUser = lazy(() => import('pages/ManageAccounts/BrokerVerificationForUser'))
const MakePaymentPage = lazy(() => import('pages/Payment/MakePaymentPage'))
const ContractorSignApplication = lazy(() => import('pages/Temporary/ContractorSignApplication'))
const SurveyPage = lazy(() => import('pages/Temporary/Survey/index'))
const TrustLedgerAgreement = lazy(() => import('pages/Temporary/TrustLedgerAgreement/index'))

export const TemporaryRoute = [
  <Route exact path="/borrowerPipelines" key={0}>
    <BorrowerPipeline />
  </Route>,
  <Route exact path="/borrowerPipelines/:token" key={0}>
    <BorrowerPipeline />
  </Route>,
  <Route exact path="/borrowerPipeline/overview/:loanNumber" key={0}>
    <BorrowerLoanOverview />
  </Route>,
  <Route exact path="/borrowerPipeline/application/:loanNumber" key={0}>
    <BorrowerLoanApplication />
  </Route>,
  <Route exact path="/borrowerPipeline/submit/:loanNumber" key={0}>
    <BorrowerLoanSubmission />
  </Route>,
  <Route exact path="/borrowerPipeline/budget/:loanNumber" key={0}>
    <BorrowerBudgetScopeOfWork />
  </Route>,
  <Route exact path="/borrowerPipeline/assetInformationVerification/:loanNumber" key={11}>
    <AssetInformationVerificationPage />
  </Route>,
  <Route exact path="/borrowerPipeline/amortizationSchedule/:loanNumber" key={11}>
    <BorrowerAmortizationSchedule />
  </Route>,
  <Route exact path="/borrowerPipeline/drawRequest/:loanNumber" key={11}>
    <BorrowerDrawRequest />
  </Route>,
  <Route exact path="/borrowerPipeline/constructionLedger/:loanNumber" key={11}>
    <BorrowerConstructionLedger />
  </Route>,
  <Route exact path="/borrowerPipeline/editProfile" key={11}>
    <BorrowerEditProfile />
  </Route>,

  <Route exact path="/customSignApplication/:token/:loanNumber" key={0}>
    <CustomSignApplication />
  </Route>,
  <Route exact path="/customSignDocumentGenie/:token/:loanNumber" key={1}>
    <CustomSignDocumentGenie />
  </Route>,
  <Route exact path="/brokerVerification/:urlSignature/:token" key={2}>
    <BrokerVerificationForUser />
  </Route>,
  <Route exact path="/customMemberSignApplication/:urlSignature/:token/:loanNumber" key={3}>
    <CustomMemberSignApplication />
  </Route>,
  <Route exact path="/orderValuationFeasibilitySign/:id/:token/:loanNumber" key={4}>
    <OrderValuationFeasibilitySign />
  </Route>,
  <Route exact path="/purchaseAdviceSellerSign/:id/:token/:loanNumber" key={5}>
    <PurchaseAdviceSellerSign />
  </Route>,
  <Route exact path="/budget/:urlSignature/:token/:loanNumber" key={6}>
    <BorrowerEditBudget />
  </Route>,
  <Route exact path="/trustLedgerAgreement/:urlSignature/:token/:orderID/:loanNumber" key={7}>
    <TrustLedgerAgreement />
  </Route>,
  <Route exact path="/loanServicingExtension/:id/:token/:loanNumber" key={8}>
    <LoanServicingExtensionSign />
  </Route>,
  <Route exact path="/contractorSignApplication/:urlSignature/:token/:no/:loanNumber" key={9}>
    <ContractorSignApplication />
  </Route>,
  <Route exact path="/makePayment/:token/:loanNumber" key={10}>
    <MakePaymentPage />
  </Route>,
  <Route exact path="/assetInformationVerification/:token/:loanNumber" key={11}>
    <AssetInformationVerificationPage />
  </Route>,
  <Route exact path="/survey/:token/:loanNumber" key={12}>
    <SurveyPage />
  </Route>,
  <Route exact path="/signLoanTermSheet/:token/:loanNumber" key={10}>
    <SignLoanTermSheet />
  </Route>,
  <Route exact path="/signClosingCalendar/:token/:loanNumber" key={10}>
    <SignClosingCalendar />
  </Route>,
  <Route exact path="/investorPipelines" key={0}>
    <InvestorPipeline />
  </Route>,
  <Route exact path="/investorPipelines/:token" key={0}>
    <InvestorPipeline />
  </Route>,
  <Route exact path="/investorEditProfile" key={11}>
    <InvestorEditProfile />
  </Route>,
  <Route exact path="/investorPipeline/overview/:loanNumber" key={0}>
    <InvestorLoanOverview />
  </Route>,
]
