import { InputType } from 'config'

export const LoanPaymentType = {
  payment: 'Payment',
  disbursement: 'Disbursement',
}
export const defaultInputs = (loanNumber: number): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      options: LoanPaymentType,
      title: 'Payment Type',
      value: 'payment',
      span: 2,
    },
    amountDue: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Amount Due',
      span: 1,
    },
    paymentDueDate: {
      inputType: 'text',
      type: 'date',
      title: 'Payment Due Date',
      span: 1,
    },
    custom2: {
      inputType: 'custom',
      title: '',
      span: 'full',
    },
    amountPaid: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Amount Paid',
      span: 1,
    },
    paymentReceivedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Payment Received',
      span: 1,
    },
    paymentDepositedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Payment Deposited',
      span: 1,
    },
    custom3: {
      inputType: 'custom',
      title: '',
      span: 'full',
    },
    principal: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Principal',
      span: 1,
    },
    interest: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Interest',
      span: 1,
    },
    mortgage: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Mortgage Insurance',
      span: 1,
    },
    escrows: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Escrows (Excl. MI)',
      span: 1,
    },
    escrowsTotal: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Escrows Total',
      span: 1,
    },
    buydown: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Buydown Funds',
      span: 1,
    },
    lateFee: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Late Fees',
      span: 1,
    },
    totalCredited: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Total Credited',
      disabled: true,
      span: 1,
    },
    custom4: {
      inputType: 'custom',
      title: '',
      span: 'full',
    },
    checkACH: {
      inputType: 'text',
      title: 'Check / ACH No',
      span: 1,
    },
    payor: {
      inputType: 'text',
      title: 'Payor / Payee',
      span: 1,
    },
    notes: {
      inputType: 'textarea',
      title: 'Notes',
      rows: 3,
      span: 'full',
    },
    files: {
      inputType: 'FileTable',
      title: 'Files',
      required: false,
      showCategory: false,
      filePath: `loan/${loanNumber}/loanPayment`,
      span: 'full',
      showStatus: false,
      multiple: true,
      additionalActions: [],
    },
  }
}
