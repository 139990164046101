import type { ICrossCollateralBlanket, ILoanDetail } from 'pages/LoanStructure/interfaces'
import { store } from 'reducers'
import { getPrice3decimal, InputValidate, isEmpty, removeComma } from 'utils'

import { additionalFieldsForAdmin, defaultInputs } from './constant'
import type { IAdditionalPropertyInformation } from './interface'

export const isAdditionalPropertyValidated = (item: IAdditionalPropertyInformation) => {
  if (item.overrideValidation) return true

  const inputs = defaultInputs()
  const hasErrorInputs = !!Object.keys(inputs)
    .filter((key) => !additionalFieldsForAdmin.includes(key))
    .filter((key) =>
      InputValidate({
        ...inputs[key],
        value: (item as any)[key],
      }),
    ).length

  return !hasErrorInputs
}

export const getFailedAdditionalPropertyInfo = (): IAdditionalPropertyInformation[] => {
  const {
    borrower: {
      borrower: { additionalPropertyInfo },
    },
    loanDetail,
  } = store.getState()

  const { crossCollateralBlanketLimit, allocatedLoanAmount, rateData } = loanDetail as ILoanDetail

  let failedAddresses = []

  if (!isEmpty(crossCollateralBlanketLimit) && !!allocatedLoanAmount.length)
    failedAddresses = additionalPropertyInfo.filter(
      (item: IAdditionalPropertyInformation, index: number) =>
        !isPassedCrossCollateralLimit(item, crossCollateralBlanketLimit, allocatedLoanAmount[index], rateData.type)
          .isPassed,
    )

  const incompleteAddresses = additionalPropertyInfo.filter(
    (item: IAdditionalPropertyInformation) => !isAdditionalPropertyValidated(item),
  )

  failedAddresses.forEach((item: IAdditionalPropertyInformation) => {
    if (!incompleteAddresses.includes(item) && !item.overrideValidation) incompleteAddresses.push(item)
  })

  return incompleteAddresses
}

export const additionalPropertyInfoValidate = () => {
  return getFailedAdditionalPropertyInfo().length == 0
}

export const isPassedCrossCollateralLimit = (
  data: IAdditionalPropertyInformation,
  crossCollateralBlanketLimit: ICrossCollateralBlanket,
  allocatedLoanAmount: number,
  productType: string,
) => {
  let errors: string[] = []

  const {
    propertyLevelAllocatedBalanceLimit,
    eligibleOccupancies,
    eligiblePropertyTypes,
    minAsIsValue,
    minPurchasePrice,
    minDscr,
  } = crossCollateralBlanketLimit
  const { occupancy, propertyType, asIsValue, purchasePrice } = data

  if (isEmpty(allocatedLoanAmount)) errors.push(`Calculated Allocated Loan Amount is N/A.`)

  if (
    allocatedLoanAmount < propertyLevelAllocatedBalanceLimit.min ||
    allocatedLoanAmount > propertyLevelAllocatedBalanceLimit.max
  )
    errors.push(
      `Calculated Allocated Loan Amount = ${getPrice3decimal(
        allocatedLoanAmount,
      )}. It should be in range of ${getPrice3decimal(propertyLevelAllocatedBalanceLimit.min)} to ${getPrice3decimal(
        propertyLevelAllocatedBalanceLimit.max,
      )}.`,
    )

  if (!eligibleOccupancies.includes(occupancy)) errors.push(`Occupancy is ineligible.`)

  if (!eligiblePropertyTypes.includes(propertyType)) errors.push(`Property Type is ineligible.`)

  if (removeComma(asIsValue) < minAsIsValue)
    errors.push(`As Is Value should be greater than ${getPrice3decimal(minAsIsValue)}.`)

  if (removeComma(purchasePrice) < minPurchasePrice)
    errors.push(`Purchase Price should be greater than ${getPrice3decimal(minPurchasePrice)}.`)

  const targetDscrLimit = minDscr.find((item) => item.propertyType === propertyType)
  if (targetDscrLimit) {
    const isAmortizing = productType.includes('ARM')
    const interestOnly = productType.includes('IO') || productType.toLowerCase().includes('I/O')

    let minDscrValue = 0
    if (isAmortizing) minDscrValue = targetDscrLimit.amortizingPaymentMinDscr
    if (interestOnly && targetDscrLimit.interestOnlyMinDscr > minDscrValue)
      minDscrValue = targetDscrLimit.interestOnlyMinDscr

    if (data.dscr < minDscrValue) errors.push(`DSCR should be greater than ${minDscrValue}.`)
  }

  return { isPassed: errors.length === 0, errors }
}
