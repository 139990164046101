import {
  ArrowUturnLeftIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import type { EmailValue } from 'components/EmailTo'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { Overview } from 'components/Overview'
import type { BaseFile } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { addNewNote, deleteNote, getNotes, openS3Document, updateNote } from 'services'
import { Button, ButtonGroup } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  confirm,
  convert2EmailMap,
  formatTime,
  renderHeader,
  stripHtmlTags,
  timeAgo,
  truncateWithEllipsis,
} from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddNote } from './AddNote'
import type { INote } from './types'

const tabs: Record<string, string> = {
  loan: 'Loan',
  email: 'Email',
}

export default function Notes({
  child = false,
  loanNumber,
  hasAction = true,
}: {
  child?: boolean
  loanNumber?: number
  hasAction?: boolean
}) {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<INote[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [editingData, setEditingData] = useState<INote | null>(null)
  const [replyData, setReplyData] = useState<INote | null>(null)
  const [filters, setFilters] = useState({
    orderBy: 'createdAt',
    orderDir: '-1',
    type: 'loan',
  })
  const [subject, setSubject] = useState('')
  const [collapseIndex, setCollapseIndex] = useState(-1)
  const [showMore, setShowMore] = useState(false)
  const [isEmail, setIsEmail] = useState(false)

  const isLoan = useMemo(() => {
    return filters.type === 'loan'
  }, [filters])

  const _loanNumber = loanNumber ? loanNumber : Number(setLoanNumber())

  useEffect(() => {
    setLoanNumber()
    refetch(filters)
  }, [])

  const refetch = (newFilters: Record<string, any> | null = null) => {
    setLoading(true)
    setData([])
    getNotes(_loanNumber, newFilters || filters)
      .then((data) => {
        setData(data)
        if (data.length > 0) setSubject(data[0].subject)
      })
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setEditingData(null)
    setReplyData(null)
    setIsEmail(!isLoan)
    setIsOpen(true)
    setSelectedIndex(-1)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setIsOpen(false)
    setEditingData(null)
    if (isNeedRefetch) refetch()
  }

  const onEdit = (id: number) => {
    const editData = data.find((item) => item.id === id)
    setEditingData(editData || null)
    setReplyData(null)
    setIsEmail(false)
    setIsOpen(true)
    setSelectedIndex(id)
  }

  const onSubmit = async (
    subject: string,
    content: string,
    files: BaseFile[],
    notifiers: EmailValue,
    isEmail: boolean,
    replyData: Record<string, any>,
  ) => {
    if (selectedIndex == -1) {
      await addNewNote(_loanNumber, subject, content, files, notifiers, isEmail, replyData)
    } else {
      await updateNote(selectedIndex, subject, content, files, notifiers)
    }
    setIsOpen(false)
    setSelectedIndex(-1)
    refetch()
  }

  const onDelete = async (id: number, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        No. {index + 1}
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    await deleteNote(id)
    refetch()
  }

  const subjects = useMemo(() => {
    return [...new Set(data.map((item) => item.subject))]
  }, [data])

  const onSort = (key: string, dir: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${dir}`
    setFilters(newFilters)
    refetch(newFilters)
  }

  const handleCollapse = (id: number) => {
    if (collapseIndex == id) setCollapseIndex(-1)
    else setCollapseIndex(id)
  }

  const onReply = (id: number) => {
    const replyData = data.find((item) => item.id === id)
    setEditingData(null)
    setReplyData(replyData || null)
    setIsEmail(true)
    setIsOpen(true)
    setSelectedIndex(-1)
  }

  const sortableHeaders = [
    { title: 'Note by', key: 'noteBy' },
    { title: 'Content', key: 'content' },
    { title: 'Created Date', key: 'createdAt' },
  ]

  return (
    <div className={`notes-container ${!child && 'px-2 py-6'}`}>
      {!child && <Overview title="Notes" />}
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white ${!child && 'shadow1 rounded mb-4 p-4'}`}>
          <LayoutLoading show={loading} />
          {!child && <div className="ml-2 text-[18px] font-variation-settings-600 mb-4">Notes</div>}
          <div className="flex justify-between items-center">
            <ButtonGroup
              title={tabs}
              value={filters.type}
              onChange={(v) => {
                setFilters({ ...filters, type: v })
                refetch({ ...filters, type: v })
              }}
            />
            <Button link onClick={onAdd} className="px-[36px]">
              {isLoan ? (
                '+ Add Note'
              ) : (
                <div className="flex items-center gap-2">
                  <EnvelopeIcon className="w-4 h-4" />
                  Send Email
                </div>
              )}
            </Button>
          </div>
          <div className="flex gap-4 mt-4">
            {filters.type === 'email' && (
              <div className="w-[300px] shadow1 p-2 rounded-md min-h-[600px] overflow-auto scroll-h">
                {subjects.map((v) => (
                  <p
                    key={v}
                    className={`p-2 text-sm cursor-pointer hover:bg-gray-200 rounded-md mb-2 ${
                      subject === v && 'pl-6 bg-gray-200'
                    }`}
                    onClick={() => setSubject(v)}
                  >
                    {v ? v : 'Primary'}
                  </p>
                ))}
              </div>
            )}
            <div className="parties-container flex-1 overflow-visible shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                {isLoan && (
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        No
                      </th>
                      {sortableHeaders.map(({ title, key }, index) =>
                        renderHeader({
                          title,
                          sortable: true,
                          key,
                          sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                          index,
                          onSort: (key: string, dir: number) => onSort(key, dir),
                        }),
                      )}
                      {hasAction && (
                        <th scope="col" className="px-6 py-3">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                )}
                <tbody className="text-[14.5px] text-gray-900">
                  {data
                    .filter((item) => isLoan || subject === item.subject)
                    .map((item, index) => {
                      let accountType = '',
                        email = ''
                      const isSelected = collapseIndex == item.id
                      try {
                        const splits = item.noteBy.split(' - ')
                        accountType = accountTypes[splits[0]] || splits[0]
                        email = splits[1]
                      } catch {}
                      return (
                        <tr key={index} className={`border-b ${index % 2 == 0 ? 'bg-gray-50' : ''}`}>
                          {isLoan && (
                            <>
                              <td className="px-6 py-3">{index + 1}</td>
                              <td className="px-2 py-3">
                                <span>
                                  <div className="border-b w-fit mb-1 border-gray-300">{accountType}</div>
                                  {email}
                                </span>
                              </td>
                            </>
                          )}
                          <td className={`px-${isLoan ? 2 : 6} py-3`}>
                            <div className="cursor-pointer" onClick={() => handleCollapse(item.id)}>
                              {!isLoan && (
                                <div className="flex justify-between items-start mb-2">
                                  <div>
                                    <p>
                                      <span className="font-bold">{accountType}</span>
                                      {isSelected && <span className="text-gray-500 text-sm">{` <${item.from}>`}</span>}
                                    </p>
                                    {isSelected && <p className="text-gray-500 text-sm">to {item.to.join(', ')}</p>}
                                  </div>
                                  <div className="flex gap-1 items-center">
                                    <span>
                                      {formatTime(item.createdAt)}
                                      {` (${timeAgo(item.createdAt)})`}
                                    </span>
                                    <div className="cursor-pointer" onClick={() => onReply(item.id)}>
                                      <div className="hover-shadow1 p-1 rounded">
                                        <Tooltip message="Reply">
                                          <ArrowUturnLeftIcon className="w-4 h-5" />
                                        </Tooltip>
                                      </div>
                                    </div>
                                    {isSelected && (
                                      <span
                                        className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                                        onClick={() => onDelete(item.id, index)}
                                      >
                                        <Tooltip message="Delete">
                                          <TrashIcon className="w-4 h-4"></TrashIcon>
                                        </Tooltip>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: isSelected
                                    ? item.content
                                    : truncateWithEllipsis(stripHtmlTags(item.content), 200),
                                }}
                              />
                            </div>
                            {!isLoan && isSelected && (
                              <>
                                {item.history && (
                                  <div
                                    className="flex text-gray-900 cursor-pointer mt-2"
                                    onClick={() => setShowMore(!showMore)}
                                  >
                                    <div className="rounded-lg bg-gray-200 hover:bg-gray-300">
                                      <EllipsisHorizontalIcon className="w-8 h-4" />
                                    </div>
                                  </div>
                                )}
                                {showMore && (
                                  <div
                                    className={`overflow-hidden cursor-pointer`}
                                    dangerouslySetInnerHTML={{ __html: item.history }}
                                  />
                                )}
                              </>
                            )}
                            {isLoan && item.notifiers && Object.keys(item.notifiers).length > 0 && (
                              <div className="text-gray-500">
                                {convert2EmailMap(JSON.stringify(item.notifiers)).text}
                              </div>
                            )}
                            {!!item.files.length && (
                              <div className="flex items-start gap-2 mt-2">
                                <span>Files:</span>
                                <div>
                                  {item.files.map((file) => (
                                    <p
                                      className="text-[12px] hover:underline text-shade-blue italic font-semibold cursor-pointer"
                                      onClick={() => openS3Document(file.fileKey)}
                                      key={`${file.fileKey}`}
                                    >
                                      - {file.name}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            )}
                          </td>
                          {isLoan && (
                            <>
                              <td className="px-2 py-3">{formatTime(item.createdAt)}</td>
                              {hasAction && (
                                <td className="px-6 py-3">
                                  <div className="flex gap-1">
                                    <span
                                      className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                                      onClick={() => onDelete(item.id, index)}
                                    >
                                      <TrashIcon className="w-4 h-4"></TrashIcon>
                                    </span>
                                    <span
                                      className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                                      onClick={() => onEdit(item.id)}
                                    >
                                      <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                                    </span>
                                  </div>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <AddNote
          onUpdate={onSubmit}
          onClose={onCloseModal}
          item={editingData}
          index={selectedIndex}
          isEmail={isEmail}
          replyData={replyData}
          loanNumber={loanNumber}
        />
      )}
    </div>
  )
}
