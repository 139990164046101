import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { Input2, Modal } from 'stories/components'

interface IUserSearchOption {
  title?: string
  inputTitle?: string
  value?: string
}

interface UserSearchProps extends ReactConfirmProps {
  options: IUserSearchOption
}

const UserSearchDialog = ({ show, proceed, options }: UserSearchProps) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [value, setValue] = useState(options.value)
  const [userInfo, setUserInfo] = useState()
  const { title, inputTitle } = options

  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <Modal
      button={<span></span>}
      title={title || 'User Search'}
      titleOkay="Okay"
      init={false}
      isOpen={show}
      disabled={!value}
      lastUpdatedAt={lastUpdatedAt}
      onOk={() => proceed(userInfo as any)}
      onClose={() => proceed(false as any)}
      childLevel={1}
    >
      <div className="w-120 text-sm">
        <Input2
          title={inputTitle || 'Email'}
          key={'userSearch'}
          type={'email'}
          value={value}
          onChange={(email, userInfo) => {
            setValue(email)
            setUserInfo(userInfo)
          }}
          userSearch={true}
        />
      </div>
    </Modal>
  )
}

const userSearchDlg = confirmable(UserSearchDialog)

const createSearchDlg = createConfirmation(userSearchDlg)

export const openUserSearchDlg = (options: IUserSearchOption = {}) => createSearchDlg({ options })
