import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonGroup } from 'stories/components'

import { Bubbles } from './Bubbles'
import { EmailSetup } from './EmailSetup'
import { ExceptionRequestConfig } from './ExceptionRequest'
import { Features } from './Features'
import { GlobalSettings } from './GlobalSettings'
import { Interface } from './Interface'
import { Links } from './Links'
import { LoanFieldsProperties } from './LoanFieldsProperties'
import { LoanStatusLabel } from './LoanStatusLabel'
import { LoanStatusRestrictions } from './LoanStatusRestrictions'
import { LoanSubmission } from './LoanSubmission'
import { PaymentTermsConditionsConfig } from './PaymentTermsConditionsConfig'
import { RestructureLoanRelease } from './RestructureLoanRelease'
import { RulesConfiguration } from './RulesConfiguration'
import { TextConfiguration } from './TextsConfiguration'
import { Workflows } from './Workflows/Workflows'

export const menus = {
  globalSettings: 'Global Settings',
  restructureLoanRelease: 'Restructure Loan Release',
  loanFieldsProperties: 'Loan Fields Properties',
  loanAction: 'Loan Actions',
  loanStatusRestrictions: 'Loan Status Restrictions',
  texts: 'Texts',
  emailSetup: 'Email Setup',
  rules: 'Rules',
  links: 'Links',
  features: 'Master',
  bubbles: 'Bubbles',
  exceptionRequests: 'Exceptions Requests',
  loanStatusLabel: 'Loan Status Label',
  interface: 'Interface',
  paymentTerms: 'Payment Terms and Conditions',
}

export const Configuration = () => {
  const { auth } = useSelector((state: any) => ({
    auth: state.auth,
  }))

  const [selectedMenu, setSelectedMenu] = useState(menus.globalSettings)

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.globalSettings:
        return <GlobalSettings />
      case menus.restructureLoanRelease:
        return <RestructureLoanRelease />
      case menus.loanFieldsProperties:
        return <LoanFieldsProperties />
      case menus.loanAction:
        return <LoanSubmission />
      case menus.loanStatusRestrictions:
        return (
          <>
            <LoanStatusRestrictions />
            <Workflows />
          </>
        )
      case menus.texts:
        return <TextConfiguration />
      case menus.emailSetup:
        return <EmailSetup />
      case menus.rules:
        return <RulesConfiguration />
      case menus.links:
        return <Links />
      case menus.features:
        return <Features />
      case menus.bubbles:
        return <Bubbles />
      case menus.exceptionRequests:
        return <ExceptionRequestConfig />
      case menus.loanStatusLabel:
        return <LoanStatusLabel />
      case menus.interface:
        return <Interface />
      case menus.paymentTerms:
        return <PaymentTermsConditionsConfig />
      default:
        return <></>
    }
  }, [selectedMenu])

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">Configuration</h1>

      <ButtonGroup
        title={Object.values(menus).filter((v) => v != 'Master' || auth.profile.master)}
        value={selectedMenu}
        onChange={(value) => setSelectedMenu(value)}
      />

      <div className="p-2">{renderContent}</div>
    </div>
  )
}
