import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { replaceConditionContent } from 'services'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

const defaultInputs: Record<string, InputType> = {
  companyName: {
    inputType: 'text',
    title: 'Company Name',
  },
  companyType: {
    inputType: 'select',
    title: 'Company Type',
    options: ['LLC', 'LLP', 'Corp', 'Partnership', 'Revocable Trust', 'Individual'],
    value: 'LLC',
  },
  companyAddress: {
    inputType: 'map',
    title: 'Company Address',
  },
}
export const ConditionReplace = () => {
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs)

  const onChange = (key: string, value: any) => {
    const temp = cloneDeep(inputs)
    temp[key].value = value
    setInputs(temp)
  }

  const replaceContent = async () => {
    // Replace content logic goes here
    const json = {
      companyName: inputs.companyName.value,
      companyType: inputs.companyType.value,
      companyAddress: inputs.companyAddress.value,
    }
    if (!json.companyName || !json.companyAddress) {
      toast('Please enter your company name and address', { type: 'error' })
      return
    }
    const res = await replaceConditionContent(json)
    if (res.success) toast('Successfully replaced company information', { type: 'success' })
  }
  return (
    <div className="grid grid-cols-4 gap-2 items-center">
      {Object.keys(inputs).map((key) => {
        const input = inputs[key]
        return (
          <div key={key} className="col-span-1">
            <RenderInput input={input} onChange={onChange} Key={key} key={key} />
          </div>
        )
      })}
      <div>
        <Button onClick={replaceContent}>Replace</Button>
      </div>
    </div>
  )
}
