import {
  AccountType,
  BrokerAccountTypes,
  COMPANY_ADDRESS,
  COMPANY_NMLS,
  COMPANY_TITLE,
  InputSelect,
  isNeedAccountTypeValidate,
  UnderwriterAEAccountTypes,
} from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getChildUsers, requestUserProps, requestUserRoles, submitUser, updateChildUserTypes } from 'services'
import { Button, Modal } from 'stories/components'
import { openAuditLog } from 'utils'
import { InputConvert } from 'utils/convertor'
import { RenderInput } from 'utils/RenderInput'
import { InputValidate } from 'utils/validator'

import {
  accountTypeOptions,
  assignedToAccountTypes,
  defaultInputs,
  profileCompanyInfoKeys,
  profileCompanyInfoVisibleKeys,
  userMarginInputs,
  userParentMarginInputs,
} from './config'
import { confirmChildUsers } from './ConfirmUser'
const cloneDeep = require('clone-deep')

export const CreateUser = ({
  defaultData = null,
  executives = {},
  onClose = () => {},
  onAfterSubmit = () => {},
  isModal = true,
  ...props
}: any) => {
  const profile = useSelector((state: any) => state.auth.profile)
  const [inputStates, setInputStates] = useState(defaultInputs())
  const [accountType, setAccountType] = useState<AccountType>(AccountType.BROKER)
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const [defaultValueStep, setDefaultValueStep] = useState<string | null>(null)
  const [initAccountTypeChange, setInitAccountTypeChange] = useState(false)

  const { hasPermission } = usePermissions()

  const canUpdateCompanyInfo = hasPermission('UPDATE_PROFILE_COMPANY_INFO')

  const executiveOptionFeed = () => {
    if (isNeedAccountTypeValidate(profile.accountType)) return
    const newState = cloneDeep(inputStates)
    const accountExecutive = newState.accountExecutive as InputSelect
    accountExecutive.options = executives
    newState.accountExecutive = accountExecutive
    newState.branchAccountExecutive.options = executives
    setInputStates(newState)
  }

  useEffect(() => {
    executiveOptionFeed()
  }, [executives])

  useEffect(() => {
    setIsOpen(props.isOpen)
    setLastUpdatedAt(props.lastUpdatedAt)
    setDefaultValueStep(null)
    if (!defaultData || !props.isOpen) return

    setAccountType(defaultData.accountType)
  }, [props.lastUpdatedAt, props.isOpen])

  useEffect(() => {
    if (!isOpen) return
    onUpdateAccountType()
  }, [accountType, isOpen])

  useEffect(() => {
    if (defaultData && defaultValueStep == null) {
      setDefaultValueStep('accountExecutive')
    }
  }, [inputStates])

  useEffect(() => {
    if (!defaultData || !defaultValueStep || defaultValueStep == 'DONE') return
    if ([AccountType.BRANCH, AccountType.BROKER].includes(profile.accountType)) return
    onChange(defaultValueStep as string, inputStates[defaultValueStep].value as string, false).then(() => {
      const nextSteps: Record<string, string> = {
        accountExecutive: AccountType.BROKER,
        [AccountType.BROKER]: AccountType.BRANCH,
        [AccountType.BRANCH]: 'DONE',
      }
      const nextStep = nextSteps[defaultValueStep]
      setDefaultValueStep(nextStep)
    })
  }, [defaultValueStep])

  const isAdmin = useMemo(
    () => [AccountType.ADMIN, AccountType.VENDOR_APPROVAL].includes(profile.accountType),
    [profile.accountType],
  )

  const isCreate = useMemo(() => !defaultData || !defaultData.id, [defaultData])

  const isSaveDisabled = useMemo(
    () => defaultData && !!defaultData.additionalExecutive && AccountType.ACCOUNT_EXECUTIVE == profile.accountType,
    [defaultData, profile],
  )

  const clearErrors = () => {
    const newStates = cloneDeep(inputStates)
    for (const key in newStates) newStates[key].error = ''
    setInputStates(newStates)
  }

  const onCreateUser = () => {
    setIsOpen(true)
    executiveOptionFeed()
    if (!defaultData) {
      const defaultAccountTypes: Record<string, AccountType> = {
        [AccountType.BROKER]: AccountType.BRANCH,
        [AccountType.RETAIL]: AccountType.BRANCH,
        [AccountType.CORRESPONDENT]: AccountType.BRANCH,
        [AccountType.TABLEFUNDER]: AccountType.BRANCH,
        [AccountType.BRANCH]: AccountType.LOAN_OFFICER,
      }
      const defaultAccountType = defaultAccountTypes[profile.accountType] ?? AccountType.BROKER
      setAccountType(defaultAccountType)
    }
  }

  const onCloseUserModal = () => {
    let newState = defaultInputs()
    const accountExecutive = newState.accountExecutive as InputSelect
    accountExecutive.options = executives
    newState.accountExecutive = accountExecutive
    ;(newState.branchAccountExecutive as InputSelect).options = executives
    setInputStates(newState)
    onClose()
  }

  const onChange = async (key: string, value: string | boolean, fromModalChange: boolean = true) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
    switch (key) {
      case 'accountType':
        setAccountType(value as AccountType)
        clearErrors()
        break
      case 'accountExecutive':
        if (!value || value == '0') break
        if (fromModalChange) {
          newState.broker.value = '0'
          newState.branch.value = '0'
        }
        await updateUserRoles(newState, AccountType.BROKER, parseInt(value as string), 'broker')
        break
      case 'broker':
        if (!value || value == '0') break
        if (fromModalChange) {
          newState.branch.value = '0'
        }
        newState = await updateUserRoles(newState, AccountType.BRANCH, parseInt(value as string), 'branch')
        if (!defaultValueStep || defaultValueStep == 'DONE')
          await updateDefaultProps(newState, parseInt(value as string))
        break
      case 'branch':
        if (!value || value == '0') {
          if (accountType == AccountType.LOAN_OFFICER || accountType == AccountType.LOAN_PROCESSOR)
            await updateDefaultProps(newState, parseInt(inputStates.broker.value as string))
          else break
        } else await updateDefaultProps(newState, parseInt(value as string))
        break
    }
  }

  const updateUserRoles = (State: any, accountType: AccountType, userId: number, key: string, isSetState = true) => {
    return new Promise((resolve) => {
      if (isSetState) setInitAccountTypeChange(true)
      requestUserRoles(accountType, userId)
        .then(async (roles) => {
          if (defaultData && defaultData.id) delete roles[defaultData.id]
          let newState = cloneDeep(State)
          newState[key].options = roles

          isSetState && setInputStates(newState)
          resolve(newState)
        })
        .finally(() => {
          if (isSetState) setInitAccountTypeChange(false)
        })
    })
  }

  const updateDefaultProps = (State: any, userId: number, isSetState = true) => {
    return new Promise((resolve) => {
      let newState = cloneDeep(State)
      requestUserProps(userId).then((props) => {
        userMarginInputs.forEach((key, index) => {
          if (props[key] === undefined) return
          props[userParentMarginInputs[index]] = props[key]
          delete props[key]
        })
        Object.keys(props).forEach((key) => {
          if (!newState[key]) return
          if (!isCreate && newState[key].value && !newState[key].disabled) return
          newState[key].value = props[key]
          newState[key].error = ''
        })
        isSetState && setInputStates(newState)
        resolve(newState)
      })
    })
  }

  useEffect(() => {
    let newState = cloneDeep(inputStates)
    const companyType = inputStates.companyType.value
    profileCompanyInfoVisibleKeys.map((key) => {
      newState[key].visible = companyType !== 'Individual'
    })
    newState.personalSeperator1.visible = companyType === 'Individual'
    newState.personalSeperator2.visible = companyType !== 'Individual'
    newState.brokerNmls.required = companyType === 'Individual'
    setInputStates(newState)
  }, [inputStates.companyType.value])

  const onUpdateAccountType = async () => {
    if (!isOpen) return
    let newState = cloneDeep(inputStates)
    const primaryInputs = defaultInputs()
    Object.keys(newState).forEach((key) => {
      newState[key].visible = false
      newState[key].disabled = false
      if (key == 'accountType') return

      if (defaultData && defaultData[key] !== undefined) {
        if (key == 'pullCreditScoreLevel') newState[key].value = ` ${defaultData[key]}`
        else newState[key].value = defaultData[key]
      } else newState[key].value = primaryInputs[key].value
    })

    newState.accountType.visible = true

    const visibleInputs = [
      'name',
      'email',
      'phone',
      'phoneExt',
      'title',
      'companyType',
      'companySeperator',
      'companyAddress',
    ]

    if (newState.companyType.value !== 'Individual') {
      visibleInputs.push(...profileCompanyInfoVisibleKeys)
      visibleInputs.push('personalSeperator2')
    } else {
      visibleInputs.push('personalSeperator1')
    }

    const disabledInputs = []

    switch (accountType) {
      case AccountType.ADMIN:
        break
      case AccountType.UW_MANAGER:
      case AccountType.UNDERWRITER:
        break
      case AccountType.BROKER:
        visibleInputs.push(...['accountExecutive', 'brokerSSN', 'brokerNmls', 'companyStateOfFormation'])
        break
      case AccountType.RETAIL:
      case AccountType.CORRESPONDENT:
      case AccountType.TABLEFUNDER:
        visibleInputs.push(...['accountExecutive', 'companyStateOfFormation'])
        break
      case AccountType.BRANCH:
        visibleInputs.push(...['accountExecutive', 'broker', 'branchNmls', 'companyStateOfFormation'])
        break
      case AccountType.LOAN_OFFICER:
        visibleInputs.push(
          ...['accountExecutive', 'broker', 'branch', 'companyLicense', 'loanOfficer', 'loanOfficerStateLicense'],
        )
        disabledInputs.push(...['companyAddress', 'companyName', 'companyNmls', 'companyEIN', 'companyType'])
        break
      case AccountType.LOAN_PROCESSOR:
        visibleInputs.push(...['accountExecutive', 'broker', 'branch'])
        disabledInputs.push(...['companyAddress', 'companyName', 'companyNmls', 'companyEIN', 'companyType'])
        break
    }

    if ([AccountType.ADMIN, AccountType.VENDOR_APPROVAL].includes(profile.accountType)) {
      if (assignedToAccountTypes.includes(accountType)) {
        visibleInputs.push('taskSeperator')
        visibleInputs.push('taskAssignViewTypes')
        newState['taskAssignViewTypes'].options = props.assignedToList
      }
      visibleInputs.push(...['otherSeperator', 'pullCreditScoreLevel'])
    }

    if (hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) {
      visibleInputs.push(...['otherSeperator', 'borrowerAutoNotify', 'loanSharing'])
    }

    if ((isAdmin || profile.accountType == AccountType.NATIONAL_SALE) && accountType == AccountType.BRANCH)
      visibleInputs.push('branchAccountExecutive')

    visibleInputs.forEach((key) => (newState[key].visible = true))
    disabledInputs.forEach((key) => (newState[key].disabled = true))
    setInitAccountTypeChange(true)
    if (isNeedAccountTypeValidate(profile.accountType)) {
      const invisibleInputs = []

      newState.accountType.options = accountTypeOptions(profile.accountType)
      switch (profile.accountType) {
        case AccountType.ACCOUNT_EXECUTIVE:
        case AccountType.NATIONAL_SALE:
          newState.accountExecutive.disabled = true
          invisibleInputs.push('accountExecutive')
          break
        case AccountType.BROKER:
        case AccountType.RETAIL:
        case AccountType.CORRESPONDENT:
        case AccountType.TABLEFUNDER:
          newState.accountExecutive.disabled = true
          newState.broker.disabled = true
          invisibleInputs.push(...['accountExecutive', 'broker'])
          break
        case AccountType.BRANCH:
          invisibleInputs.push(...['accountExecutive', 'broker', 'branch'])
          break
      }

      invisibleInputs.forEach((key) => (newState[key].visible = false))

      const executiveValue = profile.accountExecutive || profile.id
      newState = await updateUserRoles(
        newState,
        AccountType.BROKER,
        parseInt(executiveValue as string),
        'broker',
        false,
      )
      newState.accountExecutive.value = executiveValue

      if (![AccountType.ACCOUNT_EXECUTIVE, AccountType.NATIONAL_SALE].includes(profile.accountType)) {
        newState.broker.value = profile.broker || profile.id
        newState = await updateUserRoles(
          newState,
          AccountType.BRANCH,
          parseInt((profile.broker || profile.id) as string),
          'branch',
          false,
        )
      }
      if (profile.accountType == AccountType.BRANCH) {
        newState.branch.value = profile.branch || profile.id
        newState = await updateDefaultProps(newState, parseInt(profile.id as string), false)
      }
    }

    const isUnderwriterUser = !!accountType && UnderwriterAEAccountTypes.includes(accountType)
    if (!isUnderwriterUser && isCreate) {
      const underwriterDefaults: any = {
        companyName: COMPANY_TITLE,
        companyNmls: COMPANY_NMLS,
        companyAddress: COMPANY_ADDRESS ?? '',
      }

      Object.keys(underwriterDefaults).forEach((key) => {
        if (newState[key]) {
          newState[key].value = underwriterDefaults[key]
          newState[key].visible = true
        }
      })
    }

    userMarginInputs.forEach((key) => {
      newState[key].visible = isUnderwriterUser
      newState[key].disabled = !isAdmin && !BrokerAccountTypes.includes(profile.accountType)
    })
    userParentMarginInputs.forEach((key) => {
      newState[key].visible =
        !!accountType &&
        [AccountType.LOAN_OFFICER, AccountType.LOAN_PROCESSOR].includes(accountType) &&
        !BrokerAccountTypes.includes(profile.accountType)
      newState[key].disabled = true
    })
    ;['companyType', 'companyName', 'companyAddress'].forEach((key) => {
      newState[key].required = isUnderwriterUser
    })

    setInitAccountTypeChange(false)
    newState.accountType.value = accountType
    setInputStates(newState)
    return newState
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputStates)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, visible, disabled = false, inputType } = newStats[key]
      if (inputType === 'section') continue
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (visible && !disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputStates(newStats)
      return
    }

    setLoading(true)

    if (!isCreate) {
      const askingTypes1 = [
        AccountType.BROKER,
        AccountType.RETAIL,
        AccountType.CORRESPONDENT,
        AccountType.TABLEFUNDER,
        AccountType.BRANCH,
      ]
      const askingTypes2 = [
        AccountType.NATIONAL_SALE,
        AccountType.ACCOUNT_EXECUTIVE,
        AccountType.BROKER,
        AccountType.RETAIL,
        AccountType.CORRESPONDENT,
        AccountType.TABLEFUNDER,
        AccountType.BRANCH,
      ]
      const sameLevelTypes = [
        AccountType.BROKER,
        AccountType.RETAIL,
        AccountType.CORRESPONDENT,
        AccountType.TABLEFUNDER,
      ]
      const accountExecutives = [AccountType.ACCOUNT_EXECUTIVE, AccountType.NATIONAL_SALE]
      if (
        (defaultData.accountType == inputStates.accountType.value &&
          askingTypes1.indexOf(defaultData.accountType) != -1 &&
          (defaultData.accountExecutive != inputStates.accountExecutive.value ||
            defaultData.broker != inputStates.broker.value)) ||
        (defaultData.accountType != inputStates.accountType.value &&
          askingTypes2.indexOf(defaultData.accountType) != -1 &&
          !(
            accountExecutives.includes(defaultData.accountType) &&
            accountExecutives.includes(inputStates.accountType.value)
          ))
      ) {
        if (
          sameLevelTypes.includes(defaultData.accountType) &&
          sameLevelTypes.includes(inputStates.accountType.value) &&
          defaultData.accountType != inputStates.accountType.value &&
          defaultData.accountExecutive == inputStates.accountExecutive.value
        ) {
        } else {
          const isChangedAccountType = defaultData.accountType != inputStates.accountType.value
          const childUsers = await getChildUsers(defaultData.id)
          if (childUsers.length) {
            const confirmResult = (await confirmChildUsers({
              isChangedAccountType,
              defaultData,
              users: childUsers,
            })) as any
            if (confirmResult === false) {
              setLoading(false)
              return
            }
            if (confirmResult != true) {
              await updateChildUserTypes(defaultData.id, confirmResult)
            }
          }
        }
      }
    }

    if (isNeedAccountTypeValidate(profile.accountType)) {
      data.accountExecutive = data.accountExecutive || profile.accountExecutive
      data.broker = data.broker || profile.broker
      data.branch = data.branch || profile.branch
      switch (profile.accountType) {
        case AccountType.ACCOUNT_EXECUTIVE:
        case AccountType.NATIONAL_SALE:
          data.accountExecutive = profile.id
          break
        case AccountType.BROKER:
        case AccountType.RETAIL:
        case AccountType.CORRESPONDENT:
        case AccountType.TABLEFUNDER:
          data.broker = profile.id
          break
        case AccountType.BRANCH:
          data.branch = profile.id
          break
      }
    }

    const userId = isCreate ? 0 : defaultData.id
    data.email = data.email.toLowerCase()

    if (data.branch === '') data.branch = 0
    if (data.accountExecutive && data.branchAccountExecutive == data.accountExecutive) data.branchAccountExecutive = 0

    submitUser(userId, data)
      .then(() => {
        toast('User data is submitted', { type: 'success' })
        setIsOpen(false)
        setLastUpdatedAt(Date.now())
        onAfterSubmit()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'User',
      field: inputStates[key].title,
      keys: {
        userId: defaultData.id,
        field: key,
      },
    }
    openAuditLog(options)
  }

  if (!isModal) {
    return (
      <div className="grid gap-4 md:grid-cols-2">
        {Object.keys(inputStates).map((key, index) => {
          const input = inputStates[key]
          if (!input.visible) return null
          const cn = input.span === 2 ? 'input md:col-span-2 text-[14px]' : 'input'
          return (
            <div className={cn} key={index}>
              <RenderInput
                input={{
                  ...input,
                  disabled: false,
                  history: true,
                }}
                Key={key}
                onChange={() => {}}
                showHistory={showHistory}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <Modal
      button={<Button>Create User</Button>}
      title={isCreate ? 'Create New User' : 'Update User'}
      titleOkay={isSaveDisabled ? '' : 'Save'}
      loading={loading}
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onOpen={onCreateUser}
      onClose={onCloseUserModal}
      onOk={onSubmit}
      init={initAccountTypeChange}
    >
      <div className="max-w-screen-sm grid gap-4 md:grid-cols-2">
        {Object.keys(inputStates).map((key, index) => {
          const input = inputStates[key]
          if (!input.visible) return null
          const cn = input.span === 2 ? 'input md:col-span-2 text-[14px]' : 'input md:w-80'
          let disabled = initAccountTypeChange ? true : input.disabled
          if (!isCreate && !canUpdateCompanyInfo) {
            if (profileCompanyInfoKeys.includes(key)) disabled = true
          }
          return (
            <div className={cn} key={index}>
              <RenderInput
                input={{
                  ...input,
                  disabled,
                  history: true,
                }}
                Key={key}
                onChange={onChange}
                showHistory={showHistory}
              />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
