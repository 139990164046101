import cloneDeep from 'clone-deep'
import { PlainInput2 } from 'components/PlainInput/PlainInput2'
import { productTypeOptions, transactionTypeOptions } from 'config'
import { convertNullToBlank, convertNullToBlankValueToDecimal } from 'pages/Resources/GuidelineOverlay/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Checkbox, Modal } from 'stories/components'

import { IMinMaxLimitPerTypes } from '../constants'

interface IProps {
  data: IMinMaxLimitPerTypes[]
  isOpen: boolean
  loading: string
  selectedKey: string
  onClose: () => void
  onSubmit: (data: any[]) => void
}

export const MinMaxLimitPerTypes = (props: IProps) => {
  const { data, selectedKey, loading, isOpen, onClose, onSubmit } = props

  const [isAll, setIsAll] = useState(false)
  const [selected, setSelected] = useState<IMinMaxLimitPerTypes[]>([])

  let no = 0

  const fieldName = selectedKey.includes('commitment') ? 'Commitment Fee' : 'Due Dilligence'

  useEffect(() => {
    setSelected(data)
  }, [data])

  useEffect(() => {
    if (selected.length === 8) setIsAll(true)
    else setIsAll(false)
  }, [selected])

  const onSelectAll = () => {
    const newSelected: IMinMaxLimitPerTypes[] = []

    if (!isAll)
      productTypeOptions.forEach((prod) => {
        transactionTypeOptions.forEach((trans) => {
          const target = selected.find((item) => item.productType === prod && item.transactionType === trans)
          newSelected.push({
            productType: prod,
            transactionType: trans,
            minAmount: target?.minAmount || NaN,
            maxAmount: target?.maxAmount || NaN,
            minPercent: target?.minPercent || NaN,
            maxPercent: target?.maxPercent || NaN,
          })
        })
      })

    setSelected(newSelected)
  }

  const onSelect = (productType: string, transactionType: string) => {
    const newSelected = cloneDeep(selected)
    const index = selected.findIndex(
      (item) => item.productType === productType && item.transactionType === transactionType,
    )

    if (index !== -1) newSelected.splice(index, 1)
    else {
      const target = selected.find(
        (item) => item.productType === productType && item.transactionType === transactionType,
      )
      newSelected.push({
        productType,
        transactionType,
        minAmount: target?.minAmount || NaN,
        maxAmount: target?.maxAmount || NaN,
        minPercent: target?.minPercent || NaN,
        maxPercent: target?.maxPercent || NaN,
      })
    }

    setSelected(newSelected)
  }

  const onChange = (
    productType: string,
    transactionType: string,
    key: 'minAmount' | 'maxAmount' | 'minPercent' | 'maxPercent',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newSelected = cloneDeep(selected)
    newSelected.forEach((item) => {
      if (item.productType === productType && item.transactionType === transactionType) item[key] = value
    })

    setSelected(newSelected)
  }

  const rederTable = useMemo(() => {
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-2 border">No</th>
            <th className="p-2 border">Product Type</th>
            <th className="p-2 border">Transaction Type</th>
            <th className="p-2 border w-28">Min $</th>
            <th className="p-2 border w-28">Max $</th>
            <th className="p-2 border w-28">Min %</th>
            <th className="p-2 border w-28">Max %</th>
            <th className="p-2 border">
              <div className="flex items-center justify-center gap-2">
                <span>Required</span>
                <Checkbox title="All" id="all" checked={isAll} onChange={onSelectAll} className="mb-[-6px]" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {productTypeOptions.map((prod, index) => {
            return (
              <Fragment key={`${prod}-${index}`}>
                {transactionTypeOptions.map((trans, idx) => {
                  no++
                  const checked = selected.find((item) => item.productType === prod && item.transactionType === trans)

                  return (
                    <tr key={`${prod}-${index}-${idx}`}>
                      <td className="p-2 border text-center">{no}</td>
                      <td className="p-2 border">{prod}</td>
                      <td className="p-2 border">{trans}</td>
                      <td className="p-1 border">
                        <div className="flex justify-center text-center">
                          <PlainInput2
                            value={convertNullToBlank(checked?.minAmount)}
                            content={convertNullToBlankValueToDecimal(checked?.minAmount)}
                            className="w-full"
                            disabled={!checked}
                            onChange={(value: string) => onChange(prod, trans, 'minAmount', value)}
                          />
                        </div>
                      </td>
                      <td className="p-1 border">
                        <div className="flex justify-center text-center">
                          <PlainInput2
                            value={convertNullToBlank(checked?.maxAmount)}
                            content={convertNullToBlankValueToDecimal(checked?.maxAmount)}
                            className="w-full"
                            disabled={!checked}
                            onChange={(value: string) => onChange(prod, trans, 'maxAmount', value)}
                          />
                        </div>
                      </td>
                      <td className="p-1 border">
                        <div className="flex justify-center text-center">
                          <PlainInput2
                            value={convertNullToBlank(checked?.minPercent)}
                            content={convertNullToBlankValueToDecimal(checked?.minPercent)}
                            className="w-full"
                            disabled={!checked}
                            onChange={(value: string) => onChange(prod, trans, 'minPercent', value)}
                          />
                        </div>
                      </td>
                      <td className="p-1 border">
                        <div className="flex justify-center text-center">
                          <PlainInput2
                            value={convertNullToBlank(checked?.maxPercent)}
                            content={convertNullToBlankValueToDecimal(checked?.maxPercent)}
                            className="w-full"
                            disabled={!checked}
                            onChange={(value: string) => onChange(prod, trans, 'maxPercent', value)}
                          />
                        </div>
                      </td>
                      <td className="p-1 border">
                        <div className="flex justify-center">
                          <Checkbox
                            title=""
                            id={`${index}-${idx}`}
                            checked={!!checked}
                            onChange={() => onSelect(prod, trans)}
                            className="w-5 mb-[-6px]"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [selected, isAll])

  return (
    <Modal
      title={`Edit ${fieldName}`}
      titleOkay="Confirm"
      loading={loading === 'setting'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={() => onSubmit(selected)}
    >
      <div>{rederTable}</div>
    </Modal>
  )
}
