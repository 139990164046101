import {
  ArrowLeftIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { createEmailDnsConfig, getEmailDnsConfig, verifyEmailDnsConfig } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, PlainTable } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { copyClipboard } from 'utils/copyClipboard'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, getProviderInputs } from './constants'
import { EmailDNSConfig, IDomainData } from './type'

interface EmailDNSSetupDetailsProps {
  defaults: EmailDNSConfig | null
  onBack: Function
  // onComplete: Function
}

export function EmailDNSSetupDetails(props: EmailDNSSetupDetailsProps) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [defaults, setDefaults] = useState(props.defaults)
  const [domainData, setDomainData] = useState<IDomainData>()

  useEffect(() => {
    onUpdateDefaults()
  }, [])

  const onUpdateDefaults = async () => {
    if (!defaults) {
      setInputs(defaultInputs())
      return
    }
    const newStats = cloneDeep(defaultInputs(defaults.provider))
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    setInputs(newStats)
    loadDomainData()
  }

  const loadDomainData = () => {
    if (!defaults) return

    setLoading(true)
    getEmailDnsConfig(defaults.dns)
      .then((data) => setDomainData(data))
      .finally(() => setLoading(false))
  }

  const onBack = () => {
    props.onBack(!!defaults)
  }

  const onChange = (key: string, value: number) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate(newInputs[key])

    if (key == 'provider') {
      newInputs = {
        provider: newInputs.provider,
        dns: newInputs.dns,
        ...getProviderInputs(value),
      }
    }
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      data[key] = value
    }

    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    createEmailDnsConfig(data)
      .then(({ success, config, data }) => {
        if (!success) return
        if (success && config.verified) {
          props.onBack(true)
          toast('Email Domain configuration has been submitted.', { type: 'success' })
          return
        }
        setDefaults(config)
        setDomainData(data)
      })
      .finally(() => setLoading(false))
  }

  const onVerify = () => {
    if (!defaults) return
    setLoading(true)
    verifyEmailDnsConfig(defaults.dns)
      .then(() => loadDomainData())
      .catch(() => setLoading(false))
  }

  const renderData = () => {
    if (!domainData) return null

    const tableData: any[] = Object.keys(domainData.dns).map((key: any, index) => {
      const item = domainData.dns[key as 'mail_cname' | 'dkim1' | 'dkim2']
      return [
        `${index + 1}`,
        item.valid ? (
          <p className="text-green-500 flex items-center gap-1">
            <CheckCircleIcon className="w-4 h-4" /> Verified
          </p>
        ) : domainData.last_validation_attempt_at ? (
          <p className="text-red-500 flex items-center gap-1">
            <XCircleIcon className="w-4 h-4" /> Failed
          </p>
        ) : (
          <p className="text-gray-500 flex items-center gap-1">
            <MinusCircleIcon className="w-4 h-4" /> Pending
          </p>
        ),
        <span className="uppercase">{item.type}</span>,
        <p className="flex items-center gap-1">
          {item.host}
          <span className="btn-icon" onClick={() => copyClipboard(item.host)}>
            <DocumentDuplicateIcon className="w-4 h-4" />
          </span>
        </p>,
        <p className="flex items-center gap-1">
          {item.data || item.data_with_valimail}
          <span className="btn-icon" onClick={() => copyClipboard(item.data || item.data_with_valimail || '')}>
            <DocumentDuplicateIcon className="w-4 h-4" />
          </span>
        </p>,
      ]
    })
    return (
      <>
        <PlainTable header={['No', 'Status', 'Type', 'Host', 'Value']} data={tableData} />
        {!defaults?.verified && (
          <div className="block text-center">
            <Button onClick={onVerify} className="px-10" loading={isLoading}>
              Verify
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="email-template-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Update Email DNS Configuration
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={() => onBack()}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Email DNS Setup</p>
        </div>
      </Button>

      <div className="mb-3 max-w-2xl">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          input.disabled = !!defaults

          return (
            <div
              className={`mb-4 input ${input.span ? 'col-span-1 lg:col-span-4 md:col-span-3 sm:col-span-2' : ''}`}
              key={index}
            >
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      {!defaults && (
        <div className="block text-center">
          <Button onClick={onSubmit} className="px-10" loading={isLoading}>
            Submit
          </Button>
        </div>
      )}
      {defaults && renderData()}
    </div>
  )
}
