export interface ILoanConditionVisibilityOption {
  fieldOrigin: ILoanStructureConditionFieldOrigin
  fieldKey: string
  value: string | number | boolean
}

export interface ILoanStructureCondition {
  visibleLogic: ILoanConditionVisibilityOption[][]
  value: Array<number>
  detectChange: boolean
}

export enum ILoanStructureConditionFieldOrigin {
  LoanStructure = 'LoanStructure',
  BorrowerInformation = 'BorrowerInformation',
  PropertyInformation = 'PropertyInformation',
  AssetLiability = 'AssetLiability',
  TrackRecord = 'TrackRecord',
  DeclarationsHMDA = 'DeclarationsHMDA',
}

export const ILoanStructureConditionFieldOriginOptions: Record<ILoanStructureConditionFieldOrigin, string> = {
  [ILoanStructureConditionFieldOrigin.LoanStructure]: 'Loan Structure',
  [ILoanStructureConditionFieldOrigin.BorrowerInformation]: 'Borrower Information',
  [ILoanStructureConditionFieldOrigin.PropertyInformation]: 'Property Information',
  [ILoanStructureConditionFieldOrigin.AssetLiability]: 'Asset & Liability',
  [ILoanStructureConditionFieldOrigin.TrackRecord]: 'Track Record',
  [ILoanStructureConditionFieldOrigin.DeclarationsHMDA]: 'Declarations & HMDA',
}
