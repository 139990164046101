import {
  ArrowDownIcon,
  ArrowUpIcon,
  BarsArrowDownIcon,
  EyeIcon,
  EyeSlashIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { FieldOrigin, fieldOriginOptions, InputSelect, InputType, IVisibleLogic } from 'config'
import {
  CustomFieldType,
  customFieldType2Input,
  CustomFieldTypeOptions,
  getLoanInputFieldOptions,
  getLoanInputFields,
} from 'config/loan.input.fields.constants'
import React, { useEffect, useMemo, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button, Input2 } from 'stories/components'
import { capitalizeFirstLetter, confirm, getNewSelectionOptions, openUserSearchDlg, sortByKey } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { requiredFields } from './config'
import { PageOrderModal } from './PageOrderModal'
import {
  fieldOriginOptionsExceptCustom,
  IBorrowerLoanWorkflowControl,
  IBorrowerLoanWorkflowCustomInput,
  IBorrowerLoanWorkflowCustomInputSelect,
  IBorrowerLoanWorkflowForm,
  IBorrowerLoanWorkflowSelect,
  IBorrowerLoanWorkflowStep,
  ISelectOption,
} from './types'

export const BorrowerPipelineConfiguration = () => {
  const [isLoading, setLoading] = useState(false)
  const [configs, setConfigs] = useState<IBorrowerLoanWorkflowStep[]>([])
  const [changed, setChanged] = useState(false)

  const inputFields = useMemo(() => {
    const inputs = getLoanInputFields({
      custom: true,
      other: true,
    })

    const customOptions: Record<string, InputType> = {}
    configs.forEach((config) => {
      if (config.type != 'form') return
      config.inputs
        .filter((v) => v.fieldOrigin == FieldOrigin.Custom)
        .forEach((_input) => {
          const input = _input as IBorrowerLoanWorkflowCustomInput
          const inputObj = {
            ...customFieldType2Input(input.fieldType),
            title: input.title,
          }
          if (input.fieldType == CustomFieldType.Select) {
            ;(inputObj as any).options = getNewSelectionOptions(
              [],
              (input as IBorrowerLoanWorkflowCustomInputSelect).options,
            )
          }

          customOptions[input.fieldKey] = inputObj
        })
    })
    inputs.Custom = customOptions

    return inputs
  }, [configs])

  const allInputOptions = useMemo(() => {
    const options = getLoanInputFieldOptions()

    const customOptions: Record<string, string> = {}
    configs.forEach((config) => {
      if (config.type != 'form') return
      config.inputs
        .filter((v) => v.fieldOrigin == FieldOrigin.Custom)
        .forEach((_input) => {
          const input = _input as IBorrowerLoanWorkflowCustomInput
          customOptions[input.fieldKey] = `[${capitalizeFirstLetter(input.fieldType)}] ${input.title}`
        })
    })
    options.Custom = customOptions

    return options
  }, [configs])
  const selectInputOptions = useMemo(() => getLoanInputFieldOptions('select'), [])
  const [errorFields, setErrorFields] = useState<Array<{ fieldOrigin: FieldOrigin; fieldKey: string }>>([])
  const [orderPageIndex, setOrderPageIndex] = useState(-1)

  useEffect(() => {
    setLoading(true)
    getAdminConfig('borrowerLoanWorkflow')
      .then((configs) => setConfigs(configs))
      .finally(() => setLoading(false))
  }, [])

  const onChange = (index: number, key: string, value: string) => {
    const newConfigs = cloneDeep(configs)
    ;(newConfigs[index] as any)[key] = value
    if (key === 'type') (newConfigs[index] as any).inputs = []
    setConfigs(newConfigs)
    setChanged(true)
  }

  const onAddStep = () => {
    const newConfigs = cloneDeep(configs)
    newConfigs.push({
      type: '',
      title: '',
      inputs: [],
    } as any)
    setConfigs(newConfigs)
    setChanged(true)
  }

  const spliceRequiredField = (fields: any[], fieldOrigin: FieldOrigin, fieldKey: string) => {
    const index = fields.findIndex((v: any) => v.fieldOrigin === fieldOrigin && v.fieldKey === fieldKey)
    if (index != -1) fields.splice(index, 1)
    return index != -1
  }

  const onSave = () => {
    const fields = cloneDeep(requiredFields)
    configs.forEach((config) => {
      if (config.type === 'select') {
        spliceRequiredField(fields, config.fieldOrigin, config.fieldKey)
      } else {
        config.inputs.forEach((input) => {
          spliceRequiredField(fields, input.fieldOrigin, input.fieldKey)
        })
      }
    })
    setErrorFields(fields)
    if (fields.length != 0) {
      setErrorFields(fields)
      return
    }

    setLoading(true)
    setAdminConfig('borrowerLoanWorkflow', configs)
      .then(() => toast('Borrower Loan Pipeline Configuration has been saved.', { type: 'info' }))
      .finally(() => {
        setLoading(false)
        setChanged(false)
      })
  }

  const onChangePageOrder = (pageIndex: number) => {
    setOrderPageIndex(pageIndex)
  }

  const onMovePage = (index: number, dir: 1 | -1) => {
    if (index + dir < 0 || index + dir >= configs.length) return

    const newConfigs = cloneDeep(configs)
    const curItem = newConfigs[index]
    newConfigs[index] = newConfigs[index + dir]
    newConfigs[index + dir] = curItem
    setConfigs(newConfigs)
    setChanged(true)
  }

  const onRemovePage = async (index: number) => {
    const result = await confirm('Are you sure to remove this page?')
    if (!result) return

    const newConfigs = cloneDeep(configs)
    newConfigs.splice(index, 1)
    setConfigs(newConfigs)
    setChanged(true)
  }

  const onAddControl = (index: number) => {
    const newConfigs = cloneDeep(configs)
    const inputs = (newConfigs[index] as any).inputs || []
    inputs.push({
      title: '',
      loanField: '',
    })
    ;(newConfigs[index] as any).inputs = inputs
    setConfigs(newConfigs)
    setChanged(true)
  }

  const onMoveControl = (index: number, subIndex: number, dir: 1 | -1) => {
    const newConfigs = cloneDeep(configs)
    const config = newConfigs[index]
    if (config.type != 'form') return

    if (subIndex + dir < 0 || subIndex + dir >= config.inputs.length) return

    const curItem = config.inputs[subIndex]
    config.inputs[subIndex] = config.inputs[subIndex + dir]
    config.inputs[subIndex + dir] = curItem
    setConfigs(newConfigs)
    setChanged(true)
  }

  const onRemoveControl = async (index: number, subIndex: number) => {
    const result = await confirm('Are you sure to remove this control?')
    if (!result) return

    const newConfigs = cloneDeep(configs)
    const config = newConfigs[index]
    if (config.type === 'form') config.inputs.splice(subIndex, 1)
    setConfigs(newConfigs)
    setChanged(true)
  }

  const getSelectOptions = (fieldOrigin: FieldOrigin, fieldKey: string): ISelectOption[] => {
    const input = inputFields[fieldOrigin][fieldKey] as InputSelect
    if (!input) return []
    let { options } = input

    if (Array.isArray(options)) return options.map((v) => ({ key: v, title: v, visible: true, isCustom: false }))
    return sortByKey(
      Object.keys(options).map((v) => ({ key: v, title: (options as any)[v], visible: true, isCustom: false })),
      'title',
    )
  }

  const onChangeControl = (index: number, subIndex: number, key: string, value: string) => {
    const newConfigs = cloneDeep(configs)
    let newConfig: IBorrowerLoanWorkflowSelect

    if (subIndex == -1) newConfig = newConfigs[index] as any
    else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any
    ;(newConfig as any)[key] = value

    if (key === 'fieldOrigin' && value === FieldOrigin.Custom) {
      newConfig.fieldKey = `${Date.now()}`
    }

    if (key === 'fieldKey') {
      const input = inputFields[newConfig.fieldOrigin][value]
      if (input) {
        newConfig.title = input.title
        if (input.inputType == 'select' && newConfig.fieldOrigin != FieldOrigin.Custom) {
          newConfig.options = getSelectOptions(newConfig.fieldOrigin, newConfig.fieldKey)
        }
      }
    }

    setConfigs(newConfigs)
    setChanged(true)
  }

  const renderSelectOptionInputs = (index: number, subIndex: number) => {
    const isSelectPage = configs[index].type === 'select'
    let config: IBorrowerLoanWorkflowSelect = isSelectPage ? configs[index] : (configs[index] as any).inputs[subIndex]
    const { fieldOrigin, fieldKey, options = [] } = config
    const isCustomInput = fieldOrigin === FieldOrigin.Custom

    const onAddOption = () => {
      const newConfigs = cloneDeep(configs)
      const config: IBorrowerLoanWorkflowSelect = isSelectPage
        ? newConfigs[index]
        : (newConfigs[index] as any).inputs[subIndex]
      config.options = config.options || []
      config.options.push({
        key: `${Date.now()}`,
        title: '',
        visible: true,
        isCustom: true,
      })
      setConfigs(newConfigs)
    }

    let originalOptions = isCustomInput ? [] : getSelectOptions(fieldOrigin, fieldKey)

    const onChangeControlOption = (
      idx: number,
      newValue: { [P in keyof ISelectOption]?: NonNullable<ISelectOption[P]> } | null,
    ) => {
      const newConfigs = cloneDeep(configs)
      let newConfig: IBorrowerLoanWorkflowSelect

      if (subIndex == -1) newConfig = newConfigs[index] as any
      else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any

      if (!newValue) newConfig.options.splice(idx, 1)
      else {
        newConfig.options[idx] = {
          ...newConfig.options[idx],
          ...newValue,
        }
      }

      setConfigs(newConfigs)
      setChanged(true)
    }

    const onChangeControlOptionOrder = (idx: number, dir: 1 | -1) => {
      const newConfigs = cloneDeep(configs)
      let newConfig: IBorrowerLoanWorkflowSelect

      if (subIndex == -1) newConfig = newConfigs[index] as any
      else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any

      const newIdx = idx + dir
      if (newIdx < 0 || newIdx >= newConfig.options.length) return
      else {
        let temp: ISelectOption = newConfig.options[idx]
        newConfig.options[idx] = newConfig.options[newIdx]
        newConfig.options[newIdx] = temp
      }

      setConfigs(newConfigs)
      setChanged(true)
    }

    const renderAdditionalComponent = (option: ISelectOption, idx: number) => {
      const { isCustom, mapTo } = option

      const onSelectAccount = async () => {
        const result: any = await openUserSearchDlg({
          title: 'Select Account',
          inputTitle: 'User Email',
          value: option.email || '',
        })
        if (!result) return
        onChangeControlOption(idx, { mapTo: result.id, email: result.value, title: result.label })
      }

      if (fieldOrigin == FieldOrigin.Other && fieldKey == 'manageAccounts') {
        return (
          <span className="text-shade-blue text-sm cursor-pointer hover:underline" onClick={onSelectAccount}>
            {option.email || 'Select Account'}
          </span>
        )
      }

      if (isCustom && !isCustomInput)
        return (
          <select
            id="status-filter-group"
            className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
            value={mapTo}
            onChange={(e) => onChangeControlOption(idx, { mapTo: e.target.value })}
          >
            <option value="-"> </option>
            {originalOptions.map((opt) => (
              <option value={opt.key}>{opt.title}</option>
            ))}
          </select>
        )
    }

    return (
      <div className="grid gap-2">
        <div className="flex justify-between">
          <p className="font-bold text-sm">Select Options</p>
          <span className="btn-icon" onClick={onAddOption}>
            <PlusIcon className="w-4 h-4" />
          </span>
        </div>
        {options.map((opt, idx) => {
          const { key, title, visible, isCustom } = opt
          const isVisible = !!visible || visible === undefined
          const Icon = isVisible ? EyeIcon : EyeSlashIcon

          return (
            <Input2
              title={`${idx + 1}. ${isCustom ? '' : key}`}
              value={title}
              key={`Option-${idx}-${key}`}
              placeholder={title}
              className={isVisible ? 'opacity-100' : 'opacity-30'}
              onChange={(value: string) => onChangeControlOption(idx, { title: value })}
              additionalElements={() => (
                <div className="flex gap-2">
                  {renderAdditionalComponent(opt, idx)}
                  <Icon
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onChangeControlOption(idx, { visible: !isVisible })}
                  />
                  {isCustom && (
                    <TrashIcon
                      className="w-4 h-4 cursor-pointer text-red-500"
                      onClick={() => onChangeControlOption(idx, null)}
                    />
                  )}
                  <ArrowUpIcon
                    className="w-4 h-4 cursor-pointer text-shade-blue"
                    onClick={() => onChangeControlOptionOrder(idx, -1)}
                  />
                  <ArrowDownIcon
                    className="w-4 h-4 cursor-pointer text-shade-blue"
                    onClick={() => onChangeControlOptionOrder(idx, 1)}
                  />
                </div>
              )}
            />
          )
        })}
      </div>
    )
  }

  const renderVisibleLogicOR = (control: IBorrowerLoanWorkflowControl, index: number, subIndex: number) => {
    const onAddVisibleLogic = (orIndex: number) => {
      const newConfigs = cloneDeep(configs)
      let newConfig: IBorrowerLoanWorkflowSelect

      if (subIndex == -1) newConfig = newConfigs[index] as any
      else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any

      if (!newConfig.visibleLogic) newConfig.visibleLogic = []
      if (orIndex == -1) newConfig.visibleLogic.push([])
      else
        newConfig.visibleLogic[orIndex].push({
          fieldOrigin: null,
          fieldKey: '',
          value: '',
        } as any)
      setConfigs(newConfigs)
      setChanged(true)
    }

    const onRemoveVisibleLogic = (orIndex: number, andIndex: number = -1) => {
      const newConfigs = cloneDeep(configs)
      let newConfig: IBorrowerLoanWorkflowSelect

      if (subIndex == -1) newConfig = newConfigs[index] as any
      else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any
      if (!newConfig.visibleLogic) return

      if (andIndex == -1) newConfig.visibleLogic.splice(orIndex, 1)
      else newConfig.visibleLogic[orIndex].splice(andIndex, 1)

      setConfigs(newConfigs)
      setChanged(true)
    }

    const renderVisibleLogic = (option: IVisibleLogic, orIndex: number, andIndex: number) => {
      const onUpdateVisibleLogic = (key: string, value: any) => {
        const newConfigs = cloneDeep(configs)
        let newConfig: IBorrowerLoanWorkflowSelect

        if (subIndex == -1) newConfig = newConfigs[index] as any
        else newConfig = (newConfigs[index] as IBorrowerLoanWorkflowForm).inputs[subIndex] as any
        ;(newConfig.visibleLogic![orIndex][andIndex] as any)[key] = value
        setConfigs(newConfigs)
        setChanged(true)
      }

      const { fieldOrigin, fieldKey, value } = option
      const inputs: Record<string, InputType> = {
        fieldOrigin: {
          inputType: 'select',
          title: 'Page Origin',
          options: fieldOriginOptions,
          hasDefaultOption: true,
          value: fieldOrigin,
          required: true,
          sort: false,
        },
        fieldKey: {
          inputType: 'select',
          title: 'Select Field',
          options: allInputOptions[fieldOrigin] || {},
          hasDefaultOption: true,
          value: fieldKey,
          required: true,
          sort: true,
        },
      }
      if (fieldOrigin && fieldKey) {
        inputs.value = {
          ...inputFields[fieldOrigin][fieldKey],
          value: value as any,
        }
      }
      return (
        <div className="pl-4">
          <div className="flex justify-between">
            <p className="text-sm">
              Condition {orIndex + 1} - {andIndex + 1}
            </p>
            <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex, andIndex)}>
              <MinusIcon className="w-4 h-4" />
            </span>
          </div>
          <div className="grid md:grid-cols-2 gap-x-2">
            {Object.keys(inputs).map((key) => {
              const elementKey = `${index}-${subIndex}-${fieldOrigin}-${fieldKey}-${key}`
              return (
                <div
                  className={`mb-4 ${!['fieldOrigin', 'fieldKey'].includes(key) ? 'col-span-full' : ''}`}
                  key={elementKey}
                >
                  <RenderInput
                    input={inputs[key]}
                    Key={elementKey}
                    onChange={(_key: string, value: string) => onUpdateVisibleLogic(key, value)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    const renderVisibleLogicAND = (logics: IVisibleLogic[], orIndex: number) => {
      return (
        <div className={`${orIndex % 2 == 0 ? `bg-slate-100` : ''} p-2`}>
          <div className="flex justify-between items-center">
            <p className="font-bold text-sm">{orIndex + 1}. Visible Conditions (AND)</p>
            <div className="flex">
              <span className="btn-icon" onClick={() => onRemoveVisibleLogic(orIndex)}>
                <MinusIcon className="w-4 h-4" />
              </span>
              <span className="btn-icon" onClick={() => onAddVisibleLogic(orIndex)}>
                <PlusIcon className="w-4 h-4" />
              </span>
            </div>
          </div>

          {logics.map((v, andIndex) => renderVisibleLogic(v, orIndex, andIndex))}
        </div>
      )
    }

    return (
      <div>
        <div className="flex justify-between items-center">
          <p className="font-bold text-sm">Visible Conditions (OR)</p>
          <span className="btn-icon" onClick={() => onAddVisibleLogic(-1)}>
            <PlusIcon className="w-4 h-4" />
          </span>
        </div>

        {!!control.visibleLogic && control.visibleLogic.map((v, logicIdx) => renderVisibleLogicAND(v, logicIdx))}
      </div>
    )
  }

  const renderCustomMapping = (control: IBorrowerLoanWorkflowCustomInput, index: number, subIndex: number) => {
    const inputs: Record<string, InputType> = {}
    const { linkedFieldOrigin, linkedFieldKey } = control

    inputs.linkedFieldOrigin = {
      inputType: 'select',
      title: 'Page Origin',
      options: fieldOriginOptionsExceptCustom,
      hasDefaultOption: true,
      value: linkedFieldOrigin,
      required: true,
      sort: false,
    }

    if (linkedFieldOrigin) {
      const options = allInputOptions[linkedFieldOrigin]
      inputs.linkedFieldKey = {
        inputType: 'select',
        title: 'Select Field',
        options: options || {},
        hasDefaultOption: true,
        value: linkedFieldKey,
        required: true,
        sort: true,
      }
    }

    return (
      <div className="mt-4">
        <p className="font-bold text-sm mb-2">Link Custom Field to</p>

        <div className="grid md:grid-cols-2 gap-x-2">
          {Object.keys(inputs).map((key) => {
            const elementKey = `${index}-${subIndex}-${linkedFieldOrigin}-${linkedFieldKey}-${key}`
            if (inputs[key].visible === false) return null

            return (
              <div className={`mb-4`} key={elementKey}>
                <RenderInput
                  input={inputs[key]}
                  Key={elementKey}
                  onChange={(_key: string, value: string) => onChangeControl(index, subIndex, key, value)}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderControl = (control: IBorrowerLoanWorkflowControl, index: number, subIndex: number) => {
    const { fieldOrigin, fieldKey, title } = control

    const inputs: Record<string, InputType> = {}
    const isFormControl = subIndex != -1

    inputs.fieldOrigin = {
      inputType: 'select',
      title: 'Page Origin',
      options: isFormControl ? fieldOriginOptions : fieldOriginOptionsExceptCustom,
      hasDefaultOption: true,
      value: fieldOrigin,
      required: true,
      sort: false,
    }

    const isCustomField = fieldOrigin === FieldOrigin.Custom
    const options = subIndex == -1 ? selectInputOptions[fieldOrigin] : allInputOptions[fieldOrigin]
    inputs.fieldKey = {
      inputType: 'select',
      title: 'Select Field',
      options: options || {},
      hasDefaultOption: true,
      value: control.fieldKey,
      required: true,
      sort: true,
      visible: !isCustomField,
    }
    let fieldType: CustomFieldType | null = null
    if (isCustomField) {
      ;({ fieldType } = control as IBorrowerLoanWorkflowCustomInput)
      inputs.fieldType = {
        inputType: 'select',
        title: 'Field Type',
        options: CustomFieldTypeOptions,
        hasDefaultOption: true,
        value: fieldType,
        required: true,
      }
    }

    if (subIndex != -1)
      inputs.title = {
        inputType: 'text',
        title: 'Title',
        value: title,
      }

    const input = fieldOrigin ? inputFields[fieldOrigin][fieldKey] || {} : null

    const visibilityInputs: Record<string, InputType> = {}
    if (fieldOrigin) {
      visibilityInputs.visibility = {
        inputType: 'section',
        title: `Visibility Logic (OR)`,
      }
    }

    return (
      <div className="grid lg:grid-cols-2 gap-x-2 mb-4" key={`control-${index}-${subIndex}`}>
        {isFormControl && (
          <div className="col-span-full mb-4">
            <div className={`border-b-2 border-shade-blue w-full italic flex justify-between items-center`}>
              <p>- Control {subIndex + 1}</p>

              <div className="flex items-center gap-2">
                <p className="btn-icon" onClick={() => onMoveControl(index, subIndex, -1)}>
                  <ArrowUpIcon className="w-4 h-4" />
                </p>
                <p className="btn-icon" onClick={() => onMoveControl(index, subIndex, 1)}>
                  <ArrowDownIcon className="w-4 h-4" />
                </p>
                <p className="text-sm cursor-pointer" onClick={() => onRemoveControl(index, subIndex)}>
                  - Remove
                </p>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className="grid md:grid-cols-2 gap-x-2">
            {Object.keys(inputs).map((key) => {
              const elementKey = `${index}-${subIndex}-${fieldOrigin}-${fieldKey}-${key}`
              if (inputs[key].visible === false) return null

              return (
                <div
                  className={`mb-4 ${!['fieldOrigin', 'fieldKey', 'fieldType'].includes(key) ? 'col-span-full' : ''}`}
                  key={elementKey}
                >
                  <RenderInput
                    input={inputs[key]}
                    Key={elementKey}
                    onChange={(_key: string, value: string) => onChangeControl(index, subIndex, key, value)}
                  />
                </div>
              )
            })}
          </div>
          {((input && input.inputType === 'select') ||
            (fieldOrigin == FieldOrigin.Custom && fieldType === CustomFieldType.Select)) &&
            renderSelectOptionInputs(index, subIndex)}
        </div>

        <div>
          {renderVisibleLogicOR(control, index, subIndex)}
          {isCustomField && renderCustomMapping(control as IBorrowerLoanWorkflowCustomInput, index, subIndex)}
        </div>
      </div>
    )
  }

  const renderForm = (inputs: IBorrowerLoanWorkflowControl[], index: number) => {
    return (
      <>
        {inputs.map((input, subIndex: number) => renderControl(input, index, subIndex))}
        <div className="col-span-full">
          <Button link onClick={() => onAddControl(index)} className="-mt-4">
            + Add Form Control
          </Button>
        </div>
      </>
    )
  }

  const renderStepContent = (item: IBorrowerLoanWorkflowStep, index: number) => {
    switch (item.type) {
      case 'form':
        return renderForm(item.inputs || [], index)
      case 'select':
        return renderControl(item, index, -1)
    }
  }

  const renderConfig = (item: IBorrowerLoanWorkflowStep, index: number) => {
    const inputs: Record<string, InputType> = {
      header: {
        inputType: 'section',
        title: `Page ${index + 1}`,
        span: 'full',
        additionalElements: (
          <div className="flex items-center gap-2">
            {item.type == 'form' && (
              <p className="btn-icon" onClick={() => onChangePageOrder(index)}>
                <BarsArrowDownIcon className="w-4 h-4" />
              </p>
            )}
            <p className="btn-icon" onClick={() => onMovePage(index, -1)}>
              <ArrowUpIcon className="w-4 h-4" />
            </p>
            <p className="btn-icon" onClick={() => onMovePage(index, 1)}>
              <ArrowDownIcon className="w-4 h-4" />
            </p>
            <p className="text-sm cursor-pointer" onClick={() => onRemovePage(index)}>
              - Remove
            </p>
          </div>
        ),
      },
      title: {
        inputType: 'text',
        title: 'Title',
        value: item.title,
      },
      type: {
        inputType: 'select',
        title: 'Form Type',
        options: {
          select: 'Select',
          form: 'Form',
        },
        hasDefaultOption: true,
        value: item.type,
      },
    }

    return (
      <div className={`pt-4 px-4 ${index % 2 == 1 ? 'bg-slate-50' : ''}`} key={`config-${index}`}>
        {Object.keys(inputs).map((key) => {
          return (
            <div className={`mb-4 ${key == 'header' ? '' : 'max-w-[50%]'} pr-2`} key={key}>
              <RenderInput
                input={inputs[key]}
                Key={key}
                onChange={(key: string, value: string) => onChange(index, key, value)}
              />
            </div>
          )
        })}
        <div>{renderStepContent(item, index)}</div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Customize Borrower Application</h1>
      <Prompt
        when={changed}
        message={`You've made some changes on Customize Borrower Application!\nAre you sure want to leave without Saving?`}
      />
      <LayoutLoading show={isLoading} />

      {configs.map((item, index) => renderConfig(item, index))}
      {!!errorFields.length ? (
        <p className="text-sm text-red-700 bg-red-50 border border-red-600 rounded mb-4 p-4 inline-block mt-4">
          Below fields are mandatary. Please add them and save.
          <br />
          {errorFields.map((field, index) => (
            <React.Fragment key={`field-${index}`}>
              - {fieldOriginOptions[field.fieldOrigin]} / {allInputOptions[field.fieldOrigin][field.fieldKey]}
              <br />
            </React.Fragment>
          ))}
        </p>
      ) : null}
      <div className="flex flex-row justify-center gap-4">
        <Button link onClick={onAddStep}>
          + Add New Page
        </Button>
        <Button onClick={onSave}>Save</Button>
      </div>
      <SaveChanges show={changed} label="Save Changes" onSave={onSave} />
      {orderPageIndex != -1 && (
        <PageOrderModal
          inputs={(configs[orderPageIndex] as IBorrowerLoanWorkflowForm).inputs}
          onClose={(newInputs: IBorrowerLoanWorkflowControl[] | null) => {
            if (newInputs) {
              const newConfigs = cloneDeep(configs)
              ;(newConfigs[orderPageIndex] as IBorrowerLoanWorkflowForm).inputs = newInputs
              setConfigs(newConfigs)
              setChanged(true)
            }
            setOrderPageIndex(-1)
          }}
        />
      )}
    </div>
  )
}
