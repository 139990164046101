import { ArrowPathIcon, ListBulletIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { type InputType, SettingKey } from 'config'
import type { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getLoanTermSheetTemp, getSetting, removeLoanTermSheetField, updateLoanTermSheetTemp } from 'services'
import { Button } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { AddTermSheetField } from './AddTermSheetField'
import { ILoanTermSheetTemp, TermSheetPlaceholders, termSheetTypeInputs } from './constants'
import { validatePlaceholders } from './logic'
import { PickPlaceholdersUp } from './PickPlaceholdersUp'
import { SyncTermSheetField } from './SyncTermSheetField'

export const termSheetTitles: Record<string, string> = {
  headerText: 'Header Text',
  exhibitA: 'Exhibit A',
}

export const LoanTermSheet = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(termSheetTypeInputs())
  const [data, setData] = useState<ILoanTermSheetTemp>()
  const [origin, setOrigin] = useState<ILoanTermSheetTemp>()
  const [edit, setEdit] = useState(false)
  const [add, setAdd] = useState(false)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [titleErrors, setTitleErrors] = useState<Record<number, string>>({})
  const [modal, setModal] = useState('')
  const [syncItem, setSyncItem] = useState({})
  const [globalSetting, setGlobalSetting] = useState<ILoanGlobalSettings>()

  const commitmentFeeIsRequired = useMemo(() => {
    if (!globalSetting) return true

    const productType = inputs.productType?.value
    const transactionType = inputs.transactionType?.value

    if (!productType || !transactionType) return true

    return !!globalSetting.commitmentFeeRequiredTypes.find(
      (item) => item.productType === productType && item.transactionType === transactionType,
    )
  }, [globalSetting, inputs.productType, inputs.transactionType])

  const dueDilligenceIsRequired = useMemo(() => {
    if (!globalSetting) return true

    const productType = inputs.productType?.value
    const transactionType = inputs.transactionType?.value

    if (!productType || !transactionType) return true

    return !!globalSetting.dueDilligenceRequiredTypes.find(
      (item) => item.productType === productType && item.transactionType === transactionType,
    )
  }, [globalSetting, inputs.productType, inputs.transactionType])

  useEffect(() => {
    setIsLoading(true)
    getSetting(SettingKey.GLOBAL_SETTINGS)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')
        setGlobalSetting(content)
      })
      .finally(() => setIsLoading(false))

    setEdit(false)

    setEdit(false)
    setErrors({})
    setTitleErrors({})
  }, [])

  const refetch = async (productType: string, transactionType: string) => {
    setIsLoading(true)
    const res = await getLoanTermSheetTemp(productType, transactionType)
    setData(res)
    setOrigin(res)
    setIsLoading(false)
  }

  const onChangeType = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = value

    const productType = newInputs.productType?.value
    const transactionType = newInputs.transactionType?.value

    if (!!productType && !!transactionType) refetch(productType, transactionType)

    setInputs(newInputs)
  }

  const onChangeHeaderExhibit = (key: 'headerText' | 'exhibitA', value: string) => {
    if (!data) return
    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    delete newErrors[key]

    if (!value) return

    newData[key] = value
    setErrors(newErrors)
    setData(newData)
    setEdit(true)
  }

  const onChangeFields = (index: number, value: string) => {
    if (!data) return
    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    if (!value) return

    delete newErrors[newData.fields[index].title]
    newData.fields[index].content = value
    setData(newData)
    setErrors(newErrors)
    setEdit(true)
  }

  const onChangeTitle = (index: number, value: string) => {
    if (!data) return
    const newData = cloneDeep(data)
    const newTitleErrors = cloneDeep(titleErrors)
    delete newTitleErrors[index]

    if (!value) return

    newData.fields[index].title = value
    setData(newData)
    setTitleErrors(newTitleErrors)
    setEdit(true)
  }

  const onBlurTitle = async (index: number) => {
    if (!data) return
    if (!origin) return
    if (!edit) return
    const newData = cloneDeep(data)

    const value = data.fields[index].title.trim()
    newData.fields[index].title = value
    const oldValue = origin.fields[index].title

    if (value === oldValue) return

    const existIndex = data.fields.findIndex((item) => item.title === value)
    if (existIndex !== -1 && existIndex !== index) {
      const newTitleErrors = cloneDeep(titleErrors)
      newTitleErrors[index] = `This title already exists`
      setTitleErrors(newTitleErrors)
      setData(newData)
      toast(`Please input another title`, { type: 'error' })
      return
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[16px]">
        Are you sure to change this title?
        <br />
        <span className="text-gray-800 text-base">
          {oldValue} {`=>`} {value}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) {
      newData.fields[index].title = oldValue
      setData(newData)
      return
    }

    setIsLoading(true)

    await updateLoanTermSheetTemp(data.id, newData)
    setData(newData)
    setOrigin(newData)
    setIsLoading(false)

    setEdit(false)
  }

  const onBlur = async (key: 'headerText' | 'exhibitA' | 'fields', index: number = 0) => {
    if (!data) return
    if (!origin) return
    if (!edit) return

    const value: any = ['headerText', 'exhibitA'].includes(key) ? data[key] : data.fields[index].content
    const oldValue: any = ['headerText', 'exhibitA'].includes(key) ? origin[key] : origin.fields[index].content

    if (value === oldValue) return

    const hasError = validatePlaceholders(value)

    if (hasError) {
      const newErrors = cloneDeep(errors)
      newErrors[['headerText', 'exhibitA'].includes(key) ? key : data.fields[index].title] =
        'Invalid placeholders exist'
      setErrors(newErrors)
      setEdit(false)
      toast(`Input valid placeholder`, { type: 'error' })
      return
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[16px]">
        Are you sure to save this content?
        <br />
        <span className="text-gray-800 text-base">
          Title: {['headerText', 'exhibitA'].includes(key) ? termSheetTitles[key] : data?.fields[index].title}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) {
      revertData(key, index)
      return
    }

    setIsLoading(true)

    await updateLoanTermSheetTemp(data.id, data)
    setOrigin(data)
    setIsLoading(false)

    setEdit(false)
  }

  const revertData = (key: 'headerText' | 'exhibitA' | 'fields', index: number = 0) => {
    if (!data) return
    if (!origin) return

    const newData = cloneDeep(data)

    if (['headerText', 'exhibitA'].includes(key)) (newData as any)[key] = origin[key]
    else newData.fields[index].content = origin.fields[index].content

    setData(newData)
  }

  const onChangeOrder = async (val: string, index: number) => {
    if (!data) return
    const newData = cloneDeep(data)

    const nth = Number(val)
    if (nth === index) return

    const newFields = cloneDeep(newData.fields)
    const removedOne = newFields.splice(index, 1)
    newFields.splice(nth, 0, removedOne[0])
    newData.fields = newFields

    setIsLoading(true)

    await updateLoanTermSheetTemp(newData.id, newData)
    setIsLoading(false)
    setData(newData)
    setOrigin(newData)
  }

  const onAddField = (newData: ILoanTermSheetTemp) => {
    setData(newData)
    setOrigin(newData)
    setAdd(!add)
  }

  const onRemoveField = async (index: number) => {
    if (!data) return

    const content = (
      <div className="text-gray-400 mb-4 text-[16px]">
        Are you sure to remove this field?
        <br />
        <span className="text-gray-800 text-base">Title: {data.fields[index].title}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    setIsLoading(true)
    try {
      await removeLoanTermSheetField(data.id, index)
      const newData = cloneDeep(data)
      newData.fields.splice(index, 1)
      setData(newData)
      setOrigin(newData)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const renderTemp = useMemo(() => {
    if (!data) return <></>

    if (!commitmentFeeIsRequired) {
      const newFields = data.fields.filter((item) => !item.content.includes('{{CommitmentFee}}'))
      data.fields = newFields
    }
    if (!dueDilligenceIsRequired) {
      const newFields = data.fields.filter((item) => !item.content.includes('{{DueDilligence}}'))
      data.fields = newFields
    }
    const orderOptions = Array.apply(null, Array(data.fields.length)).map((_, index) => index.toString())

    return (
      <>
        <div className="text-center text-2xl font-bold mb-4">CONDITIONAL LOAN TERM SHEET</div>

        <RenderInput
          input={{
            inputType: 'textarea',
            title: 'Header Text',
            value: data.headerText,
            rows: 15,
            error: errors.headerText,
            additionalElements: (
              <Tooltip message={`<div class="text-sm">Sync</div>`}>
                <div
                  className="p-1 cursor-pointer hover-shadow1 rounded transition-all duration-200"
                  onClick={() => {
                    setModal('sync')
                    setSyncItem({
                      key: 'headerText',
                      index: 0,
                    })
                  }}
                >
                  <ArrowPathIcon className="text-indigo-700 w-4 h-4" />
                </div>
              </Tooltip>
            ),
          }}
          Key={'headerText'}
          onChange={(key: string, value: any) => onChangeHeaderExhibit('headerText', value)}
          onBlur={() => onBlur('headerText')}
        />

        <hr className="my-6" />

        <div className="flex justify-end">
          <Button className="transition-all duration-200 w-32" onClick={() => setModal('add')}>
            <div className="flex justify-center items-center gap-2">
              <PlusIcon className="w-3.5 h-3.5" />
              <span>Add</span>
            </div>
          </Button>
        </div>
        <div className="grid grid-cols-9 gap-2">
          {data.fields.map((item, index) => {
            const rows = Math.ceil(item.content.length / 70)
            const additionalElements = (
              <div className="flex gap-2">
                <Tooltip message={`<div class="text-sm">Sync</div>`}>
                  <div
                    className="p-1 cursor-pointer hover-shadow1 rounded transition-all duration-200"
                    onClick={() => {
                      setModal('sync')
                      setSyncItem({
                        key: 'fields',
                        index,
                      })
                    }}
                  >
                    <ArrowPathIcon className="text-indigo-700 w-3.5 h-3.5" />
                  </div>
                </Tooltip>

                <Tooltip message={`<div class="text-sm">Remove</div>`}>
                  <div
                    className="p-1 cursor-pointer hover-shadow1 rounded transition-all duration-200"
                    onClick={() => onRemoveField(index)}
                  >
                    <TrashIcon className="text-red-700 w-3.5 h-3.5" />
                  </div>
                </Tooltip>
              </div>
            )

            return (
              <Fragment key={index}>
                <div className="col-span-2 content-start">
                  <RenderInput
                    input={{
                      inputType: 'text',
                      title: 'Title',
                      type: 'text',
                      value: item.title,
                      error: titleErrors[index],
                    }}
                    Key={'fields'}
                    onChange={(key: string, value: any) => onChangeTitle(index, value)}
                    onBlur={() => onBlurTitle(index)}
                  />
                </div>
                <div className="col-span-6 content-start">
                  <div className="mt-[-4px]">
                    <RenderInput
                      input={{
                        inputType: 'textarea',
                        title: 'Content',
                        value: item.content,
                        rows,
                        error: errors[item.title],
                        additionalElements,
                      }}
                      Key={'fields'}
                      onChange={(key: string, value: any) => onChangeFields(index, value)}
                      onBlur={() => onBlur('fields', index)}
                    />
                  </div>
                </div>
                <div className="col-span-1 content-center">
                  <div className="flex justify-center items-center">
                    <select
                      onChange={(e) => onChangeOrder(e.target.value, index)}
                      value={index}
                      className="block rounded py-1.5 px-2 w-20 text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer hover:cursor-pointer"
                    >
                      {orderOptions.map((val) => (
                        <option key={val}>{val}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>

        <hr className="my-6" />

        <div className="text-center text-lg">Exhibit A</div>
        <RenderInput
          input={{
            inputType: 'textarea',
            title: 'Exhibit A',
            value: data.exhibitA,
            rows: 25,
            error: errors.exhibitA,
            additionalElements: (
              <Tooltip message={`<div class="text-sm">Sync</div>`}>
                <div
                  className="p-1 cursor-pointer hover-shadow1 rounded transition-all duration-200"
                  onClick={() => {
                    setModal('sync')
                    setSyncItem({
                      key: 'exhibitA',
                      index: 0,
                    })
                  }}
                >
                  <ArrowPathIcon className="text-indigo-700 w-3.5 h-3.5" />
                </div>
              </Tooltip>
            ),
          }}
          Key={'exhibitA'}
          onChange={(key: string, value: any) => onChangeHeaderExhibit('exhibitA', value)}
          onBlur={() => onBlur('exhibitA')}
        />
      </>
    )
  }, [data, errors, add, titleErrors, commitmentFeeIsRequired, dueDilligenceIsRequired])

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-4">Loan Term Sheet Template</h2>

      <div className="mb-6">
        <div className="flex gap-2 items-center mb-2">
          <span className="ml-3 text-lg font-medium">- Placeholders</span>
          <Tooltip message="Pick Placeholders Up">
            <div
              className="p-1 hover-shadow1 cursor-pointer rounded transition-all duration-200"
              onClick={() => setModal('pickUpPlaceholders')}
            >
              <ListBulletIcon className="w-5 h-5 text-shade-blue" />
            </div>
          </Tooltip>
        </div>
        <div className="p-3 border rounded-md">
          <div className="flex gap-x-1 gap-y-3 flex-wrap justify-between">
            {TermSheetPlaceholders.filter((item) => {
              if (!commitmentFeeIsRequired && item === 'CommitmentFee') return false
              else if (!dueDilligenceIsRequired && item === 'DueDilligence') return false
              else return true
            })
              .sort()
              .map((item, index) => {
                return (
                  <pre key={index} className="w-[240px]">
                    {item}
                  </pre>
                )
              })}
          </div>
        </div>
      </div>

      <div className="flex gap-4 mb-6">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]

          return (
            <div className={`w-[350px]`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChangeType} />
            </div>
          )
        })}
      </div>

      {renderTemp}

      {modal === 'sync' && !!data && (
        <SyncTermSheetField item={syncItem as any} data={data} onClose={() => setModal('')} />
      )}
      {modal === 'add' && !!data && (
        <AddTermSheetField data={data} onAddField={onAddField} onClose={() => setModal('')} />
      )}
      {modal === 'pickUpPlaceholders' && !!globalSetting && (
        <PickPlaceholdersUp
          globalSetting={globalSetting}
          onClose={() => setModal('')}
          setGlobalSetting={setGlobalSetting}
        />
      )}
    </div>
  )
}
