import { IS_MAIN_COMPANY } from 'config'
import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ProtectedRoutes } from './protected.route'

const AboutUs = lazy(() => import('pages/Base/AboutUs'))
const Careers = lazy(() => import('pages/Base/Careers'))
const ContactUs = lazy(() => import('pages/Base/ContactUs'))
const FAQs = lazy(() => import('pages/Base/FAQ'))
const Home = lazy(() => import('pages/Base/Home/Home'))
const HowItWorks = lazy(() => import('pages/Base/HowItWorks'))
const Investors = lazy(() => import('pages/Base/Investors'))
const Pricing = lazy(() => import('pages/Base/Pricing'))
const WhereWeLend = lazy(() => import('pages/Base/WhereWeLend'))

const ForgetPassword = lazy(() => import('pages/Auth/Login/ForgetPassword'))
const Login = lazy(() => import('pages/Auth/Login/Login'))
const Register = lazy(() => import('pages/Auth/Login/Register'))
const ResetPassword = lazy(() => import('pages/Auth/Login/ResetPassword'))

const LoanOverview = lazy(() => import('pages/LoanOverview'))
const BorrowerCreateLoan = lazy(() => import('pages/Borrower/CreateLoan/BorrowerCreateLoanByConfig'))
const Resources = lazy(() => import('pages/Resources'))

export function BaseRoute() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={IS_MAIN_COMPANY ? '/home' : '/login'} />
      </Route>
      <Route exact path="/home">
        {IS_MAIN_COMPANY ? <Home /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/about_us">
        <AboutUs />
      </Route>
      <Route exact path="/investors">
        <Investors />
      </Route>
      <Route exact path="/how_it_works">
        <HowItWorks />
      </Route>
      <Route exact path="/where_we_lend">
        <WhereWeLend />
      </Route>
      <Route exact path="/pricing">
        <Pricing />
      </Route>
      <Route exact path="/faqs">
        <FAQs />
      </Route>
      <Route exact path="/contacts">
        <ContactUs />
      </Route>
      <Route exact path="/careers">
        <Careers />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/register/:id">
        <Register />
      </Route>
      <Route exact path="/forgetPassword">
        <ForgetPassword />
      </Route>
      <Route exact path="/resetPassword/:selector/:token">
        <ResetPassword />
      </Route>
      <Route exact path="/loan_process/structure/new">
        <LoanOverview />
      </Route>
      <Route exact path="/loan_process/structure/new/:token/:id">
        <LoanOverview />
      </Route>
      <Route exact path="/qq/new/:aeId" key={12}>
        <BorrowerCreateLoan />
      </Route>
      <ProtectedRoutes />
    </Switch>
  )
}
