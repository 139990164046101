import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { getAdminConfig } from 'services'
import { getPrice1or2decimal } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const PaymentTermsConditions = ({
  onChangeTerms,
  amount,
  lenderName,
}: {
  onChangeTerms: Function
  amount?: string | number
  lenderName?: string
}) => {
  const [terms, setTerms] = useState<Array<boolean>>([])
  const [termsAndConditions, setTermsAndCondtions] = useState<Array<string>>([])

  const initData = async () => {
    const res = await getAdminConfig('paymentTerms')
    setTermsAndCondtions(res)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = (index: number, value: any) => {
    const temp = cloneDeep(terms)
    temp[index] = value
    let isAgreed = true
    for (let i = 0; i < termsAndConditions.length; i++) {
      if (!temp[i]) {
        isAgreed = false
        break
      }
    }
    if (isAgreed) onChangeTerms(true)
    else onChangeTerms(false)
    setTerms(temp)
  }
  return (
    <div>
      <RenderInput
        input={{ inputType: 'group', title: 'Payment Terms and Conditions' }}
        Key="title"
        onChange={onChange}
      />
      {termsAndConditions.map((v: string, index: number) => {
        if (amount) v = v.replaceAll('[Fee Amount]', `$${getPrice1or2decimal(amount)}`)
        if (lenderName) v = v.replaceAll('[Lender Name]', `${lenderName}`)
        return (
          <div key={v} className="input mt-2 flex gap-4">
            <div>
              <RenderInput
                input={{ inputType: 'checkbox', title: '', value: terms[index] || false }}
                Key={v}
                onChange={(key: string, value: any) => onChange(index, value)}
              />
            </div>
            <p
              className="flex-1 cursor-pointer"
              onClick={() => onChange(index, !terms[index])}
              dangerouslySetInnerHTML={{ __html: v }}
            ></p>
          </div>
        )
      })}
    </div>
  )
}
