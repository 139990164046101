import cloneDeep from 'clone-deep'
import { useState } from 'react'
import { Modal } from 'stories/components'

import { IBorrowerLoanWorkflowControl } from './types'

export const PageOrderModal = ({
  onClose,
  inputs: _inputs,
}: {
  onClose: Function
  inputs: IBorrowerLoanWorkflowControl[]
}) => {
  const [inputs, setInputs] = useState<IBorrowerLoanWorkflowControl[]>(_inputs)

  const onChangeOrder = (oldIdx: number, newIdx: number) => {
    const newInputs = cloneDeep(inputs)
    const deletedItem = newInputs.splice(oldIdx, 1)
    newInputs.splice(newIdx, 0, ...deletedItem)
    setInputs(newInputs)
  }

  const sortKeys = Object.keys(Array(inputs.length).fill(1))

  return (
    <Modal
      title="Update Control orders"
      onClose={() => onClose(null)}
      titleOkay="Update"
      onOk={() => onClose(inputs)}
      isOpen
    >
      <div className="min-w-[32rem]">
        {inputs.map((input, index) => (
          <div className="text-sm flex justify-between items-center mb-1 border-b" key={`item-${index}`}>
            <p>
              {index + 1}. {input.title}
            </p>

            <select
              value={index}
              className="text-sm border-gray-200 py-1"
              onChange={(e) => onChangeOrder(index, parseInt(e.target.value))}
            >
              {[...sortKeys].map((value) => (
                <option value={value} key={value}>
                  {parseInt(value) + 1}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
    </Modal>
  )
}
