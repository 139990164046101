import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { calculateAggregateAdjustment } from 'pages/Loan/ClosingScreen/HUD1Page2'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  createLoanPayment,
  deleteLoanPayment,
  gethud1PageData,
  getLoanPaymentFillData,
  getLoanPayments,
  getPurchaseAdviceData,
  updateLoanPayment,
} from 'services'
import { PlainTable } from 'stories/components'
import {
  confirm,
  convertNegative2Parentheses,
  formatDate,
  getPrice1or2decimal,
  getPrice2decimal,
  removeComma,
} from 'utils'

import { LoanPaymentsModal } from './LoanPaymentsModal'

const tableHeader = [
  'Due Date',
  'Date Received',
  'Date Deposited',
  'Amount Due',
  'Amount Paid',
  'Principal',
  'Interest',
  'Escrows',
  'Buydowns',
  'Late Fees',
  'Total Credited',
  'Unpaid Balance',
  'Notes',
  'Action',
]

export const LoanPayments = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [showAdd, setShowAdd] = useState(false)
  const [editData, setEditData] = useState<Record<string, any>>({})
  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>({})
  const [purchaseAdviceInfo, setPurchaseAdviceInfo] = useState<Record<string, any>>({})
  const [hud1Info, setHud1Info] = useState<Record<string, any>>({})

  const initData = async () => {
    setIsLoading(true)
    const [loanPayments, paymentData, purchaseAdviceData, hud1Data] = await Promise.all([
      getLoanPayments(),
      getLoanPaymentFillData(),
      getPurchaseAdviceData(),
      gethud1PageData('page2'),
    ])
    setData(loanPayments)
    setPaymentInfo(paymentData)
    setPurchaseAdviceInfo(purchaseAdviceData.data.data)
    setHud1Info(hud1Data.data)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const tableData = useMemo(() => {
    const { isDutch, loanAmount, initialLoanAmount } = paymentInfo
    const baseLoanAmount = isDutch ? loanAmount : initialLoanAmount
    let paidPrincipal = 0
    return data.map((item: Record<string, any>) => {
      paidPrincipal += Number(item.principal || 0)
      const unpaidBalance = Number((baseLoanAmount - paidPrincipal).toFixed(2))
      return [
        formatDate(item.paymentDueDate),
        formatDate(item.paymentReceivedDate),
        formatDate(item.paymentDepositedDate),
        getPrice1or2decimal(item.amountDue),
        getPrice1or2decimal(item.amountPaid),
        getPrice1or2decimal(item.principal),
        getPrice1or2decimal(item.interest),
        getPrice1or2decimal(item.escrowsTotal),
        getPrice1or2decimal(item.buydown),
        getPrice1or2decimal(item.lateFee),
        Math.round((+item.principal + +item.interest + +item.escrowsTotal + +item.buydown + +item.lateFee) * 100) / 100,
        getPrice2decimal(unpaidBalance, false, true),
        item.notes,
        <div className="flex items-center gap-1">
          <span className="text-shade-blue cursor-pointer" onClick={() => onEdit(item)}>
            <PencilSquareIcon className="w-4 h-4" />
          </span>
          <span className="text-red-600 cursor-pointer" onClick={() => onDelete(item.id)}>
            <TrashIcon className="w-4 h-4" />
          </span>
        </div>,
      ]
    })
  }, [data])

  const onEdit = (item: Record<string, any>) => {
    setEditData(item)
    setShowAdd(true)
  }

  const onAdd = () => {
    setEditData({})
    setShowAdd(true)
  }

  const onSubmit = async (id: number | null, json: Record<string, any>) => {
    let res
    setIsModalLoading(true)
    if (id) res = await updateLoanPayment(id, json)
    else res = await createLoanPayment(json)
    if (res.success) {
      const temp = cloneDeep(data)
      if (id) {
        temp.map((item) => {
          if (item.id == id) {
            Object.keys(json).map((key) => {
              item[key] = json[key]
            })
          }
        })
      } else {
        temp.push(res.item)
      }
      setData(temp)
      toast(`Successfully ${id ? 'updated' : 'created'} loan payment`, { type: 'success' })
    }
    setEditData({})
    setIsModalLoading(false)
    setShowAdd(false)
  }

  const onDelete = async (id: number) => {
    const res = await confirm('Are you sure want to remove this loan payment?')
    if (!res) return
    await deleteLoanPayment(id)
    const temp: Array<Record<string, any>> = []
    data.map((item: Record<string, any>) => {
      if (item.id == id) return
      temp.push(item)
    })
    setData(temp)
    toast('Successfully removed loan payment', { type: 'success' })
  }

  const creditedValue = useMemo(() => {
    let rtl = {
      principal: 0,
      interest: 0,
      escrowsTotal: 0,
      buydown: 0,
      lateFee: 0,
      amountPaid: 0,
      total: 0,
    }
    data.map((item) => {
      rtl.principal += Number(removeComma(item.principal))
      rtl.escrowsTotal += Number(removeComma(item.escrowsTotal))
      rtl.buydown += Number(removeComma(item.buydown))
      rtl.interest += Number(removeComma(item.interest))
      rtl.lateFee += Number(removeComma(item.lateFee))
      rtl.amountPaid += Number(removeComma(item.amountPaid))
    })
    rtl.total = rtl.principal + rtl.interest + rtl.escrowsTotal + rtl.buydown + rtl.lateFee
    return rtl
  }, [data])

  const escrowDeposit = useMemo(() => {
    let sum = 0
    for (let i = 0; i < 7; i++) {
      const prefix = `PrepaidItems.${i}.`
      if (hud1Info[`${prefix}ReservesPOC`]) continue
      sum += hud1Info[`${prefix}ReservesOV`]
        ? +hud1Info[`${prefix}ReservesOV`]
        : +hud1Info[`${prefix}MonthsInReserve`] * +hud1Info[`${prefix}Payment`]
    }
    sum += calculateAggregateAdjustment(hud1Info)
    return sum
  }, [hud1Info])

  return (
    <div className="loan-payments-container w-full">
      <LayoutLoading show={isLoading} />
      <div className="flex items-center justify-end">
        <span
          className="text-shade-blue cursor-pointer flex items-center gap-1 hover:underline"
          onClick={() => onAdd()}
        >
          <PlusCircleIcon className="w-4 h-4" />
          Add
        </span>
      </div>
      <PlainTable header={tableHeader} data={tableData} isLastColumnStatic />
      {showAdd && (
        <LoanPaymentsModal
          editData={editData}
          onClose={() => setShowAdd(false)}
          onOk={onSubmit}
          payments={data}
          paymentInfo={{ ...paymentInfo, firstPaymentDate: purchaseAdviceInfo.FirstPaymentDate }}
          loading={isModalLoading}
        />
      )}
      <table>
        <tbody>
          <tr>
            <td></td>
            {['Amount At Closing', 'Credited', 'Current Amount', 'Purchase Advice', 'Net Amount'].map((v) => {
              return <td className="p-2 border-x-[1px] border-gray-500">{v}</td>
            })}
          </tr>
          {[
            [
              'Unpaid Principal Balance',
              paymentInfo.loanAmount,
              creditedValue.principal,
              +paymentInfo.loanAmount - creditedValue.principal,
              purchaseAdviceInfo.PAUnpaidPrincipalBalance,
              '',
            ],
            [
              'Escrow Balance',
              escrowDeposit,
              creditedValue.escrowsTotal,
              escrowDeposit + creditedValue.escrowsTotal,
              purchaseAdviceInfo.PAEscrowBalance,
              '',
            ],
            [
              'Buydown Funds',
              '0.00',
              creditedValue.buydown,
              0 - creditedValue.buydown,
              purchaseAdviceInfo.PABuydownFunds,
              '',
            ],
            ['Interest', '', creditedValue.interest, '', '', ''],
            ['Late Fees', '', creditedValue.lateFee, '', '', ''],
          ].map((item: Array<any>) => {
            return (
              <tr>
                <td className="p-2 border-y-[1px] border-gray-500">{item[0]}</td>
                {item.map((v: any, index: number) => {
                  if (!index) return
                  return (
                    <td className="p-2 border-[1px] border-gray-500 text-right">
                      {convertNegative2Parentheses(getPrice2decimal(v, false, true))}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            <td className="p-2">Total Amount Credited</td>
            <td className="p-2 text-right">{getPrice2decimal(creditedValue.total, false, true)}</td>
          </tr>
          <tr>
            <td className="p-2">Total Amount Paid</td>
            <td className="p-2 text-right">{getPrice2decimal(creditedValue.amountPaid, false, true)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
