import { type InputType, loanOptionsConstants } from 'config'

export const TermSheetPlaceholders: string[] = [
  'LoanAmount',
  'LTV',
  'BorrowerName',
  'BorrowerCompanyName',
  'BrokerStreet',
  'BrokerCity',
  'BrokerState',
  'BrokerZipCode',
  'LenderCompany',
  'SubjectPropertyAddress',
  'InterestRate',
  'LoanTerm',
  'DiscountFeePercent',
  'DiscountFeeAmount',
  'CashBorrowerAtClosing',
  'ExitFee',
  'ConstructionBudget',
  'ConstructionAmountFinanced',
  'BrokerFeeAmount',
  'BrokerFeePercent',
  'BrokerCompanyName',
  'UnderwritingFee',
  'BorrowerType',
  'FiveLaterDate',
  'TodayDate',
  'LoanOfficerOrBrokerName',
  'EstimatedCreditScore',
  'CommitmentFee',
  'BreakUpFee',
  'DueDilligence',
  'MonthlyPaymentNonEscrow',
  'MonthlyPaymentWithEscrow',
]

export const fieldsInputs = (): Record<string, InputType> => {
  return {
    title: {
      title: 'Title',
      inputType: 'text',
      type: 'text',
      error: '',
      required: true,
      span: 1,
    },
    content: {
      title: 'Content',
      inputType: 'textarea',
      rows: 3,
      error: '',
      span: 2,
      required: true,
    },
  }
}

export const termSheetTypeInputs = (): Record<string, InputType> => {
  return {
    productType: {
      title: 'Product Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.productType,
      error: '',
    },
    transactionType: {
      title: 'Transaction Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.transactionType,
      error: '',
    },
  }
}

export interface ITermSheetFields {
  title: string
  content: string
}

export interface ILoanTermSheetTemp {
  id: number
  productType: string
  transactionType: string
  headerText: string
  exhibitA: string
  fields: ITermSheetFields[]
}
