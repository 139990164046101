import { InputType } from 'config'

export const getTextConfigurationFields = () => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Closing Calendar',
      span: 'full',
    },
    closingCalendarDesc: {
      title: 'Description',
      inputType: 'textarea',
      type: 'text',
      span: 2,
      rows: 2,
    },
    section2: {
      inputType: 'section',
      title: 'Loan Application',
      span: 'full',
    },
    additionalEntityDesc: {
      title: 'Additional Entity Members',
      inputType: 'textarea',
      type: 'text',
      span: 2,
      rows: 2,
    },
    section3: {
      inputType: 'section',
      title: 'Loan Structure',
      span: 'full',
    },
    shareScenarioWorking: {
      title: 'Share Scenario working with...',
      inputType: 'text',
      type: 'text',
    },
  }

  return rlt
}
