import { API_REMINDER, API_REMINDER_BY_ID, API_REMINDER_FIND_ALL, API_REMINDER_LOG } from 'config'
import Api from 'services/api'

export const getRemindersByFilters = (filters?: Record<string, any>) => {
  return Api.post(API_REMINDER_FIND_ALL, filters)
}

export const getReminderById = (id: number) => {
  return Api.get(API_REMINDER_BY_ID, {}, { id })
}

export const createReminder = (data: Record<string, any>) => {
  return Api.post(API_REMINDER, data)
}

export const updateReminder = (id: number, data: Record<string, any>) => {
  return Api.put(API_REMINDER_BY_ID, data, { id })
}

export const deleteReminder = (id: number) => {
  return Api.delete(API_REMINDER_BY_ID, {}, { id })
}

export const getReminderLogs = (no: number, key: string) => {
  return Api.get(API_REMINDER_LOG, {}, { no, key })
}
