export enum FieldOrigin {
  LoanStructure = 'LoanStructure',
  BorrowerInformation = 'BorrowerInformation',
  PropertyInformation = 'PropertyInformation',
  AssetLiability = 'AssetLiability',
  TrackRecord = 'TrackRecord',
  DeclarationsHMDA = 'DeclarationsHMDA',
  Other = 'Other',
  Custom = 'Custom',
}

export const fieldOriginOptions: Record<FieldOrigin, string> = {
  [FieldOrigin.LoanStructure]: 'Loan Structure',
  [FieldOrigin.BorrowerInformation]: 'Borrower Information',
  [FieldOrigin.PropertyInformation]: 'Property Information',
  [FieldOrigin.AssetLiability]: 'Asset & Liability',
  [FieldOrigin.TrackRecord]: 'Track Record',
  [FieldOrigin.DeclarationsHMDA]: 'Declarations & HMDA',
  [FieldOrigin.Other]: 'Other',
  [FieldOrigin.Custom]: 'Custom',
}

export interface IVisibleLogic {
  fieldOrigin: FieldOrigin
  fieldKey: string
  value: string | number | boolean
}

export interface ILoanFieldPos {
  fieldOrigin: FieldOrigin
  fieldKey: string
  [x: string]: any
}

export interface IVisibleProp {
  title?: string
  tooltip?: string
  defaultValue?: any
  visible?: boolean
  visibleLogic?: IVisibleLogic[][]
}

export type LoanFieldProps = Record<FieldOrigin, Record<string, IVisibleProp>>
