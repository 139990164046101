import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { calculateDueDate, formatDate, getNextPaymentDate, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constants'

export const LoanPaymentsModal = ({
  editData,
  payments,
  paymentInfo,
  onClose,
  onOk,
  loading,
}: {
  editData: Record<string, any>
  payments: Array<Record<string, any>>
  paymentInfo: Record<string, any>
  onClose: () => void
  onOk: Function
  loading: boolean
}) => {
  const loanNumber = setLoanNumber()
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs(loanNumber))
  useEffect(() => {
    if (editData.id) {
      const temp = cloneDeep(inputs)
      Object.keys(temp).map((key) => {
        temp[key].value = editData[key]
      })
      setInputs(temp)
    }
  }, [editData])
  const onModalOk = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    Object.keys(inputs).map((key) => {
      if (key == 'totalCredited' || newInputs[key].inputType === 'custom') return
      newInputs[key].error = InputValidate({ ...newInputs[key], value: newInputs[key].value })
      if (newInputs[key].error?.length) hasError = true
      data[key] = newInputs[key].value
    })
    setInputs(newInputs)
    if (hasError) return
    onOk(editData.id, data)
  }
  const onChange = (key: string, value: any) => {
    const temp = cloneDeep(inputs)
    temp[key].value = value
    setInputs(temp)
  }

  const onFillDue = async () => {
    const temp = cloneDeep(inputs)
    const { firstPaymentDate, amountDue, isDutch } = paymentInfo
    let paymentDueDate
    if (payments.length)
      paymentDueDate = payments[payments.length - 1].paymentDueDate
        ? calculateDueDate(payments[payments.length - 1].paymentDueDate, 1, false)
        : ''
    else paymentDueDate = firstPaymentDate
    if (paymentDueDate) temp.paymentDueDate.value = formatDate(paymentDueDate, 'yyyy-MM-DD')
    temp.amountDue.value = isDutch ? amountDue : calculateNonDutchAmountDue(temp.paymentDueDate.value)
    setInputs(temp)
  }

  const onFillPaid = () => {
    const temp = cloneDeep(inputs)
    const currentDate = formatDate(new Date(), 'yyyy-MM-DD')
    temp.paymentReceivedDate.value = currentDate
    temp.paymentDepositedDate.value = currentDate
    temp.amountPaid.value = temp.amountDue.value
    setInputs(temp)
  }

  const onFillEscrow = async () => {
    const temp = cloneDeep(inputs)
    const { mortgageInsurance, escrowAmount, loanAmount, rate, amountDue, isDutch } = paymentInfo
    temp.mortgage.value = mortgageInsurance
    temp.escrows.value = escrowAmount

    let balance = loanAmount
    payments.map((payment) => {
      balance -= Number(payment.principal || 0) + Number(payment.amountPaid || 0) - Number(payment.amountDue || 0)
    })
    const interest = isDutch
      ? Number(((balance * rate) / 100 / 12).toFixed(2))
      : Number(removeComma(inputs.amountDue.value))
    const principal =
      (inputs.amountPaid.value
        ? Number(removeComma(inputs.amountPaid.value))
        : isDutch
        ? amountDue
        : Number(removeComma(inputs.amountDue.value))) -
      +mortgageInsurance -
      +escrowAmount -
      interest

    temp.principal.value = Math.round(principal * 100) / 100
    temp.interest.value = interest
    setInputs(temp)
  }

  const calculateNonDutchAmountDue = (paymentDueDate: string) => {
    const { trustLedgerData: data, initialLoanAmount, rate, firstPaymentDate } = paymentInfo
    let amount = Number(((initialLoanAmount * rate) / 100 / 12).toFixed(2))
    data
      .filter((v: any) => v.date < paymentDueDate)
      .map((item: any) => {
        const { inspectionFee, wireFee, titleUpdateFee, additionHoldback, payment, deposit, date } = item
        const nextPaymentDate = getNextPaymentDate(firstPaymentDate, date)
        const diffDays = 30 + Number(nextPaymentDate.split('-')[2]) - Number(date.split('-')[2])
        let drawDisbursed = 0
        ;[inspectionFee, wireFee, titleUpdateFee, additionHoldback].map((v) => {
          drawDisbursed += Number(v || 0)
        })
        drawDisbursed += Number(deposit || payment)
        amount += Number(((drawDisbursed * rate * +diffDays) / 100 / 360).toFixed(2))
      })
    return Number(amount.toFixed(2))
  }
  return (
    <Modal title="Loan Payment" isOpen={true} onClose={onClose} onOk={onModalOk} loading={loading}>
      <div className="grid md:grid-cols-4 grid-cols-2 gap-2">
        {Object.keys(inputs).map((key, index: number) => {
          const input = inputs[key]
          if (['amountDue', 'amountPaid', 'principal'].includes(key)) {
            let fillFunction: Function
            if (key == 'amountDue') fillFunction = onFillDue
            if (key == 'amountPaid') fillFunction = onFillPaid
            if (key == 'principal') fillFunction = onFillEscrow
            input.additionalElements = (
              <span className="cursor-pointer text-shade-blue hover:underline" onClick={() => fillFunction()}>
                Fill
              </span>
            )
          }
          if (key == 'escrowsTotal') {
            if (inputs.mortgage.value?.toString().length || inputs.escrows.value?.toString().length) {
              input.disabled = true
              input.value = Number(removeComma(inputs.mortgage.value)) + Number(removeComma(inputs.escrows.value))
            } else {
              input.disabled = false
            }
          }
          if (key == 'totalCredited') {
            let total = 0
            ;['principal', 'interest', 'escrowsTotal', 'buydown', 'lateFee'].map((key) => {
              total += Number(removeComma(inputs[key].value) || 0)
            })
            input.value = Math.round(total * 100) / 100
          }
          return (
            <div className={`col-span-${input.span}`}>
              <RenderInput input={input} Key={key} onChange={onChange} />
              {input.inputType === 'custom' && (
                <div className="border-slate-300 border-b-[1px] my-2" key={`custom-${index}`}></div>
              )}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
