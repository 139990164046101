import { DocumentDuplicateIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { SaveChanges } from 'components/SaveChanges'
import { companyName } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { getSecondaryMarketingScreenData, postSecondaryMarketingScreenData } from 'services/apis'
import { Button, FormTable, Input, Modal } from 'stories/components'
import { getPrice3decimal, removeComma, useTitle } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import {
  buyPricingFields,
  defaultMarkupInputs,
  defaultPricingAdjustmentInputs,
  investorCommitmentFields,
  markupHeader,
  originatorLockFields,
  priceHeader,
  sellPricingFields,
} from './constant'

export default function SecondaryMarketingScreen() {
  useTitle(`Secondary Marketing - ${companyName}`)

  const [action, setAction] = useState('')
  const [IDs, setIDs] = useState<any>({})
  const [data, setData] = useState<any>({
    LoanWith: 365000,
    Pricing: {
      Buy: [],
      Sell: [],
    },
    Markup: [],
    BuyPriceBase: '',
    BuySRP: '',
    ARMMargin: '',
    IntRate: '',
    InvestorPriceBase: '',
    InvestorSRP: '',
    InvestorARMMargin: '',
    InvestorIntRate: '',
  })
  const [priceModal, setPriceModal] = useState('')
  const [buyProfit, setBuyProfit] = useState(0)
  const [sellProfit, setSellProfit] = useState(0)
  const [investors, setInvestors] = useState([])
  const [changed, setChanged] = useState(false)
  const onSync = async () => {
    setAction('sync')
    setChanged(false)
    await postSecondaryMarketingScreenData({ IDs, data })
    setAction('')
  }

  const initData = async () => {
    setAction('init')
    const res = await getSecondaryMarketingScreenData()
    setAction('')
    if (res.success) {
      if (res.data.data) setData(res.data.data)
      setIDs(res.data.IDs)
      setInvestors(res.data.investors)
    }
  }

  useEffect(() => {
    setLoanNumber()
    initData()
  }, [])

  const changeData = (key: string, value: any) => {
    const temp = cloneDeep(data)
    temp[key] = value
    setChanged(true)
    setData(temp)
  }

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      let temp = cloneDeep(data)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        temp['Markup'].push(values)
      } else {
        temp['Markup'].map((item: any, index: number) => {
          if (item.id === currentId) temp['Markup'][index] = values
        })
      }
      setData(temp)
      setChanged(true)
      resolve(values)
    })
  }

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      let newMarkup: any = []
      data['Markup'].map((item: any) => {
        if (item.id !== id) newMarkup.push(item)
      })
      let temp = cloneDeep(data)
      temp['Markup'] = newMarkup
      setData(temp)
      resolve(newMarkup)
    })
  }

  const onPriceSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      let temp = cloneDeep(data)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        temp['Pricing'][priceModal].push(values)
      } else {
        temp['Pricing'][priceModal].map((item: any, index: number) => {
          if (item.id === currentId) temp['Pricing'][priceModal][index] = values
        })
      }
      setData(temp)
      resolve(values)
    })
  }

  const onPriceRemove = async (id: any) => {
    return new Promise((resolve) => {
      let newMarkup: any = []
      data['Pricing'][priceModal].map((item: any) => {
        if (item.id !== id) newMarkup.push(item)
      })
      let temp = cloneDeep(data)
      temp['Pricing'][priceModal] = newMarkup
      setData(temp)
      resolve(newMarkup)
    })
  }

  const markupTotal = useMemo(() => {
    let totalPercent = 0
    let totalAmount = 0
    let markup = data['Markup']
    markup?.map((item: any) => {
      totalPercent += removeComma(item.MarkupPercent)
      totalAmount += removeComma(item.MarkupAmount)
    })
    return (
      <div className="flex flex-wrap gap-6">
        <div className="flex flex-wrap gap-2 items-center">
          <span className="text-[13px]">Total Percent:</span>
          <span className="font-bold">{totalPercent}%</span>
        </div>
        <div className="flex flex-wrap gap-2 items-center">
          <span className="text-[13px]">Total Amount:</span>
          <span className="font-bold">{getPrice3decimal(totalAmount)}$</span>
        </div>
      </div>
    )
  }, [data['Markup']])

  const totalGain = useMemo(() => {
    const totalProfitAmount = sellProfit - buyProfit
    const totalProfitPercent = (totalProfitAmount * 100) / data['LoanWith']
    return (
      <table className="text-[14px] w-full">
        <thead>
          <tr>
            <th className="border"></th>
            <th className="border px-2 py-1">Percent</th>
            <th className="border px-2 py-1">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-2 py-1">Sell Side Net Profit</td>
            <td className="border px-2 py-1"></td>
            <td className="border px-2 py-1">${getPrice3decimal(sellProfit.toFixed(3))}</td>
          </tr>
          <tr>
            <td className="border px-2 py-1">Buy Side Net Profit</td>
            <td className="border px-2 py-1"></td>
            <td className="border px-2 py-1">${getPrice3decimal(buyProfit.toFixed(3))}</td>
          </tr>
          <tr className="font-bold">
            <td className="border px-2 py-1 text-center">Total</td>
            <td className="border px-2 py-1">{totalProfitPercent.toFixed(4)}%</td>
            <td className="border px-2 py-1">${getPrice3decimal(totalProfitAmount.toFixed(3))}</td>
          </tr>
        </tbody>
      </table>
    )
  }, [sellProfit, buyProfit])

  const copyPricingInfo = (type: string) => {
    let temp = cloneDeep(data)
    if (type === 'Buy') {
      temp['Pricing']['Buy'] = cloneDeep(temp['Pricing']['Sell'])
      temp.BuyPriceBase = temp.InvestorPriceBase
      temp.BuySRP = temp.InvestorSRP
      temp.ARMMargin = temp.InvestorARMMargin
      temp.IntRate = temp.InvestorIntRate
    }
    if (type === 'Sell') {
      temp['Pricing']['Sell'] = cloneDeep(temp['Pricing']['Buy'])
      temp.InvestorPriceBase = temp.BuyPriceBase
      temp.InvestorSRP = temp.BuySRP
      temp.InvestorARMMargin = temp.ARMMargin
      temp.InvestorIntRate = temp.IntRate
    }
    setData(temp)
  }

  const renderPricing = (type: string) => {
    let priceBase = 'BuyPriceBase'
    let srp = 'BuySRP'
    let margin = 'ARMMargin'
    let rate = 'IntRate'

    if (type === 'Sell') {
      priceBase = 'InvestorPriceBase'
      srp = 'InvestorSRP'
      margin = 'InvestorARMMargin'
      rate = 'InvestorIntRate'
    }

    const adjustments = data['Pricing'][type]
    let baseMargin = removeComma(data[margin])
    let baseRate = removeComma(data[rate])
    let netPrice = removeComma(data[priceBase]) + removeComma(data[srp])
    let netSRP = removeComma(data[srp])
    let baseProfit = 0
    let netProfit = 0
    let adjustProfit = 0

    adjustments?.map((item: any) => {
      baseMargin -= removeComma(item.MarginPercent)
      baseRate -= removeComma(item.IntRatePercent)
      netPrice += removeComma(item.PricePercent)
      adjustProfit += removeComma(item.FeeAmount)
      if (item.PriceAdjustmentType == 1) netSRP += removeComma(item.PricePercent)
    })

    baseProfit = (data['LoanWith'] * (netPrice - 100)) / 100
    netProfit = baseProfit - adjustProfit
    if (type === 'Buy' && netProfit != buyProfit) {
      setBuyProfit(netProfit)
    }
    if (type === 'Sell' && netProfit != sellProfit) {
      setSellProfit(netProfit)
    }

    const RateSpreadKey = `${type}SideRateSpread`
    const ExitFeeKey = `${type}SideExitFee`

    return (
      <div className="my-2">
        <div className="flex justify-between mb-2 text-shade-blue flex-wrap gap-4">
          <div
            className="flex gap-1 items-center hover:underline cursor-pointer"
            onClick={() => {
              copyPricingInfo(type)
            }}
          >
            <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
            <span>Copy from {type === 'Buy' ? 'Sell' : 'Buy'}-Side</span>
          </div>
          <div
            className="flex gap-1 items-center hover:underline cursor-pointer"
            onClick={() => {
              setPriceModal(type)
            }}
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            <span>Edit Adjustment</span>
          </div>
        </div>
        <table className="w-full text-[13px]">
          <thead>
            <tr className="bg-stone-50">
              <th className="border px-2 py-1"></th>
              <th className="border px-2 py-1">Margin</th>
              <th className="border px-2 py-1">Rate</th>
              <th className="border px-2 py-1">Price</th>
              <th className="border px-2 py-1">Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1">Base</td>
              <td className="border px-2 py-1">{baseMargin.toFixed(3)}</td>
              <td className="border px-2 py-1">{baseRate.toFixed(3)}</td>
              <td className="border px-2 py-1">
                <div className="-mb-4 w-[75px]">
                  <Input
                    className="mb-0 w-full"
                    value={data[priceBase]}
                    type="number"
                    onChange={(value) => changeData(priceBase, value)}
                  />
                </div>
              </td>
              <td className="border px-2 py-1">{getPrice3decimal(baseProfit.toFixed(3))}</td>
            </tr>
            {adjustments?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td className="border px-2 py-1">{item.Description}</td>
                  <td className="border px-2 py-1">{Number(item.MarginPercent).toFixed(3)}</td>
                  <td className="border px-2 py-1">{Number(item.IntRatePercent).toFixed(3)}</td>
                  <td className="border px-2 py-1">{Number(item.PricePercent).toFixed(3)}</td>
                  <td className="border px-2 py-1">{getPrice3decimal(Number(item.FeeAmount).toFixed(3))}</td>
                </tr>
              )
            })}
            <tr>
              <td className="border px-2 py-1">Base SRP</td>
              <td className="border px-2 py-1"></td>
              <td className="border px-2 py-1"></td>
              <td className="border px-2 py-1">
                <div className="-mb-4 w-[75px]">
                  <Input
                    className="mb-0 w-full"
                    value={data[srp]}
                    type="number"
                    onChange={(value) => changeData(srp, value)}
                  />
                </div>
              </td>
              <td className="border px-2 py-1"></td>
            </tr>
            <tr className="">
              <td className="border px-2 py-1 border-t-gray-500 border-t-[2px]">Net</td>
              <td className="border px-2 py-1 border-t-gray-500 border-t-[2px]">
                <div className="-mb-4 w-[75px]">
                  <Input
                    className="mb-0 w-full"
                    value={data[margin]}
                    type="number"
                    onChange={(value) => changeData(margin, value)}
                  />
                </div>
              </td>
              <td className="border px-2 py-1 border-t-gray-500 border-t-[2px]">
                <div className="-mb-4 w-[75px]">
                  <Input
                    className="mb-0 w-full"
                    value={data[rate]}
                    type="number"
                    onChange={(value) => changeData(rate, value)}
                  />
                </div>
              </td>
              <td className="border px-2 py-1 border-t-gray-500 border-t-[2px]">{netPrice.toFixed(3)}</td>
              <td className="border px-2 py-1 border-t-gray-500 border-t-[2px]">
                {getPrice3decimal(netProfit.toFixed(3))}
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1">Net SRP</td>
              <td className="border px-2 py-1"></td>
              <td className="border px-2 py-1"></td>
              <td className="border px-2 py-1">{netSRP.toFixed(3)}</td>
              <td className="border px-2 py-1"></td>
            </tr>
          </tbody>
        </table>
        <div className={`grid gap-4 md:grid-cols-2 grid-cols-1 mt-4`}>
          <div className={`input md:col-span-1}`}>
            <RenderInput
              input={{
                title: 'Rate Spread',
                inputType: 'text',
                type: 'number',
                value: data[RateSpreadKey],
              }}
              Key={RateSpreadKey}
              onChange={changeData}
            />
          </div>
          <div className={`input md:col-span-1}`}>
            <RenderInput
              input={{
                title: 'Exit Fee',
                inputType: 'text',
                type: 'number',
                value: data[ExitFeeKey],
              }}
              Key={ExitFeeKey}
              onChange={changeData}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="SecondaryMarketingScreen-container py-6 px-2">
      <Overview title="Secondary Marketing" />
      <SaveChanges show={changed} onSave={onSync} />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-4 md:p-6 p-2">
          <LayoutLoading show={action === 'init' || action === 'sync'} />
          <div className="grid md:grid-cols-12 gap-5">
            <div className="md:col-span-12">
              <div className="shadow rounded border max-w-[900px]">
                <div className="bg-gray-200 round-t py-1 px-4">Originator Lock Info</div>
                <div className="p-3">
                  <div className="grid md:grid-cols-2 gap-6">
                    {Object.keys(originatorLockFields).map((groupKey) => {
                      const fields = originatorLockFields[groupKey]
                      return (
                        <div className="grid md:grid-cols-3 gap-2 h-fit" key={`${groupKey}}`}>
                          {Object.keys(fields).map((key, index) => {
                            let input = fields[key]
                            input.value = data[key]
                            if (key === 'RegulatoryLockDate') input.value = data['LockStartDate']
                            return (
                              <div
                                className={`md:col-span-${input.span ? input.span : 3}`}
                                key={`${groupKey}-${index}`}
                              >
                                <RenderInput input={input} Key={key} onChange={changeData} />
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="shadow rounded mt-4 border max-w-[900px]">
                <div className="bg-gray-200 round-t py-1 px-4">Investor Commitment Info</div>
                <div className="p-3">
                  <div className="grid md:grid-cols-2 gap-6">
                    {Object.keys(investorCommitmentFields).map((groupKey) => {
                      const fields = investorCommitmentFields[groupKey]
                      return (
                        <div className="grid md:grid-cols-3 gap-2 h-fit" key={`${groupKey}}`}>
                          {Object.keys(fields).map((key, index) => {
                            let input: any = fields[key]
                            input.value = data[key]
                            if (['InvestorCode', 'MandatoryInvestor', 'ExcludedInvestor'].indexOf(key) !== -1)
                              input.options = investors
                            return (
                              <div
                                className={`md:col-span-${input.span ? input.span : 3}`}
                                key={`${groupKey}-${index}`}
                              >
                                <RenderInput input={input} Key={key} onChange={changeData} />
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-12">
              <div className="shadow rounded border max-w-[900px]">
                <div className="bg-gray-200 round-t py-1 px-4">Margin / Markup</div>
                <div className="p-3">
                  {markupTotal}
                  <div className="mt-3 overflow-auto pb-2">
                    <FormTable
                      header={markupHeader}
                      inputs={defaultMarkupInputs}
                      defaultData={data['Markup']}
                      onSubmit={onSubmit}
                      onRemove={onRemove}
                      addText="Add New Margin / Markup"
                    />
                  </div>
                </div>
              </div>

              <div className="shadow rounded mt-5 border max-w-[1000px]">
                <div className="bg-gray-200 round-t py-1 px-4">Pricing and Profit</div>
                <div className="p-3">
                  <div className="overflow-auto">
                    <table className="text-[14px] w-full">
                      <thead>
                        <tr className="bg-blue-50">
                          <th className="w-[50%] border py-1 border-r-gray-500 px-2 border-r-[3px]">Buy Side</th>
                          <th className="w-[50%] border py-1">Sell Side</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border  border-r-gray-500 px-2 border-r-[3px] align-baseline">
                            <div>
                              <div className="h-fit py-2">
                                {Object.keys(buyPricingFields).map((key, index) => {
                                  let input: any = buyPricingFields[key]
                                  input.value = data[key]
                                  if (key === 'BuyPricedInvestor') input.options = investors
                                  return (
                                    <div className={`py-1`} key={index}>
                                      <RenderInput input={input} Key={key} onChange={changeData} />
                                    </div>
                                  )
                                })}
                              </div>
                              {renderPricing('Buy')}
                            </div>
                          </td>
                          <td className="border px-2 align-baseline">
                            <div>
                              <div className="h-fit py-2">
                                {Object.keys(sellPricingFields).map((key, index) => {
                                  let input: any = sellPricingFields[key]
                                  input.value = data[key]
                                  if (key === 'PricedInvestor') input.options = investors
                                  return (
                                    <div className={`py-1`} key={index}>
                                      <RenderInput input={input} Key={key} onChange={changeData} />
                                    </div>
                                  )
                                })}
                              </div>
                              {renderPricing('Sell')}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4">{totalGain}</div>
                </div>
              </div>
              <div className="mt-6 flex justify-center max-w-[1000px]">
                <div className="w-[200px]">
                  <Button loading={action === 'sync'} onClick={onSync} className="w-full">
                    Sync
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={`${priceModal} - Side Adjustments`}
        titleOkay=""
        titleCancel="Close"
        isOpen={priceModal !== ''}
        lastUpdatedAt={Date.now()}
        onClose={() => {
          setPriceModal('')
        }}
      >
        <div className="overflow-auto">
          <FormTable
            header={priceHeader}
            inputs={defaultPricingAdjustmentInputs}
            defaultData={data['Pricing'][priceModal]}
            onSubmit={onPriceSubmit}
            onRemove={onPriceRemove}
            addText="Add New Adjustment"
          />
        </div>
      </Modal>
    </div>
  )
}
