import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { application } from './application.reducer'
import { auth } from './auth.reducer'
import { borrower } from './borrower.reducer'
import { globalConfigReducer } from './global.config.reducer'
import { header } from './header.reducer'
import { loan } from './loan.reducer'
import { loanGlobalStatus } from './loan.status.reducer'
import { loanDetail } from './loanDetail.reducer'
import { ltvMaxLimit } from './ltvMaxLimit.reducer'
import { step } from './step.reducer'
import { tierLtvMaxLimit } from './tierLtvMaxLimit.reducer'
import { vendor } from './vendor.reducer'

const reducer = combineReducers({
  auth,
  header,
  loan,
  step,
  loanDetail,
  application,
  borrower,
  ltvMaxLimit,
  tierLtvMaxLimit,
  vendor,
  loanGlobalStatus,
  globalConfigReducer,
})

const { href } = window.location
const isTemporaryUrl = href.length > 200 && !href.includes('/borrower')

const emptyStorage = {
  getItem: async () => '',
  setItem: async () => '',
  removeItem: async () => '',
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage: isTemporaryUrl ? emptyStorage : storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
})

export const persistor = persistStore(store)
