import { GlobalConfigType } from 'actions'
import { FieldOrigin, IVisibleProp, LoanFieldProps } from 'config'
import { getLoanInputFields } from 'config/loan.input.fields.constants'
import { store } from 'reducers'

export const getLoanFieldProps = (fieldOrigin: FieldOrigin) => {
  const { globalConfigReducer } = store.getState()
  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (!loanFieldProperties || !loanFieldProperties[fieldOrigin]) return {}
  return loanFieldProperties[fieldOrigin]
}

export const getInvisibleFields = (fieldOrigin: FieldOrigin) => {
  const { globalConfigReducer, loan, borrower } = store.getState()
  const data = {
    [FieldOrigin.LoanStructure]: loan,
    [FieldOrigin.BorrowerInformation]: borrower['borrower'],
    [FieldOrigin.PropertyInformation]: loan,
  } as LoanFieldProps
  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (!loanFieldProperties || !loanFieldProperties[fieldOrigin]) return []

  const fieldProps: Record<string, IVisibleProp> = loanFieldProperties[fieldOrigin]

  return Object.keys(fieldProps).filter((key) => {
    let { visible, visibleLogic } = fieldProps[key]
    if (visible === false) return true

    if (!visibleLogic || !visibleLogic.length) return false
    visibleLogic = visibleLogic.filter((v) => {
      if (!v || !v.length) return false
      return !!v.filter((v) => !!v && !!v.fieldOrigin && !!v.fieldKey && v.value !== undefined).length
    })
    if (!visibleLogic.length) return false

    const isVisible = !!visibleLogic.filter((andLogics) => {
      return (
        andLogics.length ==
        andLogics.filter((logic) => {
          const { fieldOrigin, fieldKey, value: sampleValue } = logic
          const inputedValue = (data[fieldOrigin] || {})[fieldKey]
          return inputedValue == sampleValue
        }).length
      )
    }).length

    return !isVisible
  })
}

export const removeInvisibleFields = (fieldOrigin: FieldOrigin, visibleFields: string[]) => {
  const invisibleFields = getInvisibleFields(fieldOrigin)
  return visibleFields.filter((v) => !invisibleFields.includes(v))
}

export const setBorrowerDefaultData = (
  data: { borrower: Record<string, any>; coBorrower: Record<string, any> },
  fieldProps: LoanFieldProps,
) => {
  const borrowerInfo = fieldProps[FieldOrigin.BorrowerInformation]
  const loanInputFields = getLoanInputFields()
  const borrowerInputFields = loanInputFields[FieldOrigin.BorrowerInformation]

  if (!borrowerInfo) return data
  ;['borrower', 'coBorrower'].forEach((_boKey) => {
    const boKey = _boKey as 'borrower' | 'coBorrower'
    Object.keys(borrowerInputFields).forEach((key) => {
      if (!['', null, undefined].includes(data[boKey][key])) return
      if (borrowerInfo[key] && borrowerInfo[key].defaultValue !== undefined && borrowerInfo[key].defaultValue !== '')
        data[boKey][key] = borrowerInfo[key].defaultValue
    })
  })
  return data
}

export const setLoanDefaultData = (data: Record<string, any>, fieldProps: LoanFieldProps) => {
  const fieldOrigins = [FieldOrigin.LoanStructure, FieldOrigin.PropertyInformation]
  const loanInputFields = getLoanInputFields()

  for (const fieldOrigin of fieldOrigins) {
    const prop = fieldProps[fieldOrigin]
    if (!prop) continue

    Object.keys(loanInputFields[fieldOrigin]).forEach((key) => {
      const value = data[key]
      if (!['', null, undefined].includes(value)) return

      if (prop[key] && prop[key].defaultValue !== undefined && prop[key].defaultValue !== '')
        data[key] = prop[key].defaultValue
    })
  }

  return data
}
