import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import type { CustomInput, InputType } from 'config'
import { useEffect, useState } from 'react'
import { loanOverviewSectionByLoanNumber } from 'services'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import type { INote } from './types'

export const defaultInputs = (loanNumber: number): Record<string, InputType> => {
  return {
    subject: {
      inputType: 'text',
      title: 'Subject',
      span: 2,
    },
    content: {
      inputType: 'textarea',
      type: 'text',
      title: 'Note',
      value: '',
      span: 2,
      required: true,
    },
    notifiers: {
      inputType: 'custom',
      title: 'Send Notification To',
      span: 'full',
    },
    files: {
      inputType: 'filetable',
      title: 'Files',
      required: false,
      acceptFileTypes: '.pdf, .msg, .xls, .xlsx',
      multiple: true,
      span: 'full',
      showCategory: false,
      showStatus: false,
      filePath: `loan/${loanNumber}/notes`,
    },
  }
}

export const AddNote = ({
  index,
  item,
  onClose,
  onUpdate,
  isEmail = false,
  replyData = null,
  loanNumber: _loanNumber,
}: {
  index: number
  item: INote | null
  onClose: Function
  onUpdate: Function
  isEmail?: boolean
  replyData?: INote | null
  loanNumber?: number
}) => {
  const loanNumber = _loanNumber || setLoanNumber()
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs(loanNumber))
  const [notifiers, setNotifiers] = useState<EmailValue>({})

  useEffect(() => {
    if (item) {
      const newInputs = cloneDeep(inputs)
      newInputs.subject.value = item.subject
      newInputs.content.value = item.content
      newInputs.files.value = item.files
      setInputs(newInputs)
      setNotifiers(item.notifiers)
    } else {
      if (replyData) return
      const newInputs = cloneDeep(inputs)
      loanOverviewSectionByLoanNumber(loanNumber).then((detail) => {
        newInputs.subject.value = `${detail.byteproFileName || detail.loanNumber} | ${detail.entityTitle} | ${
          detail.borrowerName
        } | ${detail.propertyAddress}`
        setInputs(newInputs)
      })
    }
  }, [])

  useEffect(() => {
    if (!replyData) return
    const { noteBy, subject } = replyData
    const splits = noteBy.split(' - ')
    const hasName = splits.length > 1
    const email = hasName ? splits[1] : splits[0]

    const temp: any = cloneDeep(notifiers)
    setNotifiers({ ...temp, [email]: 'to' })

    const newInputs = cloneDeep(inputs)
    newInputs.subject.value = subject
    setInputs(newInputs)
  }, [replyData])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (isEmail && !Object.values(notifiers).includes('to')) {
      newInputs.notifiers.error = 'Please set "To" of Notification Emails'
      hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    onUpdate(data.subject, data.content, data.files, notifiers, isEmail, replyData)
  }

  const renderNotifier = () => {
    const { error } = inputs.notifiers
    return (
      <div className="w-full">
        <RenderInput input={{ inputType: 'section', title: 'Send Notification To' }} Key="title" onChange={() => {}} />
        <LoanPartiesEmailTo loanNumber={loanNumber} value={notifiers} data={[]} onChange={setNotifiers} loadParties />
        {error && <p className={`peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1`}>{error}</p>}
      </div>
    )
  }

  return (
    <Modal
      title={item ? `Update Note - No.${index + 1}` : 'Create Email / Note'}
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      childLevel={2}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            if (key == 'notifiers') {
              if (!isEmail) return
              ;(input as CustomInput).render = renderNotifier
            }
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
