export enum AccountType {
  ADMIN = 'admin',
  UW_MANAGER = 'uw_manager',
  UNDERWRITER = 'underwriter',
  APPRAISER = 'appraiser',
  LOCK_DESK = 'lock_desk',
  LOAN_SETUP = 'loan_setup',
  DISCLOSURE_SPEC = 'disclosure_spec',
  CLOSER = 'closer',
  POST_CLOSER = 'post_closer',
  SERVICING = 'servicing',
  DRAW_SPECIALIST = 'draw_specialist',
  FEASIBILITY_ANALYST = 'feasibility_analyst',
  ACCOUNTING = 'accounting',
  NATIONAL_SALE = 'national_sale',
  OPERATION_SUPERVISOR = 'operation_supervisor',
  VENDOR_APPROVAL = 'vendor_approval',
  ACCOUNT_MANAGER = 'account_manager',
  ACCOUNT_EXECUTIVE = 'account_executive',
  BROKER = 'broker',
  RETAIL = 'retail',
  CORRESPONDENT = 'correspondent',
  TABLEFUNDER = 'tablefunder',
  BRANCH = 'branch',
  LOAN_OFFICER = 'loan_officer',
  LOAN_PROCESSOR = 'loan_processor',
}

export enum UserStatus {
  ALL = 'all',
  Active = 'active',
  Inactive = 'inactive',
}

export type UserOrderBy = 'companyName' | 'name' | 'email' | 'phone' | 'companyNmls' | 'accountType'

export const VerificationAccountType = [AccountType.BROKER, AccountType.CORRESPONDENT, AccountType.TABLEFUNDER]

export const BrokerAccountTypes = [
  AccountType.BROKER,
  AccountType.CORRESPONDENT,
  AccountType.TABLEFUNDER,
  AccountType.RETAIL,
  AccountType.BRANCH,
]

export const UnderwriterAEAccountTypes = [
  AccountType.BROKER,
  AccountType.TABLEFUNDER,
  AccountType.CORRESPONDENT,
  AccountType.RETAIL,
  AccountType.BRANCH,
  AccountType.LOAN_OFFICER,
  AccountType.LOAN_PROCESSOR,
]

export interface UserFilterQuery {
  query?: string
  status?: UserStatus
  accountType?: AccountType
  pullCreditScoreLevel?: string
  accountExecutive?: string
  orderBy?: UserOrderBy
  orderDir?: 1 | -1
  skip?: number
  count?: number
  showDeleted?: boolean
  showLoanSharing?: boolean
  showOthers?: boolean
}

export const UserStatusFilters = {
  all: 'All',
  inactive: 'Lead',
  pending: 'Pending',
  active: 'Active',
}

export const permissionRequireTypes = [
  // AccountType.NATIONAL_SALE,
  AccountType.ACCOUNT_EXECUTIVE,
  AccountType.BROKER,
  AccountType.RETAIL,
  AccountType.CORRESPONDENT,
  AccountType.TABLEFUNDER,
  AccountType.BRANCH,
]

export const isNeedAccountTypeValidate = (accountType: AccountType) => {
  return permissionRequireTypes.indexOf(accountType) != -1
}

export interface UserActivityFilterQuery {
  startDate?: string
  endDate?: string
  email?: string
}

export enum UserActivityType {
  LOGIN = 'login',
  FORGET_PASSWORD = 'forget_password',
  RESET_PASSWORD = 'reset_password',
  REGISTER_USER = 'register_user',
  CREATE_USER = 'create_user',
  UPDATE_USER = 'update_user',
  UPDATE_LOAN_PROPERTY = 'update_loan_property',
}
